import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { ConsultantType } from "../consultanttype.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { ConsultantTypeService } from "app/service/consultantType/consultantType.service";
import { ConsultantService } from "app/service/consultant/consultant.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewConsultantTypeComponent implements OnInit {
  consultantType: ConsultantType;
  _id;
  isLoading: Boolean;
  userLevel: number;
  status: Array<string> = ["", "פעיל", "טרם הופעל", "מושהה", "לא פעיל"];
  colors: Array<string> = ["", "blue", "gray", "orange", "red"];
  @ViewChild(MatPaginator, { static: false }) projectsPaginator: MatPaginator;
  projectsDataSource: any;
  projectmanagelist: any;
  tempprojectsmanagelist: Array<any>;
  displayedProjectsColumns: string[] = [
    "serialNumber",
    "fullName",
    "telephone",
    "fax",
    "email",
    "status",
    "actions",
  ];
  selectionProjects = new SelectionModel<any>(true, []);
  errorMessage = "";
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private consultantTypeService: ConsultantTypeService,
    private consultantService: ConsultantService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("CONSULTANT_TYPE_TITLE_MANAGE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/consultanttype"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.consultantTypeService.getSingleConsultantType(this._id).subscribe(
        (resData) => {
          this.consultantType = resData["consultanttype"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      if (this.userLevel == 3) {
        this.fetchActiveConsultants();
      } else {
        this.fetchConsultants();
      }
      this.isLoading = false;
    }, 2000);
  }

  updateConsultantsFilter(event) {
    const fil = event.target.value;
    const tempprojects = this.tempprojectsmanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserConsultantsList(tempprojects);
  }

  fetchConsultants() {
    this.isLoading = true;
    this.consultantService.getConsultantConsultantTypeList(this._id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempprojectsmanagelist = [...resData["Consultantlist"]];
          this.projectmanagelist = [...resData["Consultantlist"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserConsultantsList(this.projectmanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActiveConsultants() {
    this.isLoading = true;
    this.consultantService
      .getActiveConsultantConsultantTypeList(this._id)
      .subscribe(
        (resData) => {
          this.totalItems = resData["totalItems"];
          if (this.totalItems == 0) {
            this.errCode = "DATA_NOT_FOUND";
          } else {
            this.tempprojectsmanagelist = [...resData["consultanttypes"]];
            this.projectmanagelist = [...resData["consultanttypes"]];
          }
        },
        (err) => {
          this.errorFound(err);
        },
        () => this.getUserConsultantsList(this.projectmanagelist)
      );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserConsultantsList(res) {
    this.projectmanagelist = res;
    this.projectsDataSource = new MatTableDataSource<any>(
      this.projectmanagelist
    );
    setTimeout(() => {
      this.projectsDataSource.paginator = this.projectsPaginator;
    }, 0);
  }

  /**
   *onDeleteContact method is used to delete contact / status 4.
   */
  onDeleteConsultant(_id, index) {
    this.consultantService.deleteConsultant(_id).subscribe(
      (resData) => {
        this.translate.get("CONSULTANT_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        if (this.userLevel == 3) {
          this.fetchActiveConsultants();
        } else {
          this.fetchConsultants();
        }
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONSULTANT_DELETE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onPauseContact method is used to pause contact / status 3.
   */
  onPauseConsultant(_id, index) {
    this.consultantService.pauseConsultant(_id).subscribe(
      (resData) => {
        this.translate.get("CONSULTANT_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        if (this.userLevel == 3) {
          this.fetchActiveConsultants();
        } else {
          this.fetchConsultants();
        }
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONSULTANT_PAUSE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onReactiveContact method is used to delete contact / status 1.
   */
  onReactiveConsultant(_id, index) {
    this.consultantService.activeConsultant(_id).subscribe(
      (resData) => {
        this.translate.get("CONSULTANT_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        if (this.userLevel == 3) {
          this.fetchActiveConsultants();
        } else {
          this.fetchConsultants();
        }
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONSULTANT_ACTIVE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/consultanttype"]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }

  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActiveConsultants();
    }else {
      this.fetchConsultants();
    }
    this.isLoading = true;
  }
}
