import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { AuthService } from "app/service/auth-service/auth.service";
import { ProjectService } from 'app/service/project/project.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-planningstage",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ListProjectComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  userLevel: number;
  status: Array<string> = ["", "פעיל", "טרם הופעל", "מושהה", "לא פעיל"];
  colors: Array<string> = ["", "blue", "gray", "orange", "red"];

  displayedColumns: string[] = [
    "serialNumber",
    "projectInternalId",
    "title",
    "client",
    "projectType",
    "fee",
    "usedFee",
    "createdAt",
    "endDate",
    "status",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private pageTitleService: PageTitleService,
    private projectService: ProjectService,
    private authService: AuthService,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("PROJECT_TITLE");
    this.onReload();
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  fetch() {
    this.isLoading = true;
    this.projectService.getProjectList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        this.dataFound(resData["projectlist"]);
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActive() {
    this.isLoading = true;
    this.projectService.getActiveProjectList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        this.dataFound(resData["projectlist"]);
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }
  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    this.projectService.deleteProject(_id).subscribe(
      (resData) => {
        this.loadSuccessToastr("PROJECT_DELETE_SUCCESS");
        this.onReload();
      },
      (errorMessage) => {
        this.loadErrorToastr("PROJECT_DELETE_WARNING");
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    this.projectService.pauseProject(_id).subscribe(
      (resData) => {
        this.loadSuccessToastr("PROJECT_PAUSE_SUCCESS");
        this.onReload();
      },
      (errorMessage) => {
        this.loadErrorToastr("PROJECT_PAUSE_WARNING");
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    this.projectService.activeProject(_id).subscribe(
      (resData) => {
        this.loadSuccessToastr("PROJECT_ACTIVE_SUCCESS");
        this.onReload();
      },
      (errorMessage) => {
        this.loadErrorToastr("PROJECT_ACTIVE_WARNING");
      }
    );
  }
  
  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActive();
    }else{
      this.fetch();
    }
  }
  
  /**
   * On Error
   */
  errorFound(err) {
    this.loadErrorToastr("ERROR_MSG");
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
  }

  /**
   * On Data Found
   */
  private dataFound(resData) {
    if (this.totalItems == 0) {
      this.errCode = "DATA_NOT_FOUND";
    } else {
      this.tempusermanagelist = [...resData];
      this.usermanagelist = [...resData];
    }
  }

  /**
   * Load Error Message
   */
  private loadErrorToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
  
  /**
   * Load Success Message
   */
  private loadSuccessToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
        this.toastr.success(text);
    });
  }
}
