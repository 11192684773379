import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { JobRole } from "../jobrole.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { JobRoleService } from "app/service/jobrole/jobrole.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditJobRoleComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  jobrole: JobRole;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private jobRoleService: JobRoleService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/jobrole"]);
    }
    this.pageTitleService.setTitle("JOB_ROLE_MAIN_TITLE");
    this.isLoading = true;
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
        this.router.navigate(["/jobrole"]);
      }
      this.jobRoleService.getSingleJobRole(this._id).subscribe(
        (resData) => {
          this.jobrole = resData["jobrole"];
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
      if (this.isFound) {
        this.form = this.fb.group({
          title: [
            this.jobrole.title,
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(24),
            ]),
          ],
          description: [
            this.jobrole.description,
            Validators.compose([
              Validators.required,
              Validators.minLength(7),
              Validators.maxLength(36),
            ]),
          ],
        });
      }
    }, 2000);
  }

  onSubmit() {
    const newPS = new JobRole(
      this.form.value.title,
      this.form.value.description
    );
    this.jobRoleService.editSingleJobRole(newPS, this._id).subscribe(
      (res) => {
        this.translate.get("JOB_ROLE_UPDATE_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/jobrole"]);
      },
      (errorMessage) => {
        this.translate.get("JOB_ROLE_UPDATE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/jobrole"]);
  }
}
