<mat-card-content>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <div class="user-content">
        <mat-card>
          <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <div class="pad-y-sm" *ngIf="!isLoading">
            <div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile">
              <div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)" fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100" class="user-contact-info">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>{{'CONSULTANT_TYPE_TITLE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4 *ngIf="userLevel == 1" class="activeIcon">
                      <a class="gene-block" href="/consultanttype/edit/{{ consultantType._id }}">{{ consultantType.title }}</a>
                    </h4>
                    <h4 *ngIf="userLevel != 1" class="activeIcon">
                      {{ consultantType.title }}
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>{{'CONSULTANT_TYPE_DESCRIPTION'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4>{{ consultantType.description }}</h4>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div class="user-manage-list">
                  <mat-card>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="mrgn-r-sm"> 
                          <h3>{{'CONSULTANT_TITLE_LIST'|translate}}</h3>
                        </div>
                      </div>
                      <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
                        <mat-card-actions class="mat-pad-none margin-none">
                          <a href="/consultanttype/consultant/{{ _id }}" mat-raised-button mat-button-sm color="primary" *ngIf="userLevel != 3">
                            {{'CONSULTANT_TITLE_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
                          </a>&nbsp;
                          <button mat-raised-button mat-button-sm color="primary" (click)="onReload()">
                            {{'BTN_RELOAD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">refresh</mat-icon>
                          </button>
                        </mat-card-actions>
                      </div>
                    </div>
                    <div class="pad-all-md mat-white box-inset mrgn-all-md" *ngIf="this.totalItems != 0">
                      <form class="example-form" fxFlex="220px">
                        <mat-form-field class="full-wid">
                          <input matInput type="text" placeholder="{{'TITLE_FILTER'|translate}}" (keyup)="updateConsultantsFilter($event)" />
                        </mat-form-field>
                      </form>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="table-responsive">
                      <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="this.totalItems == 0">{{errCode|translate}}</b>
                      <table class="full-wid" mat-table [dataSource]="projectsDataSource" *ngIf="this.totalItems > 0">
                        <ng-container matColumnDef="serialNumber">
                          <th mat-header-cell *matHeaderCellDef>{{'TABLE_SERIAL_ID'|translate}}</th>
                          <td mat-cell *matCellDef="let element; index as i">
                            {{ i + 1 }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="fullName">
                          <th mat-header-cell *matHeaderCellDef>{{'FULLNAME_TITLE'|translate}}</th>
                          <td mat-cell *matCellDef="let element">
                            <a href="/consultant/view/{{ element._id }}">{{
                              element.fullName
                            }}</a>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="telephone">
                          <th mat-header-cell *matHeaderCellDef>{{'TITLE_TELEPHONE'|translate}}</th>
                          <td mat-cell *matCellDef="let element">
                            <a href="tel:{{ element.telephone }}" class="editIcon">{{ element.telephone }}</a>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="fax">
                          <th mat-header-cell *matHeaderCellDef>{{'TITLE_FAX'|translate}}</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.fax }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                          <th mat-header-cell *matHeaderCellDef>{{'TITLE_EMAIL'|translate}}</th>
                          <td mat-cell *matCellDef="let element">
                            <a href="mailto:{{ element.email }}" class="underLine">{{ element.email }}</a>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef>{{'TITLE_STATUS'|translate}}</th>
                          <td mat-cell *matCellDef="let element">
                            <div fxLayoutAlign="start start">
                              <mat-chip-list>
                                <mat-chip
                                  color="{{ colors[element.status] }}"
                                  selected
                                >
                                  {{ status[element.status] }}</mat-chip
                                >
                              </mat-chip-list>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <a href="/consultant/view/{{ element._id }}" mat-icon-button>
                              <mat-icon class="mat-icon-grey gene-text-lg">remove_red_eye</mat-icon>
                            </a>
                            <a *ngIf="userLevel != 3" href="/consultant/edit/{{ element._id }}" mat-icon-button>
                              <mat-icon class="mat-icon-grey gene-text-lg editIcon">edit</mat-icon>
                            </a>
                            <button mat-icon-button (click)="onReactiveConsultant(element._id, i)" *ngIf="element.status != 1 && userLevel != 3">
                              <mat-icon class="mat-icon-grey gene-text-lg activeIcon">play_arrow</mat-icon>
                            </button>
                            <button mat-icon-button (click)="onPauseConsultant(element._id, i)" *ngIf="userLevel != 3">
                              <mat-icon class="mat-icon-grey gene-text-lg pauseIcon">pause</mat-icon>
                            </button>
                            <button mat-icon-button (click)="onDeleteConsultant(element._id, i)" *ngIf="userLevel != 3">
                              <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedProjectsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedProjectsColumns" (click)="selectionProjects.toggle(row)"></tr>
                      </table>
                    </div>
                    <div class="pagination" *ngIf="this.totalItems > 0">
                      <mat-paginator [length]="projectmanagelist.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                  </mat-card>
                </div>
                <mat-divider></mat-divider>
                <div class="pad-wrap">
                  <mat-card-actions>
                    <button mat-raised-button color="warn" type="button" (click)="onCancel()">
                      {{'CONSULTANT_BACK_TO_LIST'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon>
                    </button>
                  </mat-card-actions>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card-content>
