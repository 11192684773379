import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatChipsModule,
  MatProgressBarModule,
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";

import { JobRoleRoutes } from "./jobrole.routing";

import { ListJobRoleComponent } from './list/list.component';
import { AddJobRoleComponent } from './add/add.component';
import { EditJobRoleComponent } from './edit/edit.component';
import { ViewJobRoleComponent } from './view/view.component';

@NgModule({
  declarations: [
    ListJobRoleComponent,
    AddJobRoleComponent,
    EditJobRoleComponent,
    ViewJobRoleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(JobRoleRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatChipsModule,
  ],
})
export class JobRoleModule {}
