import { Component, ViewChild, OnInit ,ViewEncapsulation } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { fadeInAnimation} from "../../core/route-animation/route.animation";


@Component({
    selector: 'ms-reporting',
    templateUrl:'./reporting.component.html',
    styleUrls: ['./reporting.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        "[@fadeInAnimation]": 'true'
    },
    animations: [ fadeInAnimation ]
})
export class ReportingComponent implements OnInit {

   rows = [];
   temp = [];
   columns = [
     { prop: 'name' },
     { name: 'Company' },
     { name: 'Gender' }
   ];

   constructor(
      private pageTitleService: PageTitleService
      ) {
      this.fetch();
   }

   ngOnInit() {
      this.pageTitleService.setTitle("רשימת דיווחים");
   }

   /**
     * To fetch the data from JSON file.
     */
   fetch() {
      // const req = new XMLHttpRequest();
      // req.open('GET', `assets/data/company.json`);

      // req.onload = () => {
      //    cb(JSON.parse(req.response));
      // };

      // req.send();
   }

   /**
     * updateFilter method is used to filter the data.
     */
   updateFilter(event) {
      const val = event.target.value;

      // filter our data
      const temp = this.temp.filter(function(d) {
         return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.rows = temp;
   }
}



