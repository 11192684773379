import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class JobRoleService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"jobrole/";

  getActiveJobRoleList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getJobRoleList() {
    return this.httpService.get(this.domain + "list");
  }

  getSingleJobRole(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createJobRole(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleJobRole(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteJobRole(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pauseJobRole(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeJobRole(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
