import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { Planningstage } from "../planningstage.model";
import { PlanningStageService } from "app/service/planningstage/planningstage.service";
import { AuthService } from "app/service/auth-service/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./addplanningstage.component.html",
  styleUrls: ["./addplanningstage.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddPlanningStageComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private psService: PlanningStageService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/"]);
    }
    this.pageTitleService.setTitle("PLANNING_STAGE_MAIN_TITLE");
    this.form = this.fb.group({
      title: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(24),
        ]),
      ],
      description: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(64),
        ]),
      ],
    });
  }

  onSubmit() {
    const newPS = new Planningstage(
      this.form.value.title,
      this.form.value.description
    );
    this.psService.createSinglePlanningStage(newPS).subscribe(
      (res) => {
        this.translate.get("PLANNING_STAGE_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/planningstage"]);
      },
      (errorMessage) => {
        this.translate.get("PLANNING_STAGE_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/planningstage"]);
  }
}
