<div
  id="main-app"
  class="app sidebar-color-dark header-color-dark"
  [dir]="layout"
  [ngClass]="{'app-dark': dark, 'collapsed-sidebar': coreService.collapseSidebar, 'compact-sidebar': compactSidebar, 'customizer-in': customizerIn}"
  [class.side-panel-opened]="coreService.sidenavOpen"
  [class.side-panel-closed]="!coreService.sidenavOpen"
>
  <mat-sidenav-container class="gene-container">
    <mat-sidenav
      #sidenav
      [(mode)]="coreService.sidenavMode"
      [(opened)]="coreService.sidenavOpen"
      class="sidebar-area"
    >
      <ms-side-bar
        [menuList]="userLevel ==3 ? menuItems : userLevel ==2 ? adminMenuItems : rootMenuItems "
        [verticalMenuStatus]="true"
      ></ms-side-bar>
    </mat-sidenav>
    <mat-toolbar class="gene-header-toolbar">
      <div class="gene-ham-icon" tourAnchor="start.tour">
        <button
          class=""
          mat-mini-fab
          color="primary"
          mat-card-icon
          (click)="toggleSidebar()"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <a class="navbar-brand" href="#">{{header|translate}}</a>
      <breadcrumb class="gene-breadcrumbs"></breadcrumb>
      <span fxFlex></span>
      <div class="secondary-menu">
				<button fxHide="true" fxHide.gt-sm="false" mat-button class="fullscreen-toggle" (click)="toggleFullscreen()">
					<mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
					<mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
				</button>
        <button class="user-button" mat-button [matMenuTriggerFor]="menu">
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon>account_circle</mat-icon>
          </div>
        </button>
        <ms-language-drop-down></ms-language-drop-down>
        <button (click)="end.toggle()" mat-icon-button class="overflow-visible">
          <mat-icon>notifications</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <mat-sidenav
      #end
      position="end"
      class="chat-panel"
      mode="over"
      opened="false"
    >
      <div class="scroll">
        <mat-toolbar class="primary-bg">
          <h3>התראות</h3>
        </mat-toolbar>
        <mat-nav-list>
          <mat-list-item>
            <mat-icon mat-list-avatar class="primary-text">person_add</mat-icon>
            <h4 mat-line>New User</h4>
            <p mat-line>10 New Users Registered</p>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>
    <div class="gene-base-container" #scrollContainer>
      <div class="inner-container" fxLayout="row wrap">
        <div class="full-wid" fxFlex="100">
          <router-outlet
            (activate)="onActivate($event, scrollContainer)"
          ></router-outlet>
          <ng-container>
            <tour-step-template>
              <ng-template let-step="step">
                <div
                  (click)="$event.stopPropagation()"
                  class="popover bs-popover-bottom"
                >
                  <div class="popover-header">{{step?.title}}</div>
                  <div class="popover-body">
                    <p class="tour-step-content">{{step?.content}}</p>
                  </div>
                  <div class="tour-step-navigation">
                    <button
                      mat-icon-button
                      [disabled]="!tourService.hasPrev(step)"
                      (click)="tourService.prev()"
                    >
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      [disabled]="!tourService.hasNext(step)"
                      (click)="tourService.next()"
                    >
                      <mat-icon>chevron_left</mat-icon>
                    </button>
                    <button mat-button (click)="tourService.end()">סיום</button>
                  </div>
                </div>
              </ng-template>
            </tour-step-template>
          </ng-container>
        </div>
        <div class="footer-wrap pad-t-none full-wid" fxFLex="100">
          <ms-footer></ms-footer>
        </div>
      </div>
    </div>
  </mat-sidenav-container>
  <div class="gene-customizer">
    <button class="customizer-toggle" (click)="customizerFunction()">
      <mat-icon class="fa-spin">settings</mat-icon>
    </button>
    <div class="settings-panel">
      <mat-toolbar color="primary">
        <span fxFlex>{{'Options'|translate}}</span>
      </mat-toolbar>
      <mat-card-content class="theme-options">
        <h3>{{'Layout_Options'| translate }}</h3>
        <mat-divider></mat-divider>
        <div>
          <mat-checkbox [(ngModel)]="dark" (change) = "addClassOnBody($event)" [align]="end">{{'Dark_Mode'| translate }}</mat-checkbox>
        </div>
        <div>
          <mat-checkbox (change)="changeRTL($event.checked)" >{{'RTL'| translate }}</mat-checkbox>
        </div>
        <div class="inner-toolbar" fxHide fxShow.gt-md>
          <div class="theme-color">
            <div class="text-center mrgn-b-md">
              <h4>{{'Sidebar_Filters'|translate}}</h4>
            </div>
            <div class="gene-list" fxLayoutAlign="center center">
              <div *ngFor = "let filter of sideBarFilterClass; let i=index">
                <a class="gene-list-link" (click)="sidebarFilter(filter)">
                <span [ngClass] = "{'radius-circle': i == 0 }" id = "{{filter.sideBarSelect}}"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="theme-color">
            <div class="text-center mrgn-b-md">
              <h4 class="theme-light-color">{{'Header_Filters'|translate}}</h4>
            </div>
            <div class="gene-list" fxLayoutAlign="center center">
              <div *ngFor = "let filter of headerFilterClass; let i=index">
                <a class="gene-list-link" (click)="headerFilter(filter)">
                <span [ngClass]="{'radius-active': i == 0 }" id="{{filter.headerSelect}}"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>
</div>

<mat-menu
  class="user-menu"
  x-position="before"
  y-position="below"
  #menu="matMenu"
>
  <button (click)="profile()" mat-menu-item>
    <mat-icon>account_circle</mat-icon>
    <span>{{'PROFILE'|translate}}</span>
  </button>
  <button mat-menu-item>
    <mat-icon>help</mat-icon>
    <span>{{'HELP'|translate}}</span>
  </button>
  <button mat-menu-item>
    <mat-icon>question_answer</mat-icon>
    <span>{{'SUPPORT'|translate}}</span>
  </button>
  <mat-divider></mat-divider>
  <button (click)="logOut()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span>{{'LOG_OUT'|translate}}</span>
  </button>
</mat-menu>
