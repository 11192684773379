import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { Contact } from "../contact.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { ContactService } from "app/service/contact/contact.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewContactComponent implements OnInit {
  sps: Contact;
  private _id;
  isLoading: Boolean;
  userLevel: number;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private contactService: ContactService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("CONTACT_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/contacts"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.contactService.getSingleContact(this._id).subscribe(
        (resData) => {
          this.sps = resData["contact"];
        },
        (err) => {
          this.isFound = false;
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onCancel() {
    this.router.navigate(["/contacts"]);
  }

  onRetuenToClient() {
    if (this.sps.typeOfContact == 1) {
      this.router.navigate(["/clients/view/" + this.sps.client._id]);
    } else {
      this.router.navigate(["/consultant/view/" + this.sps.client._id]);
    }
  }

  errorFound(err) {
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
