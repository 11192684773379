import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { AuthService } from "app/service/auth-service/auth.service";
import { ContactService } from 'app/service/contact/contact.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-planningstage",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ListContactComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  userLevel: number;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  displayedColumns: string[] = [
    "serialNumber",
    "title",
    "client",
    "description",
    "telephone",
    "fax",
    "email",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private contactService: ContactService,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate : TranslateService
  ) {}

  ngOnInit() {
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("CONTACT_TITLE");
    this.isLoading = true;
    setTimeout(() => {
      if(this.userLevel==3){
        this.fetchActive();
      }else {
        this.fetch();
      }
      this.isLoading = false;
    }, 2000);
  }

  fetch() {
    this.isLoading = true;
    this.contactService.getContactList().subscribe(
      (resData) => {
        console.log(resData)
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["userlist"]];
          this.usermanagelist = [...resData["userlist"]];
        }
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      },
      () => this.getUserContactList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActive() {
    this.isLoading = true;
    this.contactService.getActiveContactList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["userlist"]];
          this.usermanagelist = [...resData["userlist"]];
        }
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      },
      () => this.getUserContactList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserContactList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserContactList(temps);
  }
  /**
   *onDeleteContact method is used to delete contact / status 4.
   */
  onDeleteContact(_id, index) {
    this.contactService.deleteContact(_id).subscribe(
      (resData) => {
        this.translate.get("CONTACT_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONTACT_DELETE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onPauseContact method is used to pause contact / status 3.
   */
  onPauseContact(_id, index) {
    this.contactService.pauseContact(_id).subscribe(
      (resData) => {
        this.translate.get("CONTACT_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONTACT_PAUSE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onReactiveContact method is used to delete contact / status 1.
   */
  onReactiveContact(_id, index) {
    this.contactService.activeContact(_id).subscribe(
      (resData) => {
        this.translate.get("CONTACT_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONTACT_ACTIVE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActive();
    }else {
      this.fetch();
    }
    this.isLoading = true;
  }

  errorFound(err) {
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
