export class SubDocument {
    constructor(
      public title: string,
      public description: string,
      public document?: {
        _id: string,
        title?: string
      },
      public creator?: string,
      public _id?: string,
      public status?: number
    ) {}
  
  }
  