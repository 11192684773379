import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/service/auth-service/auth.service";
import { ProjectService } from "app/service/project/project.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "ms-add-new-report",
  templateUrl: "./add-new-report.component.html",
  styleUrls: ["./add-new-report.component.scss"],
})
export class AddNewReportComponent implements OnInit {
  addNewUserForm: FormGroup;
  emailPattern: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  isLoading: Boolean;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;
  usermanagelist: any;
  tempusermanagelist: Array<any>;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNewReportComponent>,
    private projectService: ProjectService,
    public translate: TranslateService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.projectService.getActiveProjectList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        this.dataFound(resData["projectlist"]);
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    this.addNewUserForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      emailAddress: [
        "",
        [Validators.required, Validators.pattern(this.emailPattern)],
      ],
      accountType: ["", [Validators.required]],
    });
  }

  // onFormSubmit method is submit a add new user form.
  onFormSubmit() {
    this.dialogRef.close(this.addNewUserForm.value);
  }
  
  /**
   * On Data Found
   */
  private dataFound(resData) {
	  console.log(resData);
    if (this.totalItems == 0) {
      this.errCode = "DATA_NOT_FOUND";
    } else {
      this.tempusermanagelist = [...resData];
      this.usermanagelist = [...resData];
    }
  }

  /**
   * On Error
   */
  errorFound(err) {
    this.loadErrorToastr("ERROR_MSG");
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
  }
  
  /**
   * Load Error Message
   */
  private loadErrorToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
  
  /**
   * Load Success Message
   */
  private loadSuccessToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
        this.toastr.success(text);
    });
  }
}
