import {Component, Optional, ViewEncapsulation} from '@angular/core';
import { TranslateService} from '@ngx-translate/core';
import { AutoLogoutService } from './service/auto-logout/auto-logout.service';

@Component({
  	selector: 'gene-app',
   template:`<router-outlet></router-outlet>
   			<ngx-loading-bar></ngx-loading-bar>`,
   encapsulation: ViewEncapsulation.None
})

export class GeneAppComponent {
   constructor(translate: TranslateService, private autoLogout: AutoLogoutService) {
      translate.addLangs(['he']);
      translate.setDefaultLang('he');

      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/he/) ? browserLang : 'he');
   }
}
