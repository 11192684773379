import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { Document } from "../document.model";
import { SubDocument } from '../sub-document.model';
import { AuthService } from "app/service/auth-service/auth.service";
import { DocumentService } from 'app/service/documents/document.service';
import { SubDocumentService } from 'app/service/documents/sub-document.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewDocumentComponent implements OnInit {
  private documentId: string;
  private _id;
  isLoading: Boolean;
  dataSource: any;
  userLevel: number;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;
  
  sps: Document;
  usermanagelist: Array<SubDocument>;
  tempusermanagelist: Array<SubDocument>;
  
  displayedColumns: string[] = [
    "serialNumber",
    "title",
    "description",
    "createdAt",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private documentService: DocumentService,
    private subDocumentService: SubDocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("DOCUMENTS_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/consultanttype"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.documentService.getSingleDocument(this._id).subscribe(
        (resData) => {
        this.sps = resData["document"];
        this.documentId = this.sps._id
        this.fetch(this.documentId);
      },
      (err) => {
        this.errorFound(err);
      }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  fetch(_id) {
    this.subDocumentService.getSubdocumentsList(_id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["subdocuments"]];
          this.usermanagelist = [...resData["subdocuments"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActive(_id) {
    this.subDocumentService.getSubdocumentsList(_id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["subdocuments"]];
          this.usermanagelist = [...resData["subdocuments"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }
  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    this.subDocumentService.deleteSubdocument(_id).subscribe(
      (resData) => {
        this.translate.get("SUB_DOCUMENT_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch(this.documentId);
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("SUB_DOCUMENT_DELETE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    this.subDocumentService.pauseSubdocument(_id).subscribe(
      (resData) => {
        this.translate.get("SUB_DOCUMENT_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch(this.documentId);
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("SUB_DOCUMENT_PAUSE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    this.subDocumentService.activeSubdocument(_id).subscribe(
      (resData) => {
        this.translate.get("SUB_DOCUMENT_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch(this.documentId);
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("SUB_DOCUMENT_ACTIVE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActive(this.documentId);
    }else {
      this.fetch(this.documentId);
    }
    this.isLoading = true;
  }

  onCancel() {
    this.router.navigate(["/documents"]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
