import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { AuthService } from "app/service/auth-service/auth.service";
import { PlanningStageService } from "app/service/planningstage/planningstage.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-planningstage",
  templateUrl: "./planningstage.component.html",
  styleUrls: ["./planningstage.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class PlanningstageComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  userLevel: number;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  displayedColumns: string[] = [
    "serialNumber",
    "title",
    "description",
    "createdAt",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private psService: PlanningStageService,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate : TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("PLANNING_STAGE_MAIN_TITLE");
    this.userLevel = this.authService.getUserLevel();
    this.fetch();
    this.isLoading = true;
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }

  fetch() {
    this.psService.getPlanningStages().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["planningstages"]];
          this.usermanagelist = [...resData["planningstages"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    this.psService.deletePlanningStage(_id).subscribe(
      (resData) => {
        this.translate.get("PLANNING_STAGE_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PLANNING_STAGE_DELETE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    this.psService.pausePlanningStage(_id).subscribe(
      (resData) => {
        this.translate.get("PLANNING_STAGE_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PLANNING_STAGE_PAUSE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    this.psService.activePlanningStage(_id).subscribe(
      (resData) => {
        this.translate.get("PLANNING_STAGE_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PLANNING_STAGE_ACTIVE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *reload data.
   */
  onReload() {
    this.fetch();
    this.isLoading = true;
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
