<mat-card-content>
  <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
  <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard" *ngIf="!isLoading">
    <mat-card fxFlex="100%">
      <div class="gene-card-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column">
            <h4>{{'CONSULTANT_TITLE_EDIT'|translate}}</h4>
          </div>
          <span fxFlex></span>
        </div>
        <mat-divider></mat-divider>
      </div>
      <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
      <div class="gene-card-content" *ngIf="isFound">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'FULLNAME_TITLE'|translate}}" formControlName="fullName"  minlength="5" maxlength="64">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['fullName'].hasError('required') && form.controls['fullName'].touched">
                  {{'FULLNAME_REQUIRE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['fullName'].hasError('minlength') && form.controls['fullName'].touched">
                  {{'FULLNAME_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['fullName'].hasError('maxlength') && form.controls['fullName'].touched">
                  {{'FULLNAME_MAX'|translate}}
               </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'CID_TITLE'|translate}}" formControlName="cid" minlength="9" maxlength="9">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['cid'].hasError('required') && form.controls['cid'].touched">
                  {{'CID_REQUIRE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['cid'].hasError('minlength') && form.controls['cid'].touched">
                  {{'CID_LENGTH'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['cid'].hasError('maxlength') && form.controls['cid'].touched">
                  {{'CID_LENGTH'|translate}}
               </small>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-md">
                 <input matInput placeholder="{{'TITLE_TELEPHONE'|translate}}" formControlName="telephone" minlength="9" maxlength="10">
              </mat-form-field>
              <small class="gene-block warn-text" *ngIf="form.controls['telephone'].hasError('required') && form.controls['telephone'].touched">
              {{'TITLE_TELEPHONE_REQIURE'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['telephone'].hasError('minlength') && form.controls['telephone'].touched">
              {{'TITLE_TELEPHONE_MIN'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['telephone'].hasError('maxlength') && form.controls['telephone'].touched">
              {{'TITLE_TELEPHONE_MAX'|translate}}
              </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-md">
                 <input matInput placeholder="{{'TITLE_TELEPHONE_SECONDERY'|translate}}" formControlName="cellphone" minlength="9" maxlength="10">
              </mat-form-field>
              <small class="gene-block warn-text" *ngIf="form.controls['cellphone'].hasError('minlength') && form.controls['cellphone'].touched">
              {{'TITLE_TELEPHONE_MIN'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['cellphone'].hasError('maxlength') && form.controls['cellphone'].touched">
              {{'TITLE_TELEPHONE_MAX'|translate}}
              </small>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-md">
                 <input matInput placeholder="{{'TITLE_FAX'|translate}}" formControlName="fax" minlength="9" maxlength="10">
              </mat-form-field>
              <small class="gene-block warn-text" *ngIf="form.controls['fax'].hasError('required') && form.controls['fax'].touched">
              {{'TITLE_FAX_REQIURE'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['fax'].hasError('minlength') && form.controls['fax'].touched">
              {{'TITLE_FAX_MIN'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['fax'].hasError('maxlength') && form.controls['fax'].touched">
              {{'TITLE_FAX_MAX'|translate}}
              </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-md">
                 <input matInput placeholder="{{'TITLE_EMAIL'|translate}}" formControlName="email" minlength="10" maxlength="64" type="email">
              </mat-form-field>
              <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
                 {{'TITLE_EMAIL_REQIURE'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched">
                 {{'TITLE_EMAIL_VALID'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('minlength') && form.controls['email'].touched">
                 {{'TITLE_EMAIL_MIN'|translate}}
              </small>
              <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('maxlength') && form.controls['email'].touched">
                 {{'TITLE_MAIL_MAX'|translate}}
              </small>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
               <small class="gene-block primary-text">{{'DO_NOT_CHANGE'|translate}}</small>
            </div>
            <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
               <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-bar>
               <mat-select placeholder="{{'BANK_CHOOSE'|translate}}" formControlName="bankID" *ngIf="!isLoading" (ngModelChange)="loadBranches($event)">
                  <mat-select-filter placeholder="{{'BANK_CHOOSE_FILTER'|translate}}" [displayMember]="'viewValue'" [array]="banks" (filteredReturn)="filteredList5 = $event" *ngIf="!isLoading"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredList5" [value]="item">
                     {{ item.viewValue }}
                  </mat-option>
               </mat-select>
               <small class="gene-block warn-text" *ngIf="form.controls['bankID'].hasError('required') && form.controls['bankID'].touched">
                  {{'BANK_CHOOSE_FILTER'|translate}}.
               </small>
            </div>
            <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
               <mat-progress-bar mode="indeterminate"  color="primary"  *ngIf="isLoadingBranches" style="margin: auto"></mat-progress-bar>
               <mat-select formControlName="branchId" name="branchId" placeholder="{{'BRANCH_CHOOSE'|translate}}" *ngIf="!isLoadingBranches" [disabled]="branches.length == 0">
                  <mat-option *ngFor="let ps of branches" [value]="ps">
                     {{ ps.viewValue }}
                  </mat-option>
               </mat-select>
               <small class="gene-block warn-text" *ngIf="form.controls['branchId'].hasError('required') && form.controls['branchId'].touched">
                  {{'BRANCH_CHOOSE_FILTER'|translate}}.
               </small>
            </div>
            <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'TITLE_ACCOUNT_NUMBER'|translate}}" formControlName="accountNumber" minlength="4" maxlength="11">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['accountNumber'].hasError('required') && form.controls['accountNumber'].touched">
                  {{'TITLE_ACCOUNT_NUMBER_REQIURE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['accountNumber'].hasError('minlength') && form.controls['accountNumber'].touched">
                  {{'TITLE_ACCOUNT_NUMBER_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['accountNumber'].hasError('maxlength') && form.controls['accountNumber'].touched">
                  {{'TITLE_ACCOUNT_NUMBER_MAX'|translate}}
               </small>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'TITLE_ADDRESS'|translate}}" formControlName="address" minlength="9" maxlength="64">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched">
               {{'TITLE_ADDRESS_REQIURE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['address'].hasError('minlength') && form.controls['address'].touched">
               {{'TITLE_ADDRESS_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['address'].hasError('maxlength') && form.controls['address'].touched">
               {{'TITLE_ADDRESS_MAX'|translate}}
               </small>
            </div>
          </div>
          <div class="pad-wrap">
            <mat-card-actions>
              <button mat-raised-button color="primary" type="submit">{{'BTN_UPDATE'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
              <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
            </mat-card-actions>
          </div>
        </form>
      </div>
    </mat-card>
  </div></mat-card-content
>
