import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class ConsultantTypeService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"consultanttype/";

  getActiveConsultantTypeList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getConsultantTypeList() {
    return this.httpService.get(this.domain + "list");
  }

  getSingleConsultantType(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createConsultantType(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleConsultantType(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteConsultantType(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "delete/" + _id
    );
  }

  pauseConsultantType(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "pause/" + _id
    );
  }

  activeConsultantType(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "active/" + _id
    );
  }
}
