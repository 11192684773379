import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'NAV_HOME',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'planningstage',
    name: 'NAV_PLANNING_STAGES',
    type: 'link',
    icon: 'account_balance_wallet'
  },
  {
    state: 'floor',
    name: 'NAV_FLOOR',
    type: 'link',
    icon: 'view_headline'
  },
  {
    state: 'consultanttype',
    name: 'NAV_CONSULTING_TYPE',
    type: 'link',
    icon: 'local_library'
  },
  {
    state: 'documents',
    name: 'NAV_DOCUMENTS',
    type: 'link',
    icon: 'insert_drive_file'
  },
  {
    state: 'projecttype',
    name: 'NAV_PROJECT_TYPE',
    type: 'link',
    icon: 'business'
  },
  {
    state: 'bank',
    name: 'NAV_BANK',
    type: 'link',
    icon: 'account_balance',
  },
  {
    state: 'jobrole',
    name: 'NAV_JOB_ROLE',
    type: 'link',
    icon: 'supervisor_account'
  },
  {
    state: 'user',
    name: 'NAV_USER',
    type: 'link',
    icon: 'account_box'
  }
];

@Injectable()
export class RootMenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu:any) {
    MENUITEMS.push(menu);
  }
}
