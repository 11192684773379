import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";

import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { Consultant } from "../consultant.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { ClientService } from "app/service/client/client.service";
import { ProjectService } from "app/service/project/project.service";
import { ContactService } from "app/service/contact/contact.service";
import { ConsultantService } from "app/service/consultant/consultant.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewConsultantComponent implements OnInit {
  sps: Consultant;
  temp: Array<any> = [];
  private _id;
  isLoading: Boolean;
  userLevel: number;
  isFound: Boolean = true;
  errCode: string;
  isLoadingContacts: Boolean;
  errCodeContacts: string;
  isFoundContacts: Boolean = true;
  isLoadingProjects: Boolean;
  errCodeProjects: string;
  isFoundProjects: Boolean = true;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  totalItems: number;
  displayedColumns: string[] = [
    "serialNumber",
    "title",
    "description",
    "telephone",
    "fax",
    "email",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: false }) projectsPaginator: MatPaginator;
  projectsDataSource: any;
  projectmanagelist: any;
  tempprojectsmanagelist: Array<any>;
  totalProjectsItems: number;
  displayedProjectsColumns: string[] = [
    "serialNumber",
    "projectInternalId",
    "title",
    "projectType",
    "fee",
    "actions",
  ];
  selectionProjects = new SelectionModel<any>(true, []);

  constructor(
    public translate: TranslateService,
    private pageTitleService: PageTitleService,
    private contactService: ContactService,
    private projectService: ProjectService,
    private consultantService: ConsultantService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("CONSULTANT_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/consultant"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.consultantService.getSingleConsultant(this._id).subscribe(
        (resData) => {
          this.sps = resData["consultant"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      if (this.userLevel == 3) {
        this.fetchActive();
        this.fetchActiveProjects();
      } else {
        this.fetch();
        this.fetchProjects();
      }
      this.isLoading = false;
    }, 2000);
  }

  onCancel() {
    this.router.navigate(["/consultant"]);
  }

  /**
   *Contacts Start Here.
   */
  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserContactList(temps);
  }

  fetch() {
    this.isLoadingContacts = true;
    this.contactService.getUserContactList(this._id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.tempusermanagelist = [...resData["userlist"]];
          this.usermanagelist = [...resData["userlist"]];
        }
      },
      (err) => {
        this.isFoundContacts = false;
        this.errorFound(err);
      },
      () => this.getUserContactList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoadingContacts = false;
    }, 4000);
  }

  fetchActive() {
    this.isLoadingContacts = true;
    this.contactService.getActiveUserContactList(this._id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.tempusermanagelist = [...resData["userlist"]];
          this.usermanagelist = [...resData["userlist"]];
        }
      },
      (err) => {
        this.isFoundContacts = false;
        this.errorFound(err);
      },
      () => this.getUserContactList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoadingContacts = false;
    }, 4000);
  }

  getUserContactList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   *onDeleteContact method is used to delete contact / status 4.
   */
  onDeleteContact(_id, index) {
    this.contactService.deleteContact(_id).subscribe(
      (resData) => {
        this.translate.get("CONTACT_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONTACT_DELETE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onPauseContact method is used to pause contact / status 3.
   */
  onPauseContact(_id, index) {
    this.contactService.pauseContact(_id).subscribe(
      (resData) => {
        this.translate.get("CONTACT_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONTACT_PAUSE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onReactiveContact method is used to delete contact / status 1.
   */
  onReactiveContact(_id, index) {
    this.contactService.activeContact(_id).subscribe(
      (resData) => {
        this.translate.get("CONTACT_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("CONTACT_ACTIVE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *Projects Start Here.
   */
  updateProjectsFilter(event) {
    const fil = event.target.value;
    const tempprojects = this.tempprojectsmanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserContactList(tempprojects);
  }

  fetchProjects() {
    this.isLoadingProjects = true;
    this.projectService.getUserProjectList(this._id).subscribe(
      (resData) => {
        this.totalProjectsItems = resData["totalItems"];
        if (this.totalProjectsItems == 0) {
          this.errCode = "DATA_NOT_EXIST";
          this.isFoundProjects = false;
        } else {
          this.tempprojectsmanagelist = [...resData["projectlist"]];
          this.projectmanagelist = [...resData["projectlist"]];
        }
      },
      (err) => {
        this.isFoundProjects = false;
        this.errorFound(err);
      },
      () => this.getUserProjectsList(this.projectmanagelist)
    );
    setTimeout(() => {
      this.isLoadingProjects = false;
    }, 5000);
  }

  fetchActiveProjects() {
    this.isLoadingProjects = true;
    this.projectService.getActiveUserProjectList(this._id).subscribe(
      (resData) => {
        this.totalProjectsItems = resData["totalItems"];
        if (this.totalProjectsItems == 0) {
          this.errCode = "DATA_NOT_EXIST";
          this.isFoundProjects = false;
        } else {
          this.tempprojectsmanagelist = [...resData["projectlist"]];
          this.projectmanagelist = [...resData["projectlist"]];
        }
      },
      (err) => {
        this.isFoundProjects = false;
        this.errorFound(err);
      },
      () => this.getUserProjectsList(this.projectmanagelist)
    );
    setTimeout(() => {
      this.isLoadingProjects = false;
    }, 5000);
  }

  getUserProjectsList(res) {
    this.projectmanagelist = res;
    this.projectsDataSource = new MatTableDataSource<any>(
      this.projectmanagelist
    );
    setTimeout(() => {
      this.projectsDataSource.paginator = this.projectsPaginator;
    }, 0);
  }

  /**
   *onDeleteContact method is used to delete contact / status 4.
   */
  onDeleteProject(_id, index) {
    this.projectService.deleteProject(_id).subscribe(
      (resData) => {
        this.translate.get("PROJECT_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PROJECT_DELETE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onPauseContact method is used to pause contact / status 3.
   */
  onPauseProject(_id, index) {
    this.projectService.pauseProject(_id).subscribe(
      (resData) => {
        this.translate.get("PROJECT_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PROJECT_PAUSE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onReactiveContact method is used to delete contact / status 1.
   */
  onReactiveProject(_id, index) {
    this.projectService.activeProject(_id).subscribe(
      (resData) => {
        this.translate.get("PROJECT_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PROJECT_ACTIVE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  errorFound(err) {
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
  
  /**
   *reload data.
   */
  onReloadContacts() {
    if(this.userLevel==3){
      this.fetchActive();
    }else {
      this.fetch();
    }
  }
  
  onReloadProjects() {
    if(this.userLevel==3){
      this.fetchActiveProjects();
    }else {
      this.fetchProjects();
    }
  }
}
