import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { Branch } from "../branch.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { BranchService } from "app/service/bank/branch.service";
import { TranslateService } from "@ngx-translate/core";
import { BankService } from "app/service/bank/bank.service";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddBranchComponent implements OnInit {
  public form: FormGroup;
  isLoading: Boolean;
  selectedValue: string;
  desc: string = "";
  bankId: string;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private branchService: BranchService,
    private authService: AuthService,
    private router: Router,
    private bankService: BankService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/bank"]);
    }
    this.isLoading = true;
    this.pageTitleService.setTitle("TITLE_BANK");
    this.route.params.subscribe((param) => {
      this.bankId = param.id;
      if (this.bankId.length != 24) {
        this.router.navigate(["/bank"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.bankService.getSingleBank(this.bankId).subscribe(
        (resData) => {
          this.form = this.fb.group({
            branchCode: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(3),
              ]),
            ],
            branchName: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(36),
              ]),
            ],
            branchAddress: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(64),
              ]),
            ],
            branchPostalCode: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(7),
              ]),
            ],
            branchTelephone: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(10),
              ]),
            ],
            branchFax: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(10),
              ]),
            ],
          });
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onSubmit() {
    const newPS = new Branch(
      this.form.value.branchCode,
      this.form.value.branchName,
      this.form.value.branchAddress,
      this.form.value.branchPostalCode,
      this.form.value.branchTelephone,
      this.form.value.branchFax,
      { _id: this.bankId }
    );
    this.branchService.createBranch(newPS).subscribe(
      (res) => {
        this.translate.get("BRANCH_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/bank/view/" + this.bankId]);
      },
      (errorMessage) => {
        this.translate.get("BRANCH_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/bank/view/" + this.bankId]);
  }
}
