export class MainUrl {
    constructor() {}
  
  get url() {
    // const mainUrl = "http://localhost:8080/";
    // const mainUrl = "https://apidemotrace.herokuapp.com/";
    const mainUrl = "http://apps.aiosx.co/";
    return mainUrl;
  }
}
