import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

import { Router } from "@angular/router";
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { fadeInAnimation } from 'app/core/route-animation/route.animation';
import { ToastrService } from "ngx-toastr";

import { ProjectType } from '../projecttype.model';
import { AuthService } from 'app/service/auth-service/auth.service';
import { ProjectTypeService } from 'app/service/projecttype/projecttype.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddProjectTypeComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private projectTypeService: ProjectTypeService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/projecttype"]);
    } 
    this.pageTitleService.setTitle("PROJECT_TYPE_MAIN_TITLE");
    this.form = this.fb.group({
      title: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(24),
        ]),
      ],
      description: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(36),
        ]),
      ]
    });
  }

  onSubmit() {
    const newPS = new ProjectType(
      this.form.value.title,
      this.form.value.description
    );
    this.projectTypeService.createProjectType(newPS).subscribe(
      (res) => {
        this.translate.get("JOB_ROLE_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/projecttype"]);
      },
      (errorMessage) => {
        this.translate.get("JOB_ROLE_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }
  
  onCancel() {
    this.router.navigate(["/projecttype"]);
  }
}
