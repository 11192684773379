import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class BranchService {
  // domain = "https://apidemotrace.herokuapp.com/branch/";
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"branch/";

  getBranchsList(id) {
    const _id = id;
    return this.httpService.get(this.domain + "list/" + _id);
  }

  getBankBranchsList(id) {
    const _id = id;
    return this.httpService.get(this.domain + "bank/" + _id);
  }

  getSingleBranch(id) {
    const _id = id;
    return this.httpService.get(this.domain + "view/" + _id);
  }

  createBranch(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleBranch(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteBranch(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "delete/" + _id
    );
  }

  pauseBranch(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeBranch(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "active/" + _id
    );
  }
}
