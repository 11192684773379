import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ProjectService } from "app/service/project/project.service";
import { Task } from "app/taskboard/task.model";
import { UserService } from "app/user/user.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "ms-add-new-task",
  templateUrl: "./add-new-task.component.html",
  styleUrls: ["./add-new-task.component.scss"],
})
export class AddNewTaskComponent implements OnInit {
  addNewUserForm: FormGroup;
  emailPattern: string = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;
  isLoading: Boolean;
  temct: Array<any>;
  ctype: Array<any> = [];
  public filteredList4;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNewTaskComponent>,
    private projectService: ProjectService,
    private userService: UserService,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.addNewUserForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      project: [null, [Validators.required]],
      planningStage: [null, [Validators.required]],
      subPlanningStage: [null, [Validators.required]],
      employee: [null, [Validators.required]],
      description: [null, [Validators.required]],
      lastDate: [null, [Validators.required]],
      consultant: [null, [Validators.required]],
      urgencyLevel: [null, [Validators.required]],
      remark: [null, [Validators.required]],
      building: [null, [Validators.required]],
      floor: [null, [Validators.required]],
      document: [null, [Validators.required]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      emailAddress: [
        "",
        [Validators.required, Validators.pattern(this.emailPattern)],
      ],
      accountType: ["", [Validators.required]],
    });
    this.fetchProjectsList();
  }

  fetchProjectsList() {
    this.isLoading = true;
    this.projectService.getActiveProjectList().subscribe(
      (resData) => {
        console.log(resData);
        this.temct = [...resData["projectlist"]];
        this.temct.forEach((item) => {
          this.ctype.push({
            value: item._id,
            viewValue: item.title,
          });
        });
        this.filteredList4 = this.ctype.slice();
      },
      (err) => {
        this.errorFound(err);
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }

  // onFormSubmit method is submit a add new user form.
  onFormSubmit() {
    // const task = new Task(
    //   "test",
    //   { _id: "sss", title: "tete" },
    //   { _id: "sss", title: "tete" },
    //   { _id: "sss", title: "tete" },
    //   { _id: "sss", fullName: "tete" },
    //   "tete",
    //   "12-12-2020",
    //   {
    //     _id: "5f97287368f5402e68ce7ad7",
    //     fullName: "אופק",
    //     feePerSquareMeter: 10,
    //   },
    //   2,
    //   "dd"
    // );
    // this.dialogRef.close(task);
    // this.dialogRef.close(this.addNewUserForm.value);
  }
}
