import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { Client } from "../client.model";
import { ClientService } from "app/service/client/client.service";

import { AuthService } from "app/service/auth-service/auth.service";
import { BankService } from "app/service/bank/bank.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddClientComponent implements OnInit {
  public form: FormGroup;
  banks: Array<any> = [];
  branches: Array<any> = [];
  tems: Array<any>;
  isLoading: Boolean;
  isLoadingBranches: Boolean;
  public filteredList5;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private clientService: ClientService,
    private authService: AuthService,
    private bankService: BankService,
    private router: Router,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel == 3) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/client"]);
    }
    this.pageTitleService.setTitle("CLIENT_TITLE");
    this.fetch();
    this.form = this.fb.group({
      fullName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(64),
        ]),
      ],
      cid: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
        ]),
      ],
      address: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(64),
        ]),
      ],
      telephone: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
        ]),
      ],
      fax: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
        ]),
      ],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(64),
          Validators.email,
        ]),
      ],
      cellphone: [
        null,
        Validators.compose([Validators.minLength(9), Validators.maxLength(10)]),
      ],
      bankID: [null, Validators.compose([Validators.required])],
      branchId: [null, Validators.compose([Validators.required])],
      accountNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(11),
        ]),
      ],
    });
  }

  fetch() {
    this.bankService.getBanksList().subscribe(
      (resData) => {
        this.tems = [...resData["banks"]];
        this.tems.forEach((item) => {
          this.banks.push({
            value: item._id,
            viewValue: item.bankName,
            code: item.bankCode,
          });
        });
        this.filteredList5 = this.banks.slice();
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "DATA_NOT_FOUND";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  loadBranches(bankIndex) {
    this.isLoadingBranches = true;
    this.branches = [];
    const temps = this.tems.filter(function (d) {
      return d._id.indexOf(bankIndex.value) !== -1 || !bankIndex.value;
    });
    temps[0].bankBranches.forEach((item) => {
      this.branches.push({
        value: item._id,
        viewValue: item.branchName,
        branch: item.branchCode,
      });
    });
    setTimeout(() => {
      this.isLoadingBranches = false;
    }, 3000);
  }

  onSubmit() {
    const newPS = new Client(
      this.form.value.fullName,
      this.form.value.cid,
      this.form.value.address,
      this.form.value.telephone,
      this.form.value.fax,
      this.form.value.email,
      this.form.value.cellphone,
      {
        bank: {
          _id: this.form.value.bankID.value,
          bankCode: this.form.value.bankID.code,
          bankName: this.form.value.bankID.viewValue,
        },
        branch: {
          _id: this.form.value.branchId.value,
          branchCode: this.form.value.branchId.branch,
          branchName: this.form.value.branchId.viewValue,
        },
        accountNumber: this.form.value.accountNumber,
      }
    );
    console.log(newPS);
    this.clientService.createClient(newPS).subscribe(
      (res) => {
        this.toastr.success("CLIENT_ADD_SUCCESS");
        this.router.navigate(["/clients"]);
      },
      (errorMessage) => {
        this.translate.get("CLIENT_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/clients"]);
  }
}
