import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { fadeInAnimation } from 'app/core/route-animation/route.animation';

import { Project } from "../project.model";
import { AuthService } from 'app/service/auth-service/auth.service';
import { BankService } from 'app/service/bank/bank.service';
import { FloorService } from 'app/service/floor/floor.service';

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditProjectComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  floor: Project;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private floorService: FloorService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.toastr.warning("אין לך הרשאה מתאימה!");
      this.router.navigate(["/floor"]);
    } 
    this.pageTitleService.setTitle("ניהול קומות");
    this.isLoading = true;
    this.route.params.subscribe((param) => {
      this._id = param.id;
      this.floorService.getSingleFloor(this._id).subscribe((resData) => {
        this.floor = resData["floor"];
      });
    });
    setTimeout(() => {
      this.isLoading = false;
      this.form = this.fb.group({
        title: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(24),
          ]),
        ],
        description: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(64),
          ]),
        ],
        projectInternalId: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(12),
          ]),
        ],
        projecttype: [
          null,
          Validators.compose([
            Validators.required,
          ]),
        ],
      });
    }, 2000);
  }

  onSubmit() {
    const planningStages: Array<any> =[];
    const newPS = new Project(
      this.form.value.title,
      this.form.value.description,
      this.form.value.internalserialnumber,
      {
        _id: this.form.value.level.value,
        title: this.form.value.level.viewValue,
      },
      this.form.value.address,
      this.form.value.blockNumber,
      this.form.value.smoothNumber,
      this.form.value.smoothSpace,
      this.form.value.projectArea,
      this.form.value.basementArea,
      this.form.value.quantityOfBuildings,
      this.form.value.quantityOfUnits,
      this.form.value.projectFees,
      this.form.value.basementArea,
      this._id
    );
    this.floorService.editSingleFloor(newPS, this._id).subscribe(
      (res) => {
        this.toastr.success("פרטי קומה עודכנו בהצלחה!");
        this.router.navigate(["/floor"]);
      },
      (errorMessage) => {
        this.toastr.warning("בעיה בעדכון פרטי קומה!");
      }
    );
  }
  
  onCancel() {
    this.router.navigate(["/floor"]);
  }
}
