export class Bank {
    constructor(
      public bankCode: number,
      public bankName: string,
      public bankAddress: string,
      public bankPostalCode: number,
      public bankTelephone: number,
      public bankFax: string,
      public bankBranches?: [],
      public status?: number,
      public creator?: string,
      public _id?: string
    ) {}
  
  }