import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from "app/service/auth-service/auth.service";
import { BranchService } from "app/service/bank/branch.service";
import { Branch } from "../branch.model";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewBranchComponent implements OnInit {
  private _id: string;
  isLoading: Boolean;
  userLevel: number;
  sps: Branch;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private branchService: BranchService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("TITLE_BANK");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.branchService.getSingleBranch(this._id).subscribe(
        (resData) => {
          this.sps = resData["bank"];
        },
        (err) => {
          this.isFound = false;
          if(err.status==404) {
            this.errCode = 'DATA_NOT_EXIST'
          }else {
            this.errCode = 'DATA_NOT_FOUND'
          }
          this.translate.get('ERROR_MSG').subscribe((text:string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onCancel() {
    this.router.navigate(["/bank/view/" + this.sps.bank._id]);
  }
}
