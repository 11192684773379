<mat-card-content>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <div class="user-content">
        <mat-card>
          <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <div class="pad-y-sm" *ngIf="!isLoading">
            <div class="gene-card-title">
              <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxLayout="column">
                    <h4>{{'BANK_INFO'|translate}}</h4>
                  </div> <span fxFlex></span>
              </div>
              <mat-divider></mat-divider>
            </div>
            <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
            <div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile" *ngIf="isFound">
              <div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)" fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100" class="user-contact-info">
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                  <h4>{{'BANK_NAME'|translate}} :</h4>
                </div>
                <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                  <h4 *ngIf="userLevel == 1" class="activeIcon">
                    <a class="gene-block" href="/bank/edit/{{ sps._id }}">{{ sps.bankName }}</a>
                  </h4>
                  <h4 *ngIf="userLevel != 1" class="activeIcon">
                    {{ sps.bankName }}
                  </h4>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                  <h4>
                    <i class="fa fa-code primary-text" aria-hidden="true"></i>
                    {{'BANK_CODE'|translate}} :
                  </h4>
                </div>
                <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                  <h4>{{ sps.bankCode }}</h4>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                  <h4>
                    <i class="fa fa-map-marker primary-text" aria-hidden="true"></i>
                    {{'TITLE_ADDRESS'|translate}} :
                  </h4>
                </div>
                <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                  <h4>{{ sps.bankAddress }}</h4>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                  <h4>
                    <i class="fa fa-phone primary-text" aria-hidden="true"></i>
                    {{'TITLE_TELEPHONE'|translate}} :
                  </h4>
                </div>
                <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                  <h4 class="editIcon">
                    <a class="gene-block" href="tel:{{ sps.bankTelephone }}">{{ sps.bankTelephone }}</a>
                  </h4>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                  <h4>
                    <i class="fa fa-fax primary-text" aria-hidden="true"></i>
                    {{'TITLE_FAX'|translate}} :
                  </h4>
                </div>
                <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                  <h4>{{ sps.bankFax }}</h4>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                  <h4>
                    <i class="fa fa-location-arrow primary-text" aria-hidden="true"></i>
                    {{'TITLE_POSTAL_CODE'|translate}} :
                  </h4>
                </div>
                <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                  <h4>{{ sps.bankPostalCode }}</h4>
                </div>
              </div>
              </div>
            </div>
            <div class="user-manage-list" *ngIf="isFound">
            <mat-divider></mat-divider>
              <mat-card>
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="mrgn-r-sm">
                      <h3>{{'BRANCH_LIST'|translate}}</h3>
                    </div>
                  </div>
                  <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
                    <mat-card-actions class="mat-pad-none margin-none">
                      <a href="/bank/branch/{{ sps._id }}" mat-raised-button mat-button-sm color="primary" *ngIf="userLevel==1">
                        {{'BRANCH_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
                      </a>&nbsp;
                      <button mat-raised-button mat-button-sm color="primary" (click)="onReload()">
                        {{'BTN_RELOAD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">refresh</mat-icon>
                      </button>
                    </mat-card-actions>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="!isLoadingBank" style="margin: auto"></mat-progress-spinner>
                <div class="table-responsive" *ngIf="isLoadingBank">
                  <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="this.totalItems == 0">{{errorMessage}}</b>
                  <table class="full-wid" mat-table [dataSource]="dataSource" *ngIf="this.totalItems > 0">
                    <ng-container matColumnDef="branchCode">
                      <th mat-header-cell *matHeaderCellDef>{{'BRANCH_CODE'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.branchCode }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="branchName">
                      <th mat-header-cell *matHeaderCellDef>{{'BRANCH_NAME'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <a href="/bank/viewBranch/{{ element._id }}">{{
                          element.branchName
                        }}</a>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="branchAddress">
                      <th mat-header-cell *matHeaderCellDef>{{'TITLE_ADDRESS'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.branchAddress }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="branchPostalCode">
                      <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.branchPostalCode }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="branchTelephone">
                      <th mat-header-cell *matHeaderCellDef>{{'TITLE_TELEPHONE'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.branchTelephone }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="branchFax">
                      <th mat-header-cell *matHeaderCellDef>{{'TITLE_FAX'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.branchFax }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.createdAt | date: "dd/MM/yyyy" }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        <a href="/bank/viewBranch/{{ element._id }}" mat-icon-button>
                          <mat-icon class="mat-icon-grey gene-text-lg">remove_red_eye</mat-icon>
                        </a>
                        <a *ngIf="userLevel==1" href="/bank/editBranch/{{ element._id }}" mat-icon-button>
                          <mat-icon class="mat-icon-grey gene-text-lg editIcon">edit</mat-icon>
                        </a>
                        <button mat-icon-button (click)="onReactive(element._id, i)" *ngIf="element.status != 1 && userLevel==1">
                          <mat-icon class="mat-icon-grey gene-text-lg activeIcon">play_arrow</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onPause(element._id, i)" *ngIf="userLevel==1">
                          <mat-icon class="mat-icon-grey gene-text-lg pauseIcon">pause</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onDelete(element._id, i)" *ngIf="userLevel==1">
                          <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                  </table>
                </div>
                <div class="pagination" *ngIf="totalItems > 0">
                  <mat-paginator [length]="usermanagelist.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </div>
              </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="pad-wrap">
              <mat-card-actions>
                  <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BACK_TO_BANK_LIST'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon></button>
              </mat-card-actions>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card-content>
