<div class="gene-forgotpassV2 pad-y-xl">
   <div fxLayout="row" fxLayoutAlign="center start">
      <div fxLayout="column" fxFlex="100" fxFlex.xs="90" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="70" fxFlex.xl="60">

         <div fxLayout="row wrap" fxLayoutAlign="center stretch" class="height-full pad-t-xl pad-b-xl form-wrap-row">
            <div fxLayout="column" fxFlex="100" fxFlex.xs="100" fxFlex.sm="500px" fxFlex.md="544px" fxFlex.lg="544px" fxFlex.xl="544px">
               <mat-card class="gene-login-v2 height-full ">
                  <div>
                     <div fxLayout="column" fxLayoutAlign="center center" class="mrgn-b-sm text-center">
                        <h2>{{'LOGOUT_IDLE_LOGON'|translate}}</h2>
                        <p>{{"AIOSX_SYSTEM" |translate}}</p>
                     </div>
                     <form #form="ngForm"(ngSubmit)="send(form.value)" fxLayoutAlign="center start">
                     <div fxLayout="column" fxFlex.xl="75" fxFlex.lg="75" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="100" fxLayoutAlign="start">
                        <mat-form-field class="full-wid mrgn-b-md">
                           <input matInput placeholder="{{'Enter_Your_E-Mail'|translate}}" type="text" name="email" required [(ngModel)]="email">
                           <span matSuffix><i class="material-icons">vpn_key</i></span>                              
                        </mat-form-field>
                        <div fxLayoutAlign="center start" class="mrgn-b-md gene-btn-wrap">
                           <button mat-flat-button color="green-600" class="gene-btn-lg full-wid" [disabled]="!form.valid">{{'Reset_Password'|translate}}</button>    
                        </div>
                     </div>
                     </form>
                     <div  fxLayout="row wrap" fxLayoutAlign="center start">
                        <div fxLayout="column" fxFlex.xl="75" fxFlex.lg="75" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="100" fxLayoutAlign="start">
                           <div fxLayoutAlign="center start" class="gene-btn-wrap">
                              <button mat-flat-button color="accent" class="gene-btn-lg full-wid" (click)="router.navigate(['/session/loginV2'])">{{'Already_have_an_account'|translate}}</button>    
                           </div>
                        </div>
                     </div>
                  </div>
               </mat-card>
            </div>
         </div>
      </div>
   </div>
</div>