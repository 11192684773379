import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'NAV_HOME',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'reporting',
    name: 'NAV_REPORTS',
    type: 'link',
    icon: 'explore',
  },
  {
    state: 'user',
    name: 'NAV_USER',
    type: 'link',
    icon: 'account_box'
  },
  {
    state: 'taskboard',
    name: 'NAV_TASK_BOARD',
    type: 'link',
    icon: 'drag_indicator'
  },
  {
    state: 'calendar',
    name: 'NAV_REPORTING',
    type: 'link',
    icon: 'receipt'
  },
  {
    state: 'clients',
    name: 'NAV_CLIENTS',
    type: 'link',
    icon: 'group',
  },
  {
    state: 'consultant',
    name: 'NAV_CONSULTANT',
    type: 'link',
    icon: 'recent_actors'
  },
  {
    state: 'contacts',
    name: 'NAV_CONTACTS',
    type: 'link',
    icon: 'contact_phone'
  },
  {
    state: 'projects',
    name: 'NAV_PROJECTS',
    type: 'link',
    icon: 'location_city'
  },
  {
    state: 'consultanttype',
    name: 'NAV_CONSULTING_TYPE',
    type: 'link',
    icon: 'local_library'
  },
  {
    state: 'floor',
    name: 'NAV_FLOOR',
    type: 'link',
    icon: 'view_headline'
  },
  {
    state: 'planningstage',
    name: 'NAV_PLANNING_STAGES',
    type: 'link',
    icon: 'account_balance_wallet'
  },
  {
    state: 'documents',
    name: 'NAV_DOCUMENTS',
    type: 'link',
    icon: 'insert_drive_file',
  },
  {
    state: 'projecttype',
    name: 'NAV_PROJECT_TYPE',
    type: 'link',
    icon: 'business'
  },
  {
    state: 'bank',
    name: 'NAV_BANK',
    type: 'link',
    icon: 'account_balance',
  },
  {
    state: 'jobrole',
    name: 'NAV_JOB_ROLE',
    type: 'link',
    icon: 'supervisor_account'
  },
];

@Injectable()
export class AdminMenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu:any) {
    MENUITEMS.push(menu);
  }
}
