import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { fadeInAnimation } from 'app/core/route-animation/route.animation';
import { AuthService } from 'app/service/auth-service/auth.service';
import { BankService } from 'app/service/bank/bank.service';
import { ToastrService } from "ngx-toastr";

import { Bank } from '../bank.model';

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddBankComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private bankService: BankService,
    private authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/bank"]);
    }
    this.pageTitleService.setTitle('TITLE_BANK');
    this.form = this.fb.group({
      bankCode: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
      ],
      bankName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(36),
        ]),
      ],
      bankAddress: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(64),
        ]),
      ],
      bankPostalCode: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(7),
        ]),
      ],
      bankTelephone: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
        ]),
      ],
      bankFax: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
        ]),
      ],
    });
  }

  onSubmit() {
    const newPS = new Bank(
      this.form.value.bankCode,
      this.form.value.bankName,
      this.form.value.bankAddress,
      this.form.value.bankPostalCode,
      this.form.value.bankTelephone,
      this.form.value.bankFax
    );
    this.bankService.createBank(newPS).subscribe(
      (res) => {
        this.translate.get("BANK_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/bank"]);
      },
      (errorMessage) => {
        this.translate.get("BRANCH_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }
  
  onCancel() {
    this.router.navigate(["/bank"]);
  }
}
