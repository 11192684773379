<mat-card-content>
   <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
      <mat-card fxFlex="100%">
         <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
               <div fxLayout="column">
                  <h4>{{'JOB_ROLE_ADD_NEW'|translate}}</h4>
               </div> 
               <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
         </div>
         <div class="gene-card-content">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
               <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                     <mat-form-field class="full-wid mrgn-b-md">
                        <input matInput placeholder="{{'JOB_ROLE_TITLE'|translate}}" formControlName="title" minlength="5" maxlength="12">
                     </mat-form-field>
                     <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched">
                        {{'JOB_ROLE_TITLE_REQUIRE'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('minlength') && form.controls['title'].touched">
                        {{'JOB_ROLE_TITLE_MIN'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('maxlength') && form.controls['title'].touched">
                        {{'JOB_ROLE_TITLE_MAX'|translate}}
                     </small>
                  </div>
               </div>
               <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                     <mat-form-field class="full-wid mrgn-b-md">
                        <input matInput placeholder="{{'JOB_ROLE_DESCRIPTION'|translate}}" formControlName="description" maxlength="64">
                     </mat-form-field>
                     <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">
                        {{'JOB_ROLE_DESCRIPTION_REQUIRE'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('minlength') && form.controls['description'].touched">
                        {{'JOB_ROLE_DESCRIPTION_MIN'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched">
                        {{'JOB_ROLE_DESCRIPTION_MAX'|translate}}
                     </small>
                  </div>
               </div>
               <div class="pad-wrap">
                  <mat-card-actions>
                     <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">{{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
                     <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
                  </mat-card-actions>
               </div>
            </form>
         </div>
      </mat-card>
   </div>
</mat-card-content>