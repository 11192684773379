import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";

import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";

import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { Planningstage } from "../planningstage.model";
import { PlanningStageService } from 'app/service/planningstage/planningstage.service';
import { AuthService } from 'app/service/auth-service/auth.service';
import { SubPlanningStageService } from 'app/service/planningstage/subplanningstage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./singleplanningstage.component.html",
  styleUrls: ["./singleplanningstage.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class SinglePlanningStageComponent implements OnInit {
  sps: Planningstage;
  subPlanningStage: Array<any> = [];
  temp: Array<any> = [];
  private _id;
  isFound: Boolean = true;
  errCode: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  userLevel: number;
  totalItems: number;

  displayedColumns: string[] = [
    "serialNumber",
    "title",
    "description",
    "createdAt",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private psService: PlanningStageService,
    private spsService: SubPlanningStageService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.pageTitleService.setTitle("PLANNING_STAGE_MAIN_TITLE");
    this.userLevel = this.authService.getUserLevel();
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
        this.router.navigate(["/planningstage"]);
      }
      this.psService.getSinglePlanningStage(this._id).subscribe(
        (resData) => {
          this.sps = resData["planningStage"];
          this.fetch();
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }

  fetch() {
    this.isLoading = true;
    this.psService.getSubPlanningStage(this._id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["subplanningstages"]];
          this.usermanagelist = [...resData["subplanningstages"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    this.spsService.deleteSubPlanningStage(_id).subscribe(
      (resData) => {
        this.translate.get("SUB_PLANNING_STAGE_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("SUB_PLANNING_STAGE_DELETE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    this.spsService.pauseSubPlanningStage(_id).subscribe(
      (resData) => {
        this.translate.get("SUB_PLANNING_STAGE_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("SUB_PLANNING_STAGE_PAUSE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    this.spsService.activeSubPlanningStage(_id).subscribe(
      (resData) => {
        this.translate.get("SUB_PLANNING_STAGE_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetch();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("SUB_PLANNING_STAGE_ACTIVE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/planningstage"]);
  }
  
  /**
   *reload data.
   */
  onReload() {
    this.fetch();
    this.isLoading = true;
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
