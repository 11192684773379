import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  MatCardModule,
  MatButtonModule,
  MatProgressBarModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatInputModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragulaModule } from "ng2-dragula";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { TaskBoardComponent } from "./task-board/task-board.component";
import { TaskBoardRoutes } from "./task-board.routing";
import { AddTaskComponent } from "./task-board-add/task-board-add.component";
import { MatSelectFilterModule } from "mat-select-filter";
import { ViewSingleTaskComponent } from "./task-board-view/task-board-view.component";

@NgModule({
  declarations: [TaskBoardComponent, AddTaskComponent, ViewSingleTaskComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatSelectModule,
    MatProgressBarModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    FlexLayoutModule,
    TranslateModule,
    DragulaModule.forRoot(),
    RouterModule.forChild(TaskBoardRoutes),
    MatSelectFilterModule,
  ],
})
export class TaskBoardModule {}
