import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class ConsultantService {
  // domain = "https://apidemotrace.herokuapp.com/consultant/";
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"consultant/";

  getActiveConsultantList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getConsultantList() {
    return this.httpService.get(this.domain + "list");
  }

  getActiveConsultantConsultantTypeList(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "activeconsultantlist/" + _id
    );
  }

  getConsultantConsultantTypeList(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "consultantlist/" + _id
    );
  }

  getSingleConsultant(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createConsultant(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleConsultant(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteConsultant(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "delete/" + _id
    );
  }

  pauseConsultant(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "pause/" + _id
    );
  }

  activeConsultant(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "active/" + _id
    );
  }
}
