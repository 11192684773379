import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { SubPlanningStage } from "../subplanningstage.model";
import { SubPlanningStageService } from 'app/service/planningstage/subplanningstage.service';
import { AuthService } from 'app/service/auth-service/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./editsubplanningstage.component.html",
  styleUrls: ["./editsubplanningstage.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditSubPlanningStageComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  sps: SubPlanningStage;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private psService: SubPlanningStageService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/planningstage"]);
    } 
    this.isLoading = true;
    this.pageTitleService.setTitle("PLANNING_STAGE_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      this.psService.getSubSinglePlanningStage(this._id).subscribe(
        (resData) => {
          this.sps = resData["subplanningStage"];
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      if(this.isFound) {
        this.form = this.fb.group({
          title: [
            this.sps.title,
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(24),
            ]),
          ],
          description: [
            this.sps.description,
            Validators.compose([
              Validators.required,
              Validators.minLength(12),
              Validators.maxLength(64),
            ]),
          ],
        });
      }
      this.isLoading = false;
    }, 2000);
  }

  onSubmit() {
    this.sps.title = this.form.value.title;
    this.sps.description = this.form.value.description;
    this.psService.editSubSinglePlanningStage(this.sps, this._id).subscribe(
      (res) => {
        this.translate.get("SUB_PLANNING_STAGE_UPDATE_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/planningstage/view/" + this.sps.planningStage._id]);
      },
      (errorMessage) => {
        this.translate.get("SUB_PLANNING_STAGE_UPDATE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/planningstage/view/" + this.sps.planningStage._id]);
  }
}
