import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class ContactService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"contact/";

  getActiveContactList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getContactList() {
    return this.httpService.get(this.domain + "list");
  }

  getActiveUserContactList(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "activeuserlist/" + _id
    );
  }

  getUserContactList(id) {
    const _id = id;
    return this.httpService.get(this.domain + "userlist/" + _id);
  }

  getSingleContact(id) {
    const _id = id;
    return this.httpService.get(this.domain + "view/" + _id);
  }

  createContact(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleContact(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteContact(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "delete/" + _id
    );
  }

  pauseContact(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "pause/" + _id
    );
  }

  activeContact(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "active/" + _id
    );
  }
}
