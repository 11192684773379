import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "app/service/auth-service/auth.service";
import { Consultant } from "../consultant.model";
import { BankService } from "app/service/bank/bank.service";
import { ConsultantService } from "app/service/consultant/consultant.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditConsultantComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  sps: Consultant;
  banks: Array<any> = [];
  branches: Array<any> = [];
  tems: Array<any>;
  isLoadingBranches: Boolean;
  public filteredList5;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private consultantService: ConsultantService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private bankService: BankService,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel == 3) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/consultant"]);
    }
    this.isLoading = true;
    this.pageTitleService.setTitle("CONSULTANT_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/consultant"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.consultantService.getSingleConsultant(this._id).subscribe(
        (resData) => {
          this.sps = resData["consultant"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      this.fetch();
      if (this.isFound) {
        this.isLoading = false;
      }
    }, 2000);
  }

  fetch() {
    this.bankService.getBanksList().subscribe(
      (resData) => {
        this.tems = [...resData["banks"]];
        this.tems.forEach((item) => {
          this.banks.push({
            value: item._id,
            viewValue: item.bankName,
          });
        });
        this.filteredList5 = this.banks.slice();
        this.form = this.fb.group({
          fullName: [
            this.sps.fullName,
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(64),
            ]),
          ],
          cid: [
            this.sps.cid,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(9),
            ]),
          ],
          address: [
            this.sps.address,
            Validators.compose([
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(64),
            ]),
          ],
          telephone: [
            this.sps.telephone,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
          fax: [
            this.sps.fax,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
          email: [
            this.sps.email,
            Validators.compose([
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(64),
            ]),
          ],
          cellphone: [
            this.sps.cellphone,
            Validators.compose([
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
          bankID: [null, Validators.compose([Validators.required])],
          branchId: [null, Validators.compose([Validators.required])],
          accountNumber: [
            this.sps.bankInfo.accountNumber,
            Validators.compose([
              Validators.required,
              Validators.minLength(4),
              Validators.maxLength(11),
            ]),
          ],
        });
      },
      (err) => {
        this.errorFound(err);
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  loadBranches(bankIndex) {
    this.isLoadingBranches = true;
    this.branches = [];
    const temps = this.tems.filter(function (d) {
      return d._id.indexOf(bankIndex.value) !== -1 || !bankIndex.value;
    });
    temps[0].bankBranches.forEach((item) => {
      this.branches.push({
        value: item._id,
        viewValue: item.branchName,
      });
    });
    setTimeout(() => {
      this.isLoadingBranches = false;
    }, 3000);
  }

  onSubmit() {
    console.log(this.branches.indexOf((element) => {}));
    if (this.form.value.bankID != null) {
      this.sps.bankInfo.bank._id = this.form.value.bankID;
      this.sps.bankInfo.branch._id = this.form.value.branchId;
    }
    this.sps.fullName = this.form.value.fullName;
    this.sps.cid = this.form.value.cid;
    this.sps.address = this.form.value.address;
    this.sps.telephone = this.form.value.telephone;
    this.sps.fax = this.form.value.fax;
    this.sps.email = this.form.value.email;
    this.sps.cellphone = this.form.value.cellphone;
    this.sps.bankInfo.accountNumber = this.form.value.accountNumber;
    this.consultantService.editSingleConsultant(this.sps, this._id).subscribe(
      (res) => {
        this.translate.get("CONSULTANT_UPDATE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.router.navigate(["/consultant/view/" + this._id]);
      },
      (errorMessage) => {
        this.translate.get("CONSULTANT_UPDATE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/consultant"]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
