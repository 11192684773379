<mat-card-content>
  <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
  <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard" *ngIf="!isLoading">
    <mat-card fxFlex="100%">
       <div class="gene-card-title">
          <div fxLayout="row" fxLayoutAlign="start center">
             <div fxLayout="column">
                <h4>{{'BRANCH_EDIT'|translate}}</h4>
             </div>
             <span fxFlex></span>
          </div>
          <mat-divider></mat-divider>
       </div>
         <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
       <div class="gene-card-content" *ngIf="isFound">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
             <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                   <mat-form-field class="full-wid mrgn-b-md">
                      <input matInput placeholder="{{'BRANCH_CODE'|translate}}" formControlName="branchCode" minlength="3"  maxlength="3">
                   </mat-form-field>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchCode'].hasError('required') && form.controls['branchCode'].touched">
                     {{'BRANCH_CODE_REQUIRE'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchCode'].hasError('minlength') && form.controls['branchCode'].touched">
                     {{'BRANCH_CODE_LENGTH'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchCode'].hasError('maxlength') && form.controls['branchCode'].touched">
                     {{'BRANCH_CODE_LENGTH'|translate}}
                   </small>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                   <mat-form-field class="full-wid mrgn-b-md">
                      <input matInput placeholder="{{'BRANCH_NAME'|translate}}" formControlName="branchName" minlength="3" maxlength="36">
                   </mat-form-field>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchName'].hasError('required') && form.controls['branchName'].touched">
                     {{'BRANCH_NAME_REQUIRE'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchName'].hasError('minlength') && form.controls['branchName'].touched">
                     {{'BRANCH_NAME_MIN'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchName'].hasError('maxlength') && form.controls['branchName'].touched">
                     {{'BRANCH_NAME_MAX'|translate}}
                   </small>
                </div>
             </div>
             <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                   <mat-form-field class="full-wid mrgn-b-md">
                      <input matInput placeholder="{{'TITLE_ADDRESS'|translate}}" formControlName="branchAddress" minlength="9" maxlength="64">
                   </mat-form-field>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchAddress'].hasError('required') && form.controls['branchAddress'].touched">
                     {{'TITLE_ADDRESS_REQIURE'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchAddress'].hasError('minlength') && form.controls['branchAddress'].touched">
                     {{'TITLE_ADDRESS_MIN'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchAddress'].hasError('maxlength') && form.controls['branchAddress'].touched">
                     {{'TITLE_ADDRESS_MAX'|translate}}
                   </small>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                   <mat-form-field class="full-wid mrgn-b-md">
                      <input matInput placeholder="{{'TITLE_POSTAL_CODE'|translate}}" formControlName="branchPostalCode" minlength="5" maxlength="7">
                   </mat-form-field>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchPostalCode'].hasError('required') && form.controls['branchPostalCode'].touched">
                     {{'TITLE_POSTAL_CODE_REQIURE'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchPostalCode'].hasError('minlength') && form.controls['branchPostalCode'].touched">
                     {{'TITLE_POSTAL_CODE_MIN'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchPostalCode'].hasError('maxlength') && form.controls['branchPostalCode'].touched">
                     {{'TITLE_POSTAL_CODE_MAX'|translate}}
                   </small>
                </div>
             </div>
             <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                   <mat-form-field class="full-wid mrgn-b-md">
                      <input matInput placeholder="{{'TITLE_TELEPHONE'|translate}}" formControlName="branchTelephone" minlength="9" maxlength="10">
                   </mat-form-field>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchTelephone'].hasError('required') && form.controls['branchTelephone'].touched">
                     {{'TITLE_TELEPHONE_REQIURE'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchTelephone'].hasError('minlength') && form.controls['branchTelephone'].touched">
                     {{'TITLE_TELEPHONE_MIN'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchTelephone'].hasError('maxlength') && form.controls['branchTelephone'].touched">
                     {{'TITLE_TELEPHONE_MAX'|translate}}
                   </small>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                   <mat-form-field class="full-wid mrgn-b-md">
                      <input matInput placeholder="{{'TITLE_FAX'|translate}}" formControlName="branchFax" minlength="9" maxlength="10">
                   </mat-form-field>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchFax'].hasError('required') && form.controls['branchFax'].touched">
                     {{'TITLE_FAX_REQIURE'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchFax'].hasError('minlength') && form.controls['branchFax'].touched">
                     {{'TITLE_FAX_MIN'|translate}}
                   </small>
                   <small class="gene-block warn-text" *ngIf="form.controls['branchFax'].hasError('maxlength') && form.controls['branchFax'].touched">
                     {{'TITLE_FAX_MAX'|translate}}
                   </small>
                </div>
             </div>
             <div class="pad-wrap">
                <mat-card-actions>
                  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">{{'BTN_UPDATE'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
                  <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
                </mat-card-actions>
             </div>
          </form>
       </div>
    </mat-card>
 </div>
</mat-card-content>
