<mat-card-content>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <div class="user-content">
        <mat-card>
          <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <div class="pad-y-sm" *ngIf="!isLoading">
            <div class="gene-card-title">
              <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxLayout="column">
                    <h4>{{'BRANCH_INFO'|translate}}</h4>
                  </div> <span fxFlex></span>
              </div>
              <mat-divider></mat-divider>
            </div>
            <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
            <div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile" *ngIf="isFound">
              <div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)" fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100" class="user-contact-info">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>{{'BANK_NAME'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4 *ngIf="userLevel == 1" class="activeIcon">
                      <a class="gene-block" href="/bank/edit/{{ sps.bank._id }}" >{{ sps.bank.bankName }}</a>
                    </h4>
                    <h4 *ngIf="userLevel != 1" class="activeIcon">
                      {{ sps.bank.bankName }}
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>{{'BRANCH_NAME'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4 *ngIf="userLevel == 1">
                      <a class="gene-block" href="/bank/editBranch/{{ sps._id }}" >{{ sps.branchName }}</a>
                    </h4>
                    <h4 *ngIf="userLevel != 1">{{ sps.branchName }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>
                      <i class="fa fa-code primary-text" aria-hidden="true"></i>
                      {{'BRANCH_CODE'|translate}} :
                    </h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4>{{ sps.branchCode }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>
                      <i class="fa fa-map-marker primary-text" aria-hidden="true"></i>
                      {{'TITLE_ADDRESS'|translate}} :
                    </h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4>{{ sps.branchAddress }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>
                      <i class="fa fa-phone primary-text" aria-hidden="true"></i>
                      {{'TITLE_TELEPHONE'|translate}} :
                    </h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4 class="editIcon">
                      <a class="gene-block" href="tel:{{ sps.branchTelephone }}">{{ sps.branchTelephone }}</a>
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>
                      <i class="fa fa-fax primary-text" aria-hidden="true"></i>
                      {{'TITLE_FAX'|translate}} :
                    </h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4>{{ sps.branchFax }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>
                      <i class="fa fa-location-arrow primary-text" aria-hidden="true"></i>
                      {{'TITLE_POSTAL_CODE'|translate}} :
                    </h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4>{{ sps.branchPostalCode }}</h4>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div class="pad-wrap">
                  <mat-card-actions>
                      <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BACK_TO_BANK_DATA'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon></button>
                  </mat-card-actions>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card-content>
