export class User {
    constructor(
      public fullName: string,
      public email: string,
      public telephone: string,
      public cid: string,
      public level: number,
      public address: string,
      public bankInfo?: {
        bank: {
          _id: string,
          bankCode?: string,
          bankName?: string
        },
        branch: {
          _id: string,
          branchCode?: string,
          branchName?: string
        },
        accountNumber: string
      },
      public password?: string,
      public cellphone?: string,
      public fax?: string,
      public reports?: [],
      public creator?: string,
      public _id?: string,
      public status?: number
    ) {}
  
  }
  