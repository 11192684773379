import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { SelectionModel } from "@angular/cdk/collections";

import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { UserService } from "../user.service";
import { AuthService } from 'app/service/auth-service/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-planningstage",
  templateUrl: "./listuser.component.html",
  styleUrls: ["./listuser.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ListUserComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  popUpNewUserResponse: any;
  popUpEditUserResponse: any;
  popUpDeleteUserResponse: any;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  color = {
    root: "warn",
    admin: "accent",
    user: "primary",
  };
  levels: Array<string> = ["", "מנהל על", "מנהל", "משתמש"];
  status: Array<string> = ["", "פעיל", "טרם הופעל", "מושהה", "לא פעיל"];
  colors: Array<string> = ["", "blue", "gray", "orange", "red"];
  userLevel: number;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  displayedColumns: string[] = [
    "user",
    "emailAddress",
    "status",
    "accountType",
    "dateCreated",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate : TranslateService
  ) {}

  ngOnInit() {
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("USERS_MAIN_TITLE");
    this.fetch();
    this.isLoading = true;
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }

  fetch() {
    this.userService.getUsersList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["userlist"]];
          this.usermanagelist = [...resData["userlist"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActive() {
    this.userService.getUsersList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempusermanagelist = [...resData["userlist"]];
          this.usermanagelist = [...resData["userlist"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /**
   * addNewUserDialog method is used to open a add new user dialog.
   */
  addNewUserDialog() {
    // this.coreService.addNewUserDailog().subscribe(
    //   (res) => {
    //     this.popUpNewUserResponse = res;
    //   },
    //   (err) => console.log(err),
    //   () => this.getAddUserPopupResponse(this.popUpNewUserResponse)
    // );
  }

  /**
   *getAddUserPopupResponse method is used to get a new user dialog response.
   *if response will be get then add new user into user list.
   */
  getAddUserPopupResponse(response: any) {
    if (response) {
      let addUser = {
        img: "assets/img/user-4.jpg",
        firstName: response.firstName,
        lastName: response.lastName,
        emailAddress: response.emailAddress,
        accountType: response.accountType,
        status: "Active",
        statusTime: "Since 1 hour",
        dateCreated: new Date(),
        accountTypeColor: this.color[response.accountType],
      };
      this.usermanagelist.push(addUser);
      this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
      this.dataSource.paginator = this.paginator;
    }
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    if (_id === "5f55406a86813f289cb1517d") {
      this.translate.get("ACTION_FORBIDDEN").subscribe((text: string) => {
          this.toastr.warning(text);
      });
    } else {
      this.userService.deleteUser(_id).subscribe(
        (resData) => {
          this.translate.get("USERS_DELETE_SUCCESS").subscribe((text: string) => {
              this.toastr.success(text);
          });
          this.fetch();
          this.isLoading = true;
        },
        (errorMessage) => {
          this.translate.get("USERS_DELETE_WARNING").subscribe((text: string) => {
              this.toastr.warning(text);
          });
        }
      );
    }
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    if (_id === "5f55406a86813f289cb1517d") {
      this.translate.get("ACTION_FORBIDDEN").subscribe((text: string) => {
          this.toastr.warning(text);
      });
    } else {
      this.userService.pauseUser(_id).subscribe(
        (resData) => {
          this.translate.get("USERS_PAUSE_SUCCESS").subscribe((text: string) => {
              this.toastr.success(text);
          });
          this.fetch();
          this.isLoading = true;
        },
        (errorMessage) => {
          this.translate.get("USERS_PAUSE_WARNING").subscribe((text: string) => {
              this.toastr.warning(text);
          });
        }
      );
    }
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    if (_id === "5f55406a86813f289cb1517d") {
      this.translate.get("ACTION_FORBIDDEN").subscribe((text: string) => {
          this.toastr.warning(text);
      });
    } else {
      this.userService.activeUser(_id).subscribe(
        (resData) => {
          this.translate.get("USERS_ACTIVE_SUCCESS").subscribe((text: string) => {
              this.toastr.success(text);
          });
          this.fetch();
          this.isLoading = true;
        },
        (errorMessage) => {
          this.translate.get("USERS_ACTIVE_WARNING").subscribe((text: string) => {
              this.toastr.warning(text);
          });
        }
      );
    }
  }

  /**
   * getDeleteResponse method is used to delete a user from the user list.
   */
  getDeleteResponse(response: string, i) {
    if (response == "yes") {
      this.dataSource.data.splice(i, 1);
      this.dataSource = new MatTableDataSource(this.dataSource.data);
    }
  }

  /**
   * onEdit method is used to open a edit dialog.
   */
  onEdit(data, index) {
    // this.coreService.editList(data).subscribe(
    //   (res) => {
    //     this.popUpEditUserResponse = res;
    //   },
    //   (err) => console.log(err),
    //   () => this.getEditResponse(this.popUpEditUserResponse, data, index)
    // );
  }

  /**
   * getEditResponse method is used to edit a user data.
   */
  getEditResponse(response: any, data, i) {
    if (response) {
      (this.usermanagelist[i].firstName = response.firstName),
        (this.usermanagelist[i].lastName = response.lastName),
        (this.usermanagelist[i].emailAddress = response.emailAddress),
        (this.usermanagelist[i].accountType = response.accountType),
        (this.usermanagelist[i].accountTypeColor = this.color[
          response.accountType
        ]);
    }
  }

  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActive();
    }else {
      this.fetch();
    }
    this.isLoading = true;
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
