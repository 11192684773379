import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class ProjectTypeService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"projecttype/";

  getActiveProjectTypeList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getProjectTypeList() {
    return this.httpService.get(this.domain + "list");
  }

  getSingleProjectType(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createProjectType(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleProjectType(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteProjectType(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "delete/" + _id
    );
  }

  pauseProjectType(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "pause/" + _id
    );
  }

  activeProjectType(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "active/" + _id
    );
  }
}
