<mat-card class="mrgn-b-none">
   <div class="footer-card-content pad-t-xs" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="mrgn-b-md">
         <div class="footer-logo mrgn-b-sm">
            <a href="javascript:void(0)" (click)="onClick()">
               <div class="logo-sign inline-block"><img src="assets/img/samllDMS.png" width="100" height="47"></div>
            </a>
         </div>
         <div class="copyright-info">
            <p class="mrgn-b-none">{{'RIGHTS'|translate}} © 2017 - 2020 AIOSX. </p>
         </div>
      </div>
   </div>
</mat-card>
