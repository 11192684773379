import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTabsModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule, MatProgressBarModule
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { ConsultantRoutes } from "./consultant.routing";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";
import { MatSelectFilterModule } from 'mat-select-filter';

import { EditConsultantComponent } from './edit/edit.component';
import { ListConsultantComponent } from './list/list.component';
import { ViewConsultantComponent } from './view/view.component';
import { AddContactComponent } from './contact/add.component';
import { AddNewConsultantComponent } from './add/add.component';

@NgModule({
  declarations: [
    AddNewConsultantComponent,
    EditConsultantComponent,
    ListConsultantComponent,
    ViewConsultantComponent,
    AddContactComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ConsultantRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
		MatTabsModule,
    MatSelectFilterModule 
  ],
})
export class ConsultantModule {}
