import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SelectionModel } from "@angular/cdk/collections";
import { CustomValidators } from 'ng2-validation';

import { AuthService } from "app/service/auth-service/auth.service";
import { Project } from "app/project/project.model";
import { ProjectTypeService } from "app/service/projecttype/projecttype.service";
import { ProjectService } from "app/service/project/project.service";
import { IProject } from "app/interfaces/project";
import { PlanningStageService } from "app/service/planningstage/planningstage.service";
import { ContactService } from "app/service/contact/contact.service";
import { IProjectContact } from "app/interfaces/project-contact";
import { TranslateService } from '@ngx-translate/core';
import { ConsultantService } from 'app/service/consultant/consultant.service';
import { IConsultant } from 'app/interfaces/consultant';

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddProjectComponent implements OnInit {
  public form: FormGroup;
  public form2: FormGroup;
  public form3: FormGroup;
  public form4: FormGroup;
  public form5: FormGroup;
  public form6: FormGroup;
  private _id;
  levels: Array<any> = [];
  tems: Array<any>;
  isLoading: Boolean;
  isLoadingPS: Boolean;
  isLoadingContacts: Boolean;
  isLoadingContactsList: Boolean;
  public filteredList5;
  public filteredListPS;
  public filteredListContacts;
  public selectedValue;
  psArray: Array<any> = [];
  temPs: Array<any>;
  contactsArray: Array<any> = [];
  temContact: Array<any>;
  temc: Array<any> = [];
  planningStages: Array<IProject> = [];
  contacts: Array<IProjectContact> = [];
  planningStagesTotal: number = 0;
  selectedIndex: number = 0;
  isLoadingData: Boolean;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  displayedColumns: string[] = ["serialNumber", "title", "total", "action"];
  private clientSelected: number;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: false }) paginatorContacts: MatPaginator;
  dataSourceContacts: any;
  displayedColumnsContacts: string[] = [
    "serialNumber",
    "fullName",
    "level",
    "action",
  ];
  selectionContacts = new SelectionModel<any>(true, []);
  
  public selectedValueConsultants;
  isLoadingConsultant: Boolean;
  isLoadingConsultantList: Boolean;
  public filteredListConsultants;
  tempConsultants: Array<any>;
  consultantsArray: Array<any> = [];
  consultants: Array<IConsultant> = [];
  @ViewChild(MatPaginator, { static: false }) paginatorConsultants: MatPaginator;
  dataSourceConsultants: any;
  displayedColumnsConsultants: string[] = [
    "serialNumber",
    "fullName",
    "action",
  ];
  selectionConsultants = new SelectionModel<any>(true, []);
  
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private fb2: FormBuilder,
    private fb3: FormBuilder,
    private fb4: FormBuilder,
    private fb5: FormBuilder,
    private pageTitleService: PageTitleService,
    private projectTypeService: ProjectTypeService,
    private planningStageTypeService: PlanningStageService,
    private contactService: ContactService,
    private consultantService: ConsultantService,
    private projectService: ProjectService,
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("PROJECT_TITLE");
    const userLevel = this.authService.getUserLevel();
    if (userLevel == 3) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/clients/view/" + this._id]);
    }
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/clients"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    });
    this.fetch();
    this.isLoading = true;
    this.fetchPS();
    this.isLoadingPS = true;
    this.fetchContacts();
    this.isLoadingContacts = true;
    this.fetchConsultants();
    this.form = this.fb.group({
      title: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(24),
        ]),
      ],
      description: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(64),
        ]),
      ],
      projectInternalId: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(12),
        ]),
      ],
      projecttype: [null, Validators.compose([Validators.required])],
      date: [null, Validators.compose([Validators.required, CustomValidators.date])],
    });
    this.form2 = this.fb2.group({
      address: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(64),
        ]),
      ],
      blockNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(7),
        ]),
      ],
      smoothNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(4),
        ]),
      ],
      smoothSpace: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(5),
        ]),
      ],
      projectArea: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(9),
        ]),
      ],
      basementArea: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(9),
        ]),
      ],
      quantityOfBuildings: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
        ]),
      ],
      quantityOfUnits: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5),
        ]),
      ],
      projectFees: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(9),
        ]),
      ],
    });
    this.form3 = this.fb3.group({
      planningStageArray: [null, Validators.compose([Validators.required])],
      planningStageFee: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
      ],
    });
    this.form4 = this.fb4.group({
      contactName: [null, Validators.compose([Validators.required])],
    });
    this.form5 = this.fb5.group({
      consultingOffice: [null, Validators.compose([Validators.required])],
      feePerSquareMeter: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(4),
        ])
      ],
    });
  }

  fetch() {
    this.projectTypeService.getProjectTypeList().subscribe((resData) => {
      this.tems = [...resData["projectTypeList"]];
      this.tems.forEach((item) => {
        this.levels.push({
          value: item._id,
          viewValue: item.title,
        });
      });
      this.filteredList5 = this.levels.slice();
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchPS() {
    this.planningStageTypeService
      .getActivePlanningStages()
      .subscribe((resData) => {
        this.temPs = [...resData["planningstages"]];
        this.temPs.forEach((item) => {
          this.psArray.push({
            _id: item._id,
            title: item.title,
          });
        });
        this.filteredListPS = this.psArray.slice();
      });
    setTimeout(() => {
      this.isLoadingPS = false;
    }, 3000);
  }

  fetchContacts() {
    this.contactService
      .getActiveUserContactList(this._id)
      .subscribe((resData) => {
        this.temContact = [...resData["userlist"]];
        this.temContact.forEach((item) => {
          this.contactsArray.push({
            _id: item._id,
            fullName: item.fullName,
            level: item.level.title,
          });
        });
        this.filteredListContacts = this.contactsArray.slice();
      });
    setTimeout(() => {
      this.isLoadingContacts = false;
    }, 3000);
  }

  fetchConsultants() {
    this.isLoadingConsultant = true;
    this.consultantService
      .getActiveConsultantList()
      .subscribe((resData) => {
        this.tempConsultants = [...resData["consultant"]];
        this.tempConsultants.forEach((item) => {
          this.consultantsArray.push({
            _id: item._id,
            fullName: item.fullName
          });
        });
        this.filteredListConsultants = this.consultantsArray.slice();
      });
    setTimeout(() => {
      this.isLoadingConsultant = false;
    }, 3000);
  }

  onAddConsultant() {
    this.consultants.push({
      _id: this.form5.value.consultingOffice._id,
      fullName: this.form5.value.consultingOffice.fullName,
      feePerSquareMeter: parseInt(this.form5.value.feePerSquareMeter),
    });
    const id = this.form5.value.consultingOffice._id;
    let pos = this.consultantsArray.map(function(e) {return e._id}).indexOf(id);
    this.consultantsArray.splice(pos, 1);
    this.filteredListConsultants = this.consultantsArray.slice();
    this.isLoadingConsultant = true;
    setTimeout(() => {
      this.isLoadingConsultant = false;
    }, 1000);
    this.form5.reset();
    this.selectedValueConsultants = "";
    this.getConsultantList(this.consultants);
  }

  changeValueConsultant(val) {
    this.selectedValueConsultants = val.source.value.level;
  }

  getConsultantList(res) {
    this.isLoadingConsultant = true;
    this.dataSourceConsultants = new MatTableDataSource<any>(res);
    setTimeout(() => {
      this.isLoadingConsultant = false;
      this.dataSourceConsultants.paginatorConsultants = this.paginatorConsultants;
    }, 1000);
  }

  onRemoveConsultant(index) {
    this.consultantsArray.push(this.consultants[index]);
    this.filteredListConsultants = this.consultantsArray.slice();
    this.isLoadingConsultant = true;
    this.consultants.splice(index, 1);
    this.getConsultantList(this.consultants);
    setTimeout(() => {
      this.isLoadingConsultant = false;
    }, 3000);
  }

  onAddPlanningStage() {
    if (
      this.planningStagesTotal + parseInt(this.form3.value.planningStageFee) <=
      100
    ) {
      this.planningStages.push({
        _id: this.form3.value.planningStageArray._id,
        title: this.form3.value.planningStageArray.title,
        planningStageFee: parseInt(this.form3.value.planningStageFee),
      });
      this.planningStagesTotal += parseInt(this.form3.value.planningStageFee);
      let pos = this.psArray.map(function(e) {return e._id}).indexOf(this.form3.value.planningStageArray._id);
      this.psArray.splice(pos, 1);
      this.filteredListPS = this.psArray.slice();
      this.isLoadingPS = true;
      setTimeout(() => {
        this.isLoadingPS = false;
      }, 2000);
      this.form3.reset();
      this.getUserList(this.planningStages);
    } else {
      this.translate.get("PROJECT_PLANNING_STAGE_LIMIT").subscribe((text: string) => {
        this.toastr.warning(text);
      });
    }
  }

  onRemoveItem(index) {
    const sub = this.planningStages[index].planningStageFee;
    this.psArray.push(this.planningStages[index]);
    this.filteredListPS = this.psArray.slice();
    this.planningStages.splice(index, 1);
    this.planningStagesTotal -= sub;
    this.form3.reset();
    this.isLoadingPS = true;
    setTimeout(() => {
      this.isLoadingPS = false;
    }, 1000);
    this.getUserList(this.planningStages);
  }

  getUserList(res) {
    this.isLoadingData = true;
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.isLoadingData = false;
      this.dataSource.paginator = this.paginator;
    }, 1000);
  }

  onAddContact() {
    this.contacts.push(this.form4.value.contactName);
    const id = this.form4.value.contactName._id;
    let pos = this.contactsArray.map(function(e) {return e._id}).indexOf(id);
    this.contactsArray.splice(pos, 1);
    this.filteredListContacts = this.contactsArray.slice();
    this.isLoadingContacts = true;
    setTimeout(() => {
      this.isLoadingContacts = false;
    }, 1000);
    this.form4.reset();
    this.selectedValue = "";
    this.getContactList(this.contacts);
  }

  changeValue(val) {
    this.selectedValue = val.source.value.level;
  }

  onRemoveContact(index) {
    console.log(this.contacts[index]);
    this.contactsArray.push(this.contacts[index]);
    this.filteredListContacts = this.contactsArray.slice();
    this.isLoadingContacts = true;
    setTimeout(() => {
      this.isLoadingContacts = false;
    }, 3000);
    this.contacts.splice(index, 1);
    this.getContactList(this.contacts);
  }

  getContactList(res) {
    this.isLoadingContactsList = true;
    this.dataSourceContacts = new MatTableDataSource<any>(res);
    setTimeout(() => {
      this.isLoadingContactsList = false;
      this.dataSourceContacts.paginatorContacts = this.paginatorContacts;
    }, 1000);
  }

  onSubmit() {
    const newPS = new Project(
      this.form.value.title,
      this.form.value.description,
      this.form.value.projectInternalId,
      {
        _id: this.form.value.projecttype.value,
        title: this.form.value.projecttype.viewValue,
      },
      this.form.value.date,
      this.form2.value.address,
      this.form2.value.blockNumber,
      this.form2.value.smoothNumber,
      this.form2.value.smoothSpace,
      this.form2.value.projectArea,
      this.form2.value.basementArea,
      this.form2.value.quantityOfBuildings,
      this.form2.value.quantityOfUnits,
      this.form2.value.projectFees,
      { _id: this._id },
      this.planningStages,
      this.consultants
    );
    this.projectService.createProject(newPS).subscribe(
      (res) => {
        this.toastr.success("פרויקט הוסף בהצלחה!");
        this.router.navigate(["/clients/view/" + this._id]);
      },
      (errorMessage) => {
        console.log(errorMessage)
        this.toastr.warning("בעיה בהוספת פרויקט!");
      }
    );
  }

  onCancel() {
    this.router.navigate(["/clients/view/" + this._id]);
  }

  /**
   * nextStep method is used to go to the next step of form.
   */
  nextStep() {
    this.selectedIndex += 1;
  }

  /**
   * previousStep method is used to go to the previous step of form.
   */
  previousStep() {
    this.selectedIndex -= 1;
  }
}
