import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";

import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { AuthService } from 'app/service/auth-service/auth.service';
import { BankService } from 'app/service/bank/bank.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "ms-planningstage",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ListBankComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  userLevel: number;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  displayedColumns: string[] = [
    "bankCode",
    "bankName",
    "bankAddress",
    "bankPostalCode",
    "bankTelephone",
    "bankFax",
    "createdAt",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private bankService: BankService,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle('TITLE_BANK');
    this.fetch();
    this.isLoading = true;
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }

  fetch() {
    this.bankService.getBanksList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.translate.get("DATA_NOT_EXIST").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        } else {
          this.tempusermanagelist = [...resData["banks"]];
          this.usermanagelist = [...resData["banks"]];
        }
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "DATA_NOT_FOUND";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActive() {
    this.bankService.getBanksList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.translate.get("DATA_NOT_EXIST").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        } else {
          this.tempusermanagelist = [...resData["banks"]];
          this.usermanagelist = [...resData["banks"]];
        }
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "DATA_NOT_FOUND";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
      this.bankService.deleteBank(_id).subscribe(
        (resData) => {
          this.translate.get("BANK_ACTIVE_SUCCESS").subscribe((text: string) => {
              this.toastr.success(text);
          });
          this.fetch();
          this.isLoading = true;
        },
        (errorMessage) => {
          this.translate.get("BANK_ACTIVE_WARNING").subscribe((text: string) => {
              this.toastr.warning(text);
          });
        }
      );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
      this.bankService.pauseBank(_id).subscribe(
        (resData) => {
          this.translate.get("BANK_ACTIVE_SUCCESS").subscribe((text: string) => {
              this.toastr.success(text);
          });
          this.fetch();
          this.isLoading = true;
        },
        (errorMessage) => {
          this.translate.get("BANK_ACTIVE_WARNING").subscribe((text: string) => {
              this.toastr.warning(text);
          });
        }
      );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
      this.bankService.activeBank(_id).subscribe(
        (resData) => {
          this.translate.get("BANK_ACTIVE_SUCCESS").subscribe((text: string) => {
              this.toastr.success(text);
          });
          this.fetch();
          this.isLoading = true;
        },
        (errorMessage) => {
          this.translate.get("BANK_ACTIVE_WARNING").subscribe((text: string) => {
              this.toastr.warning(text);
          });
        }
      );
    
  }

  /**
   *reload data.
   */
  onReload() {
    this.fetch();
  }
}
