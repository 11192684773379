import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { AuthService } from "app/service/auth-service/auth.service";
import { ClientService } from "app/service/client/client.service";

@Component({
  selector: "ms-planningstage",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ListClientComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  isLoading: Boolean;
  userLevel: number;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  displayedColumns: string[] = [
    "serialNumber",
    "fullName",
    "telephone",
    "fax",
    "email",
    "address",
    "createdAt",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private clientService: ClientService,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate : TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("CLIENT_TITLE");
    this.userLevel = this.authService.getUserLevel();
    this.onReload();
  }

  updateFilter(event) {
    const fil = event.target.value;
    const temps = this.tempusermanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserList(temps);
  }

  fetch() {
    this.isLoading = true;
    this.clientService.getClientList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        this.dataFound(resData["clients"]);
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActive() {
    this.isLoading = true;
    this.clientService.getActiveClientList().subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        this.dataFound(resData["clients"]);
      },
      (err) => {
        this.isFound = false;
        this.errorFound(err);
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    this.clientService.deleteClient(_id).subscribe(
      (resData) => {
        this.translate.get("CLIENT_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        if(this.userLevel==3){
          this.fetchActive();
        }else{
          this.fetch();
        }
      },
      (errorMessage) => {
        this.translate.get("CLIENT_DELETE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    this.clientService.pauseClient(_id).subscribe(
      (resData) => {
        this.loadSuccessToastr("CLIENT_PAUSE_SUCCESS");
        this.onReload();
      },
      (errorMessage) => {
        this.loadErrorToastr("CLIENT_PAUSE_WARNING");
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    this.clientService.activeClient(_id).subscribe(
      (resData) => {
        this.translate.get("CLIENT_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        if(this.userLevel==3){
          this.fetchActive();
        }else{
          this.fetch();
        }
      },
      (errorMessage) => {
        this.translate.get("CLIENT_ACTIVE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActive();
    }else{
      this.fetch();
    }
  }
  
  /**
   * On Error
   */
  
  errorFound(err) {
    this.loadErrorToastr("ERROR_MSG");
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
  }

  /**
   * On Data Found
   */
  private dataFound(resData) {
    if (this.totalItems == 0) {
      this.errCode = "DATA_NOT_FOUND";
    } else {
      this.tempusermanagelist = [...resData];
      this.usermanagelist = [...resData];
    }
  }

  /**
   * Load Error Message
   */
  private loadErrorToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
  
  /**
   * Load Success Message
   */
  private loadSuccessToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
        this.toastr.success(text);
    });
  }
}
