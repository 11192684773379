import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";

import { Contact } from "../contact.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { JobRoleService } from "app/service/jobrole/jobrole.service";
import { ContactService } from "app/service/contact/contact.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditContactComponent implements OnInit {
  public form: FormGroup;
  private _id;
  private contact: Contact;
  levels: Array<any> = [];
  tems: Array<any>;
  isLoading: Boolean;
  isLoadingBranches: Boolean;
  public filteredList5;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private jobRoleService: JobRoleService,
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel == 3) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/contacts"]);
    }
    this.pageTitleService.setTitle("CONTACT_TITLE");
    this.isLoading = true;
    this.fetch();
    this.isLoadingBranches = true;
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/contacts"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.contactService.getSingleContact(this._id).subscribe(
        (resData) => {
          this.contact = resData["contact"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
      this.form = this.fb.group({
        level: [null, Validators.compose([Validators.required])],
        fullName: [
          this.contact.fullName,
          Validators.compose([
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(64),
          ]),
        ],
        telephone: [
          this.contact.telephone,
          Validators.compose([
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(10),
          ]),
        ],
        fax: [
          this.contact.fax,
          Validators.compose([
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(10),
          ]),
        ],
        email: [
          this.contact.email,
          Validators.compose([
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(64),
            Validators.email,
          ]),
        ],
        cellphone: [
          this.contact.cellphone,
          Validators.compose([
            Validators.minLength(9),
            Validators.maxLength(10),
          ]),
        ],
      });
    }, 2000);
  }

  fetch() {
    this.jobRoleService.getActiveJobRoleList().subscribe(
      (resData) => {
        this.tems = [...resData["jobRoleList"]];
        this.tems.forEach((item) => {
          this.levels.push({
            _id: item._id,
            title: item.title,
          });
        });
        this.filteredList5 = this.levels.slice();
      },
      (err) => {
        this.errorFound(err);
      }
    );
    setTimeout(() => {
      this.isLoadingBranches = false;
    }, 3000);
  }

  onSubmit() {
    if (this.form.value.level != null) {
      this.contact.level = this.form.value.level;
    }
    this.contact.fullName = this.form.value.fullName;
    this.contact.telephone = this.form.value.telephone;
    this.contact.fax = this.form.value.fax;
    this.contact.email = this.form.value.email;
    this.contact.cellphone = this.form.value.cellphone;
    this.contactService.editSingleContact(this.contact, this._id).subscribe(
      (res) => {
        this.translate.get("CONTACT_UPDATE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.router.navigate(["/contacts/view/" + this._id]);
      },
      (errorMessage) => {
        this.translate.get("CONTACT_UPDATE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/contact"]);
  }

  onRetuenToClient() {
    this.router.navigate(["/clients/view/" + this.contact.client._id]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
