import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { Project } from "../project.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { ProjectService } from 'app/service/project/project.service';

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewProjectComponent implements OnInit {
  sps: Project;
  private _id;
  isLoading: Boolean;
  userLevel: number;

  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("ניהול פרויקטים");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      this.projectService.getSingleProject(this._id).subscribe((resData) => {
        this.sps = resData["project"];
      });
    });
    setTimeout(() => {
      console.log(this.sps)
      this.isLoading = false;
    }, 2000);
  }
  
  onCancel() {
    this.router.navigate(["/projects"]);
  }

  onRetuenToClient() {
    this.router.navigate(["/clients/view/" + this.sps.client._id]);
  }
}
