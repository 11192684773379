import { IConsultant } from 'app/interfaces/consultant';
import { IProject } from "app/interfaces/project";

export class Project {
  constructor(
    public title: string,
    public description: string,
    public projectInternalId: number,
    public projecttype: {
      _id: string;
      title: string;
    },
    public lastDate: string,
    public address?: string,
    public blockNumber?: number,
    public smoothNumber?: number,
    public smoothSpace?: number,
    public projectArea?: number,
    public basementArea?: number,
    public quantityOfBuildings?: number,
    public quantityOfUnits?: number,
    public projectFees?: number,
    public client?: {
      _id?: string;
      fullName?: string;
    },
    public planningStage?: Array<IProject>,
    public consultants?: Array<IConsultant>,
    public mainImage?: string,
    public status?: number,
    public creator?: string,
    public _id?: string
  ) {}
}
