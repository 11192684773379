
      <mat-progress-spinner
      mode="indeterminate"
      color="primary"
      *ngIf="isLoading"
      style="margin: auto"
    ></mat-progress-spinner>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard" *ngIf="!isLoading">
   <mat-card fxFlex="100%">
      <div class="gene-card-title">
         <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
               <h4>הוספת קומה</h4>
            </div> <span fxFlex></span>
         </div>
         <mat-divider></mat-divider>
      </div>
      <div class="gene-card-content">
         <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
               <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="שם קומה" formControlName="title" maxlength="12">
                  </mat-form-field>
                  <small class="gene-block" *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
                     class="warn-text">
                     נא להזין שם קומה.
                  </small>
                  <small class="gene-block" *ngIf="form.controls['title'].hasError('minlength') && form.controls['title'].touched"
                     class="warn-text">
                     שם קומה חייב להיות לפחות 5 תווים.
                  </small>
                  <small class="gene-block" *ngIf="form.controls['title'].hasError('maxlength') && form.controls['title'].touched"
                     class="warn-text">
                     שם קומה חייב להיות לכל היותר 12 תווים
                  </small>
               </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
               <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="תיאור קומה" formControlName="description" maxlength="64">
                  </mat-form-field>
                  <small class="gene-block" *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched"
                     class="warn-text">
                     נא להזין תיאור קומה.
                  </small>
                  <small class="gene-block" *ngIf="form.controls['description'].hasError('minlength') && form.controls['description'].touched"
                     class="warn-text">
                     תיאור קומה חייב להיות לפחות 5 אותיות.
                  </small>
                  <small class="gene-block" *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched"
                     class="warn-text">
                     אורך תיאור קומה לכל היותר 64 אותיות.
                  </small>
               </div>
            </div>
            <div class="pad-wrap">
               <mat-card-actions>
                  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">עדכן<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
                  <button mat-raised-button color="warn" type="button" (click)="onCancel()">ביטול<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
               </mat-card-actions>
            </div>
         </form>
      </div>
   </mat-card>
</div>