<mat-card-content>
  <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
  <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
     <mat-card fxFlex="100%">
        <div class="gene-card-title">
           <div fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="column">
                 <h4>{{'SUB_PLANNING_STAGE_EDIT'|translate}}</h4>
              </div> 
              <span fxFlex></span>
           </div>
           <mat-divider></mat-divider>
        </div>
        <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
        <div class="gene-card-content" *ngIf="isFound">
           <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                 <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field class="full-wid mrgn-b-md">
                       <input matInput placeholder="{{'PLANNING_STAGE_TITLE'|translate}}" formControlName="title" minlength="5" maxlength="12">
                    </mat-form-field>
                    <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched">
                       {{'PLANNING_STAGE_TITLE_REQUIRE'|translate}}
                    </small>
                    <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('minlength') && form.controls['title'].touched">
                       {{'PLANNING_STAGE_TITLE_MIN'|translate}}
                    </small>
                    <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('maxlength') && form.controls['title'].touched">
                       {{'PLANNING_STAGE_TITLE_MAX'|translate}}
                    </small>
                 </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                 <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field class="full-wid mrgn-b-md">
                       <input matInput placeholder="{{'PLANNING_STAGE_DESCRIPTION'|translate}}" formControlName="description" maxlength="64">
                    </mat-form-field>
                    <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">
                       {{'PLANNING_STAGE_DESCRIPTION_REQUIRE'|translate}}
                    </small>
                    <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('minlength') && form.controls['description'].touched">
                       {{'PLANNING_STAGE_DESCRIPTION_MIN'|translate}}
                    </small>
                    <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched">
                       {{'PLANNING_STAGE_DESCRIPTION_MAX'|translate}}
                    </small>
                 </div>
              </div>
              <div class="pad-wrap">
                 <mat-card-actions>
                   <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">{{'BTN_UPDATE'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
                   <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
                 </mat-card-actions>
              </div>
           </form>
        </div>
     </mat-card>
  </div>
</mat-card-content>