import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { SubDocument } from "../sub-document.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { SubDocumentService } from "app/service/documents/sub-document.service";
import { TranslateService } from "@ngx-translate/core";
import { DocumentService } from "app/service/documents/document.service";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddSubDocumentComponent implements OnInit {
  public form: FormGroup;
  isLoading: Boolean;
  documentId: string;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private subDocumentService: SubDocumentService,
    private documentService: DocumentService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/documents"]);
    }
    this.pageTitleService.setTitle("DOCUMENTS_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this.documentId = param.id;
      if (this.documentId.length != 24) {
        this.router.navigate(["/documents"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    });
    this.isLoading = true;
    this.documentService.getSingleDocument(this.documentId).subscribe(
      (resData) => {
        this.form = this.fb.group({
          title: [
            null,
            Validators.compose([
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(24),
            ]),
          ],
          description: [
            null,
            Validators.compose([
              Validators.required,
              Validators.minLength(12),
              Validators.maxLength(64),
            ]),
          ],
        });
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "DATA_NOT_FOUND";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onSubmit() {
    const newPS = new SubDocument(
      this.form.value.title,
      this.form.value.description,
      { _id: this.documentId }
    );
    this.subDocumentService.createSubdocument(newPS).subscribe(
      (res) => {
        this.translate.get("SUB_DOCUMENT_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/documents/view/" + this.documentId]);
      },
      (errorMessage) => {
        this.translate.get("SUB_DOCUMENT_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/documents/view/" + this.documentId]);
  }
}
