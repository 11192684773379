<mat-card-content>
   <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
      <mat-card fxFlex="100%">
         <div class="gene-card-title">
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start center">
               <div class="mrgn-r-sm">
                  <h3>{{'PROJECT_ADD_NEW'|translate}}</h3>
               </div>
            </div>
            <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
               <mat-card-actions class="mat-pad-none margin-none">
                  <button mat-raised-button style="background-color: red" type="button" (click)="onCancel()">
                     {{'CLIENT_BACK_TO_DATA'|translate}} <mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon>
                  </button>
               </mat-card-actions>
            </div>
            </div>
            <mat-divider></mat-divider>
         </div>
         <div class="gene-card-content">
            <mat-tab-group [selectedIndex]="selectedIndex">
               <mat-tab label="{{'PROJECT_WIZARD_GENERAL_INFO'|translate}}">
                  <form [formGroup]="form">
                     <div fxLayout="column">
                     <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100" fxFlex.gt-md="33">
                           <mat-form-field class="full-wid mrgn-b-md">
                           <input matInput placeholder="{{'PROJECT_NAME'|translate}}" formControlName="title" minlength="5" maxlength="64" />
                           </mat-form-field>
                           <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched">
                              {{'PROJECT_NAME_REQUIRE'|translate}}
                           </small>
                           <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('minlength') && form.controls['title'].touched">
                              {{'PROJECT_NAME_MIN'|translate}}
                           </small>
                           <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('maxlength') && form.controls['title'].touched">
                              {{'PROJECT_NAME_MAX'|translate}}
                           </small>
                        </div>
                        <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100" fxFlex.gt-md="33">
                           <mat-form-field class="full-wid mrgn-b-md">
                           <input matInput placeholder="{{'PROJECT_DESCRIPTION'|translate}}" formControlName="description" />
                           </mat-form-field>
                           <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('required') &&form.controls['description'].touched">
                              {{'PROJECT_DESCRIPTION_REQUIRE'|translate}}
                           </small>
                           <small class="gene-block warn-text"*ngIf="form.controls['description'].hasError('minlength') && form.controls['description'].touched">
                              {{'PROJECT_DESCRIPTION_MIN'|translate}}
                           </small>
                           <small class="gene-block warn-text" *ngIf=" form.controls['description'].hasError('maxlength') && form.controls['description'].touched">
                              {{'PROJECT_DESCRIPTION_MAX'|translate}}
                           </small>
                        </div>
                        <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100" fxFlex.gt-md="33">
                           <mat-form-field class="full-wid mrgn-b-md">
                           <input matInput placeholder="{{'PROJECT_INTERNAL_NUMBER'|translate}}" formControlName="projectInternalId" minlength="1" maxlength="12"/>
                           </mat-form-field>
                           <small class="gene-block warn-text" *ngIf="form.controls['projectInternalId'].hasError('required') && form.controls['projectInternalId'].touched">
                              {{'PROJECT_INTERNAL_NUMBER_REQIURE'|translate}}
                           </small>
                           <small class="gene-block warn-text" *ngIf="form.controls['projectInternalId'].hasError('minlength') && form.controls['projectInternalId'].touched">
                              {{'PROJECT_INTERNAL_NUMBER_NIM'|translate}}
                           </small>
                           <small class="gene-block warn-text" *ngIf="form.controls['projectInternalId'].hasError('maxlength') && form.controls['projectInternalId'].touched">
                              {{'PROJECT_INTERNAL_NUMBER_MAX'|translate}}
                           </small>
                        </div>
                     </div>
                     <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                           <mat-form-field class="full-wid mrgn-b-md">
                              <input matInput placeholder="תאריך סיום עבודה" formControlName="date" type="date">
                           </mat-form-field>
                        </div>
                        <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                           <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-bar>
                           <mat-select placeholder="{{'PROJECT_TYPE'|translate}}" formControlName="projecttype" *ngIf="!isLoading">
                              <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'viewValue'" [array]="levels" (filteredReturn)="filteredList5 = $event" *ngIf="!isLoading"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList5" [value]="item">
                                 {{ item.viewValue }}
                              </mat-option>
                           </mat-select>
                           <small class="gene-block warn-text" *ngIf="form.controls['projecttype'].hasError('projecttype') && form.controls['projecttype'].touched">
                              {{'PROJECT_TYPE_REQUIRE'|translate}}
                           </small>
                        </div>
                     </div>
                     <div fxLayout="row" fxLayoutAlign="start">
                        <button mat-button mat-raised-button [disabled]="!form.valid" (click)="nextStep()"type="button">
                           {{'BTN_NEXT'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">chevron_left</mat-icon>
                        </button>
                     </div>
                     </div>
                  </form>
               </mat-tab>
               <mat-tab label="{{'PROJECT_WIZARD_TECHNICAL_INFO'|translate}}" [disabled]="!form.valid">
                  <form [formGroup]="form2">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'TITLE_ADDRESS'|translate}}" formControlName="address" minlength="9" maxlength="64">
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['address'].hasError('required') && form2.controls['address'].touched">
                                 {{'TITLE_ADDRESS_REQIURE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['address'].hasError('minlength') && form2.controls['address'].touched">
                                 {{'TITLE_ADDRESS_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['address'].hasError('maxlength') && form2.controls['address'].touched">
                                 {{'TITLE_ADDRESS_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_BLOCK_NUMBER'|translate}}" formControlName="blockNumber" minlength="3" maxlength="7" />
                              </mat-form-field>
                              <small class="gene-block warn-text"*ngIf="form2.controls['blockNumber'].hasError('required') && form2.controls['blockNumber'].touched">
                                 {{'PROJECT_BLOCK_NUMBER_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text"*ngIf="form2.controls['blockNumber'].hasError('minlength') && form2.controls['blockNumber'].touched">
                                 {{'PROJECT_BLOCK_NUMBER_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['blockNumber'].hasError('maxlength') && form2.controls['blockNumber'].touched">
                                 {{'PROJECT_BLOCK_NUMBER_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_SMOOTH_NUMBER'|translate}}" formControlName="smoothNumber" minlength="1" maxlength="4" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['smoothNumber'].hasError('required') && form2.controls['smoothNumber'].touched">
                                 {{'PROJECT_SMOOTH_NUMBER_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['smoothNumber'].hasError('minlength') && form2.controls['smoothNumber'].touched">
                                 {{'PROJECT_SMOOTH_NUMBER_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['smoothNumber'].hasError('maxlength') && form2.controls['smoothNumber'].touched">
                                 {{'PROJECT_SMOOTH_NUMBER_MAX'|translate}}
                              </small>
                           </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
                              <small class="gene-block primary-text">{{'PROJECT_AREA_TITLE'|translate}}</small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_SMOOTH_TOTAL_AREA'|translate}}" formControlName="smoothSpace" minlength="2" maxlength="5" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['smoothSpace'].hasError('required') && form2.controls['smoothSpace'].touched">
                                 {{'PROJECT_SMOOTH_TOTAL_AREA_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['smoothSpace'].hasError('minlength') && form2.controls['smoothSpace'].touched">
                                 {{'PROJECT_SMOOTH_TOTAL_AREA_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['smoothSpace'].hasError('maxlength') && form2.controls['smoothSpace'].touched">
                                 {{'PROJECT_SMOOTH_TOTAL_AREA_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_TOTAL_AREA'|translate}}" formControlName="projectArea" minlength="2" maxlength="9" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['projectArea'].hasError('required') && form2.controls['projectArea'].touched">
                                 {{'PROJECT_TOTAL_AREA_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['projectArea'].hasError('minlength') && form2.controls['projectArea'].touched">
                                 {{'PROJECT_TOTAL_AREA_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['projectArea'].hasError('maxlength') && form2.controls['projectArea'].touched">
                                 {{'PROJECT_TOTAL_AREA_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_BASEMENT_TOTAL_AREA'|translate}}" formControlName="basementArea" minlength="2" maxlength="9" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['basementArea'].hasError('required') && form2.controls['basementArea'].touched">
                                 {{'PROJECT_BASEMENT_TOTAL_AREA_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['basementArea'].hasError('minlength') && form2.controls['basementArea'].touched">
                                 {{'PROJECT_BASEMENT_TOTAL_AREA_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['basementArea'].hasError('maxlength') && form2.controls['basementArea'].touched">
                                 {{'PROJECT_BASEMENT_TOTAL_AREA_MAX'|translate}}
                              </small>
                           </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_QUANTITY_OF_BUILDINGS'|translate}}" formControlName="quantityOfBuildings" minlength="1" maxlength="3" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['quantityOfBuildings'].hasError('required') && form2.controls['quantityOfBuildings'].touched">
                                 {{'PROJECT_QUANTITY_OF_BUILDINGS_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['quantityOfBuildings'].hasError('minlength') && form2.controls['quantityOfBuildings'].touched">
                                 {{'PROJECT_QUANTITY_OF_BUILDINGS_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['quantityOfBuildings'].hasError('maxlength') && form2.controls['quantityOfBuildings'].touched">
                                 {{'PROJECT_QUANTITY_OF_BUILDINGS_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_QUANTITY_OF_UNITS'|translate}}" formControlName="quantityOfUnits" minlength="1" maxlength="5" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['quantityOfUnits'].hasError('required') && form2.controls['quantityOfUnits'].touched">
                                 {{'PROJECT_QUANTITY_OF_UNITS_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf=" form2.controls['quantityOfUnits'].hasError('minlength') && form2.controls['quantityOfUnits'].touched">
                                 {{'PROJECT_QUANTITY_OF_UNITS_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['quantityOfUnits'].hasError('maxlength') && form2.controls['quantityOfUnits'].touched">
                                 {{'PROJECT_QUANTITY_OF_UNITS_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_FEES'|translate}}" formControlName="projectFees" minlength="3" maxlength="9" />
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form2.controls['projectFees'].hasError('required') && form2.controls['projectFees'].touched">
                                 {{'PROJECT_FEES_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['projectFees'].hasError('minlength') && form2.controls['projectFees'].touched">
                                 {{'PROJECT_FEES_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form2.controls['projectFees'].hasError('maxlength') && form2.controls['projectFees'].touched">
                                 {{'PROJECT_FEES_MAX'|translate}}
                              </small>
                           </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start">
                           <button mat-button type="button" (click)="previousStep()">
                              <mat-icon class="mrgn-l-md gene-text-xl">chevron_right</mat-icon>{{'BTN_PREV'|translate}}
                           </button>
                           <button mat-button mat-raised-button [disabled]="!form2.valid" (click)="nextStep()" type="button">
                              {{'BTN_NEXT'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">chevron_left</mat-icon>
                           </button>
                        </div>
                     </div>
                  </form>
               </mat-tab>
               <mat-tab label="{{'NAV_PLANNING_STAGES'|translate}}" [disabled]="!form2.valid">
                  <form [formGroup]="form3">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="planningStagesTotal < 100">
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoadingPS" style="margin: auto"></mat-progress-bar>
                              <mat-select placeholder="{{'PROJECT_PLANNING_STAGE'|translate}}" formControlName="planningStageArray" *ngIf="!isLoadingPS">
                                 <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'title'" [array]="psArray" (filteredReturn)="filteredListPS = $event" *ngIf="!isLoadingPS"></mat-select-filter>
                                 <mat-option *ngFor="let item of filteredListPS" [value]="item">
                                    {{ item.title }}
                                 </mat-option>
                              </mat-select>
                              <small class="gene-block warn-text" *ngIf=" form3.controls['planningStageArray'].hasError( 'planningStageArray') && form3.controls['planningStageArray'].touched">
                                 {{'PROJECT_PLANNING_STAGE_REQUIRE'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_PLANNING_STAGE_PERCENTAGE'|translate}}" formControlName="planningStageFee" minlength="1" maxlength="2"/>
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form3.controls['planningStageFee'].hasError('required') && form3.controls['planningStageFee'].touched">
                                 {{'PROJECT_PLANNING_STAGE_PERCENTAGE_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form3.controls['planningStageFee'].hasError('minlength') && form3.controls['planningStageFee'].touched">
                                 {{'PROJECT_PLANNING_STAGE_PERCENTAGE_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form3.controls['planningStageFee'].hasError('maxlength') && form3.controls['planningStageFee'].touched">
                                 {{'PROJECT_PLANNING_STAGE_PERCENTAGE_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <button mat-button color="primary" type="button" [disabled]="!form3.valid" (click)="onAddPlanningStage()">
                                 {{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon>
                              </button>
                           </div>
                        </div>
                        <mat-card *ngIf="planningStages.length > 0">
                           <div class="table-responsive">
                              <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoadingData" style="margin: auto"></mat-progress-spinner>
                              <table class="full-wid" mat-table [dataSource]="dataSource" *ngIf="!isLoadingData">
                                 <ng-container matColumnDef="serialNumber">
                                    <th mat-header-cell *matHeaderCellDef>{{'TABLE_SERIAL_ID'|translate}}</th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                       {{ i + 1 }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="title">
                                    <th mat-header-cell *matHeaderCellDef>{{'PROJECT_PLANNING_STAGE_TITLE'|translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                       {{ element.title }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef>{{'PROJECT_PLANNING_STAGE_PERCENTAGE'|translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                       {{ element.planningStageFee }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                                    <td mat-cell *matCellDef="let element; let i = index">
                                       <button mat-icon-button (click)="onRemoveItem(i)">
                                          <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
                                       </button>
                                    </td>
                                 </ng-container>

                                 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                 <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                              </table>
                           </div>
                        </mat-card>
                        <div fxLayout="row" fxLayoutAlign="start">
                           <button mat-button type="button" (click)="previousStep()">
                              <mat-icon class="mrgn-l-md gene-text-xl">chevron_right</mat-icon>{{'BTN_PREV'|translate}}
                           </button>
                           <button mat-button mat-raised-button [disabled]="planningStagesTotal != 100" (click)="nextStep()" type="button">
                              {{'BTN_NEXT'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">chevron_left</mat-icon>
                           </button>
                        </div>
                     </div>
                  </form>
               </mat-tab>
               <mat-tab label="{{'NAV_CONTACTS'|translate}}" [disabled]="!form3.valid">
                  <form [formGroup]="form4">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoadingContacts" style="margin: auto"></mat-progress-bar>
                              <mat-select placeholder="{{'FULLNAME_CHOOSE_TITLE'|translate}}" formControlName="contactName" *ngIf="!isLoadingContacts">
                                 <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'fullName'" [array]="contactsArray" (filteredReturn)="filteredListContacts = $event" *ngIf="!isLoadingContacts"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListContacts" [value]="item" (onSelectionChange)="changeValue($event)">{{ item.fullName }}</mat-option>
                                 </mat-select>
                              <small class="gene-block warn-text" *ngIf="form4.controls['contactName'].hasError('contactName') && form4.controls['contactName'].touched">
                                 {{'FULLNAME_CHOOSE_REQUIRE'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <small class="gene-block warn-text">{{selectedValue}}</small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <button mat-button color="primary" type="button" [disabled]="!form4.valid" (click)="onAddContact()">
                                 {{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon>
                              </button>
                           </div>
                        </div>
                        <mat-card *ngIf="contacts.length > 0">
                           <div class="table-responsive">
                              <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoadingContactsList" style="margin: auto"></mat-progress-spinner>
                              <table class="full-wid" mat-table [dataSource]="dataSourceContacts" *ngIf="!isLoadingContactsList">
                                 <ng-container matColumnDef="serialNumber">
                                    <th mat-header-cell *matHeaderCellDef>{{'TABLE_SERIAL_ID'|translate}}</th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                       {{ i + 1 }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="fullName">
                                    <th mat-header-cell *matHeaderCellDef>{{'FULLNAME_TITLE'|translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                       {{ element.fullName }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="level">
                                    <th mat-header-cell *matHeaderCellDef>{{'JOB_ROLE_TITLE'|translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                       {{ element.level }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                                    <td mat-cell *matCellDef="let element; let i = index">
                                       <button mat-icon-button (click)="onRemoveContact(i)">
                                          <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
                                       </button>
                                    </td>
                                 </ng-container>

                                 <tr mat-header-row *matHeaderRowDef="displayedColumnsContacts"></tr>
                                 <tr mat-row *matRowDef="let row; columns: displayedColumnsContacts" (click)="selectionContacts.toggle(row)"></tr>
                              </table>
                           </div>
                        </mat-card>
                        <div fxLayout="row" fxLayoutAlign="start">
                           <button mat-button type="button" (click)="previousStep()">
                              <mat-icon class="mrgn-l-md gene-text-xl">chevron_right</mat-icon>{{'BTN_PREV'|translate}}
                           </button>
                           <button mat-button mat-raised-button [disabled]="contacts.length == 0" (click)="nextStep()" type="button">
                              {{'BTN_NEXT'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">chevron_left</mat-icon>
                           </button>
                        </div>
                     </div>
                  </form>
               </mat-tab>
               <mat-tab label="{{'PROJECT_CONSULTANT_TITLE'|translate}}" [disabled]="!form4.valid">
                  <form [formGroup]="form5">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoadingConsultant" style="margin: auto"></mat-progress-bar>
                              <mat-select placeholder="{{'FULLNAME_CHOOSE_TITLE'|translate}}" formControlName="consultingOffice" *ngIf="!isLoadingConsultant">
                                 <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'fullName'" [array]="consultantsArray" (filteredReturn)="filteredListConsultants = $event" *ngIf="!isLoadingConsultant"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListConsultants" [value]="item" (onSelectionChange)="changeValueConsultant($event)">{{ item.fullName }}</mat-option>
                                 </mat-select>
                              <small class="gene-block warn-text" *ngIf="form5.controls['consultingOffice'].hasError('consultingOffice') && form5.controls['consultingOffice'].touched">
                                 {{'FULLNAME_CHOOSE_REQUIRE'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid mrgn-b-md">
                                 <input matInput placeholder="{{'PROJECT_PLANNING_STAGE_PERCENTAGE'|translate}}" formControlName="feePerSquareMeter" minlength="1" maxlength="2"/>
                              </mat-form-field>
                              <small class="gene-block warn-text" *ngIf="form5.controls['feePerSquareMeter'].hasError('required') && form5.controls['feePerSquareMeter'].touched">
                                 {{'PROJECT_PLANNING_STAGE_PERCENTAGE_REQUIRE'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form5.controls['feePerSquareMeter'].hasError('minlength') && form5.controls['feePerSquareMeter'].touched">
                                 {{'PROJECT_PLANNING_STAGE_PERCENTAGE_MIN'|translate}}
                              </small>
                              <small class="gene-block warn-text" *ngIf="form5.controls['feePerSquareMeter'].hasError('maxlength') && form5.controls['feePerSquareMeter'].touched">
                                 {{'PROJECT_PLANNING_STAGE_PERCENTAGE_MAX'|translate}}
                              </small>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <button mat-button color="primary" type="button" [disabled]="!form5.valid" (click)="onAddConsultant()">
                                 {{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon>
                              </button>
                           </div>
                        </div>
                        <mat-card *ngIf="consultants.length > 0">
                           <div class="table-responsive">
                              <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoadingConsultantList" style="margin: auto"></mat-progress-spinner>
                              <table class="full-wid" mat-table [dataSource]="dataSourceConsultants" *ngIf="!isLoadingConsultantList">
                                 <ng-container matColumnDef="serialNumber">
                                    <th mat-header-cell *matHeaderCellDef>{{'TABLE_SERIAL_ID'|translate}}</th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                       {{ i + 1 }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="fullName">
                                    <th mat-header-cell *matHeaderCellDef>{{'FULLNAME_TITLE'|translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                       {{ element.fullName }}
                                    </td>
                                 </ng-container>

                                 <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
                                    <td mat-cell *matCellDef="let element; let i = index">
                                       <button mat-icon-button (click)="onRemoveConsultant(i)">
                                          <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
                                       </button>
                                    </td>
                                 </ng-container>

                                 <tr mat-header-row *matHeaderRowDef="displayedColumnsConsultants"></tr>
                                 <tr mat-row *matRowDef="let row; columns: displayedColumnsConsultants" (click)="selectionConsultants.toggle(row)"></tr>
                              </table>
                           </div>
                        </mat-card>
                        <div fxLayout="row" fxLayoutAlign="start">
                           <button mat-button type="button" (click)="previousStep()">
                              <mat-icon class="mrgn-l-md gene-text-xl">chevron_right</mat-icon>{{'BTN_PREV'|translate}}
                           </button>
                           <button mat-button mat-raised-button [disabled]="contacts.length == 0" (click)="nextStep()" type="button">
                              {{'BTN_NEXT'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">chevron_left</mat-icon>
                           </button>
                        </div>
                     </div>
                  </form>
               </mat-tab>
               <mat-tab label="{{'FORM_6'|translate}}" [disabled]="!form5.valid">
                  <div fxLayout="row" fxLayoutAlign="start">
                     <button mat-button mat-raised-button type="submit" (click)="onSubmit()">
                        {{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon>
                     </button>
                  </div>
                  <!-- <form [formGroup]="form6" (ngSubmit)="onSubmit()">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        </div>
                     </div>
                  </form> -->
               </mat-tab>
            </mat-tab-group>
         </div>
      </mat-card>
   </div>
</mat-card-content>
