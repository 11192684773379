import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import { Branch } from "../branch.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { BranchService } from "app/service/bank/branch.service";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditBranchComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  sps: Branch;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private branchService: BranchService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("TITLE_BANK");
    this.isLoading = true;
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.branchService.getSingleBranch(this._id).subscribe(
        (resData) => {
          this.sps = resData["bank"];
          if (userLevel != 1) {
            this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
              this.toastr.warning(text);
            });
            this.router.navigate(["/bank/view/" + this.sps.bank._id]);
          }
        },
        (err) => {
          this.isFound = false;
          let errmsg: string;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            errmsg = text;
          });
          this.toastr.warning(errmsg);
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
      if (this.isFound) {
        this.form = this.fb.group({
          branchCode: [
            this.sps.branchCode,
            Validators.compose([
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(3),
            ]),
          ],
          branchName: [
            this.sps.branchName,
            Validators.compose([
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(36),
            ]),
          ],
          branchAddress: [
            this.sps.branchAddress,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(64),
            ]),
          ],
          branchPostalCode: [
            this.sps.branchPostalCode,
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(7),
            ]),
          ],
          branchTelephone: [
            this.sps.branchTelephone,
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(10),
            ]),
          ],
          branchFax: [
            this.sps.branchFax,
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(10),
            ]),
          ],
        });
      }
    }, 2000);
  }

  onSubmit() {
    this.sps.branchCode = this.form.value.branchCode;
    this.sps.branchName = this.form.value.branchName;
    this.sps.branchAddress = this.form.value.branchAddress;
    this.sps.branchPostalCode = this.form.value.branchPostalCode;
    this.sps.branchTelephone = this.form.value.branchTelephone;
    this.sps.branchFax = this.form.value.branchFax;
    this.branchService.editSingleBranch(this.sps, this._id).subscribe(
      (res) => {
        this.translate.get("BRANCH_EDIT_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/bank/view/" + this.sps.bank._id]);
      },
      (errorMessage) => {
        this.translate.get("BRANCH_EDIT_SUCCESS").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/bank/view/" + this.sps.bank._id]);
  }
}
