export class Client {
  constructor(
    public fullName: string,
    public cid: string,
    public address: string,
    public telephone: string,
    public fax: string,
    public email: string,
    public cellphone: string,
    public bankInfo?: {
      bank: {
        _id: string,
        bankCode?: string,
        bankName?: string
      },
      branch: {
        _id: string,
        branchCode?: string,
        branchName?: string
      },
      accountNumber: string
    },
    public contacts?: [
      {
        _id: string;
        fullName?: string;
        telephone?: string;
        email?: string;
        job?: string;
        status?: string;
      }
    ],
    public projects?: [
      {
        _id: string;
        title?: string;
        address?: string;
        status?: string;
      }
    ],
    public creator?: string,
    public _id?: string,
    public status?: string
  ) {}
}
