<mat-card-content>
  <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
  <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard" *ngIf="!isLoading">
    <mat-card fxFlex="100%">
      <div class="gene-card-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column">
            <h4>{{'CONTACT_TITLE_EDIT'|translate}}</h4>
          </div>
          <span fxFlex></span>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="gene-card-content">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'FULLNAME_TITLE'|translate}}" formControlName="fullName"  minlength="5" maxlength="64">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['fullName'].hasError('required') && form.controls['fullName'].touched">
                  {{'FULLNAME_REQUIRE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['fullName'].hasError('minlength') && form.controls['fullName'].touched">
                  {{'FULLNAME_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['fullName'].hasError('maxlength') && form.controls['fullName'].touched">
                  {{'FULLNAME_MAX'|translate}}
               </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-bar>
               <mat-select placeholder="{{'JOB_ROLE_TITLE'|translate}}" formControlName="level" *ngIf="!isLoading">
                  <mat-select-filter
                     placeholder="{{'TITLE_FILTER'|translate}}"
                     [displayMember]="'viewValue'"
                     [array]="levels"
                     (filteredReturn)="filteredList5 = $event"
                     *ngIf="!isLoading"
                  ></mat-select-filter>
                  <mat-option *ngFor="let item of filteredList5" [value]="item">
                     {{ item.viewValue }}
                  </mat-option>
               </mat-select>
               <small class="gene-block warn-text" *ngIf="form.controls['level'].hasError('level') && form.controls['level'].touched">
                  {{'JOB_ROLE_CHOOSE_REQIURE'|translate}}
               </small>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'TITLE_TELEPHONE'|translate}}" formControlName="telephone" minlength="9" maxlength="10">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['telephone'].hasError('required') && form.controls['telephone'].touched">
               {{'TITLE_TELEPHONE_REQIURE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['telephone'].hasError('minlength') && form.controls['telephone'].touched">
               {{'TITLE_TELEPHONE_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['telephone'].hasError('maxlength') && form.controls['telephone'].touched">
               {{'TITLE_TELEPHONE_MAX'|translate}}
               </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'TITLE_TELEPHONE_SECONDERY'|translate}}" formControlName="cellphone" minlength="9" maxlength="10">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['cellphone'].hasError('minlength') && form.controls['cellphone'].touched">
               {{'TITLE_TELEPHONE_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['cellphone'].hasError('maxlength') && form.controls['cellphone'].touched">
               {{'TITLE_TELEPHONE_MAX'|translate}}
               </small>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'TITLE_FAX'|translate}}" formControlName="fax" minlength="9" maxlength="10">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['fax'].hasError('required') && form.controls['fax'].touched">
                  {{'TITLE_FAX_REQIURE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['fax'].hasError('minlength') && form.controls['fax'].touched">
                  {{'TITLE_FAX_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['fax'].hasError('maxlength') && form.controls['fax'].touched">
                  {{'TITLE_FAX_MAX'|translate}}
               </small>
             </div>
             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
               <mat-form-field class="full-wid mrgn-b-md">
                  <input matInput placeholder="{{'TITLE_EMAIL'|translate}}" formControlName="email" minlength="10" maxlength="64" type="email">
               </mat-form-field>
               <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">
                  {{'TITLE_EMAIL_REQIURE'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched">
                  {{'TITLE_EMAIL_VALID'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('minlength') && form.controls['email'].touched">
                  {{'TITLE_EMAIL_MIN'|translate}}
               </small>
               <small class="gene-block warn-text" *ngIf="form.controls['email'].hasError('maxlength') && form.controls['email'].touched">
                  {{'TITLE_MAIL_MAX'|translate}}
               </small>
             </div>
          </div>
          <div class="pad-wrap">
            <mat-card-actions>
              <button mat-raised-button color="primary" type="submit">{{'BTN_UPDATE'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
              <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
            </mat-card-actions>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</mat-card-content>
