import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { UserService } from "app/user/user.service";

import { AuthService } from "app/service/auth-service/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Task } from "../task.model";
import { ProjectService } from "app/service/project/project.service";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./task-board-add.component.html",
  styleUrls: ["./task-board-add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddTaskComponent implements OnInit {
  public form: FormGroup;
  isLoading: Boolean;
  isFound: Boolean = true;
  errCode: string;
  temct: Array<any>;
  ctype: Array<any> = [];
  public filteredList4;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    this.fetch();
    this.pageTitleService.setTitle("USERS_MAIN_TITLE");
    this.form = this.fb.group({
      // level: [null, Validators.compose([Validators.required])],
      // fullName: [
      //   null,
      //   Validators.compose([
      //     Validators.required,
      //     Validators.minLength(7),
      //     Validators.maxLength(24),
      //   ]),
      // ],
      title: [
        null,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(24),
        ],
      ],
      project: [null, [Validators.required]],
      description: [
        null,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(24),
        ],
      ],
      lastDate: [null, [Validators.required]],
      urgencyLevel: [null, [Validators.required]],
      employee: [null, [Validators.required]],
      planningStage: [null],
      consultant: [null],
      subPlanningStage: [null],
      remark: [null],
      building: [null],
      floor: [null],
      document: [null],
    });
  }

  fetch() {
    this.isLoading = true;
    this.projectService.getActiveProjectList().subscribe(
      (resData) => {
        this.temct = [...resData["projectlist"]];
        this.temct.forEach((item) => {
          this.ctype.push({
            value: item._id,
            viewValue: item.title,
          });
        });
        this.filteredList4 = this.ctype.slice();
      },
      (err) => {
        this.errorFound(err);
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  changeValueConsultant(val) {

  }

  onSubmit() {
    // const newPS = new Task(
    //   "test",
    //   { _id: "sss", title: "tete" },
    //   { _id: "sss", title: "tete" },
    //   { _id: "sss", title: "tete" },
    //   { _id: "sss", fullName: "tete" },
    //   "tete",
    //   "12-12-2020",
    //   {
    //     _id: "5f97287368f5402e68ce7ad7",
    //     fullName: "אופק",
    //     feePerSquareMeter: 10,
    //   },
    //   2,
    //   "dd"
    // );
    // this.userService.createUser(newPS).subscribe(
    //   (res) => {
    //     this.translate.get("USERS_ADD_SUCCESS").subscribe((text: string) => {
    //       this.toastr.success(text);
    //     });
    //     this.router.navigate(["/user"]);
    //   },
    //   (errorMessage) => {
    //     this.translate.get("USERS_ADD_WARNING").subscribe((text: string) => {
    //       this.toastr.warning(text);
    //     });
    //   }
    // );
  }

  onCancel() {
    this.router.navigate(["/user"]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
