import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import { Bank } from "../bank.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { BankService } from "app/service/bank/bank.service";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditBankComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  bank: Bank;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private bankService: BankService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/bank"]);
    }
    this.pageTitleService.setTitle("TITLE_BANK");
    this.isLoading = true;
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.bankService.getSingleBank(this._id).subscribe(
        (resData) => {
          this.bank = resData["bank"];
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
      if (this.isFound) {
        this.form = this.fb.group({
          bankCode: [
            this.bank.bankCode,
            Validators.compose([
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(2),
            ]),
          ],
          bankName: [
            this.bank.bankName,
            Validators.compose([
              Validators.required,
              Validators.minLength(7),
              Validators.maxLength(36),
            ]),
          ],
          bankAddress: [
            this.bank.bankAddress,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(64),
            ]),
          ],
          bankPostalCode: [
            this.bank.bankPostalCode,
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(7),
            ]),
          ],
          bankTelephone: [
            this.bank.bankTelephone,
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(10),
            ]),
          ],
          bankFax: [
            this.bank.bankFax,
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(10),
            ]),
          ],
        });
      }
    }, 2000);
  }

  onSubmit() {
    const newPS = new Bank(
      this.form.value.bankCode,
      this.form.value.bankName,
      this.form.value.bankAddress,
      this.form.value.bankPostalCode,
      this.form.value.bankTelephone,
      this.form.value.bankFax
    );
    this.bankService.editSingleBank(newPS, this._id).subscribe(
      (res) => {
        this.translate.get("BANK_EDIT_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/bank"]);
      },
      (errorMessage) => {
        this.translate.get("BANK_EDIT_SUCCESS").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/bank"]);
  }
}
