import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatInputModule,
			MatFormFieldModule,
			MatCardModule,
			MatButtonModule,
			MatIconModule,
			MatDividerModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ReportingRoutes } from './reporting.routing';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { ReportingComponent}  from './reporting/reporting.component';


@NgModule({
	declarations: [
		ReportingComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(ReportingRoutes),
		NgxDatatableModule,
		MatInputModule,
		MatFormFieldModule,
		MatCardModule,
		MatIconModule,
		MatButtonModule,
		MatDividerModule,
		FlexLayoutModule,
		TranslateModule
	]
})
export class ReportingModule { }
