import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class BankService {
  // domain = "https://apidemotrace.herokuapp.com/bank/";
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"bank/";

  getBanksList() {
    console.log(this.domain)
    return this.httpService.get(this.domain + "list");
  }

  getSingleBank(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createBank(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleBank(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteBank(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pauseBank(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeBank(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
