import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule, MatProgressBarModule
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { PlanningstageRoutes } from "./planningstage.routing";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";

import { PlanningstageComponent } from "./list-planningstage/planningstage.component";
import { SinglePlanningStageComponent } from "./single-planningstage/singleplanningstage.component";
import { EditPlanningStageComponent } from "./edit-planningstage/editplanningstage.component";
import { AddPlanningStageComponent } from "./add-planningstage/addplanningstage.component";
import { AddSubPlanningStageComponent } from './add-subplanningstage/addsubplanningstage.component';
import { EditSubPlanningStageComponent } from './edit-subplanningstage/editsubplanningstage.component';
import { SingleSubPlanningStageComponent } from './single-subplanningstage/singlesubplanningstage.component';

@NgModule({
  declarations: [
    PlanningstageComponent,
    SinglePlanningStageComponent,
    EditPlanningStageComponent,
    AddPlanningStageComponent,
    SingleSubPlanningStageComponent,
    AddSubPlanningStageComponent,
    EditSubPlanningStageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(PlanningstageRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
  ],
})
export class PlanningstageModule {}
