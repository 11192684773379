<mat-card class="mat-pad-none gene-calender">
   <ng-template ngbModalContainer></ng-template>
   <ng-template #modalContent let-close="close">
      <div class="modal-header">
         <h5 class="modal-title">Event action occurred</h5>
         <button color="primary" class="close" (click)="close()">
            <mat-icon>close</mat-icon>
         </button>
      </div>
      <div class="modal-body">
         <div>
            Action: 
            <pre>{{ modalData?.action }}</pre>
         </div>
         <div>
            Event: 
            <pre>{{ modalData?.event | json }}</pre>
         </div>
      </div>
      <mat-icon (click)="close()" class="modal-confirm-btn">done</mat-icon>
   </ng-template>
   <div class="text-center primary-bg overflow-hidden">
      <div class="cal-col">
         <div class="gene-btn-group">
            <mat-icon class="" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"> chevron_left
            </mat-icon>
            <mat-icon class="" mwlCalendarToday [(viewDate)]="viewDate"> today </mat-icon>
            <mat-icon class="" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"> chevron_right
            </mat-icon>
         </div>
      </div>
      <div class="cal-col">
         <h3 class="gene-cal-head">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <div class="right-tab cal-col">
         <div class="gene-btn-group">
            <mat-icon class="" (click)="view = 'month'" [class.active]="view === 'month'"> view_comfy </mat-icon>
            <!-- <mat-icon class="" (click)="view = 'week'" [class.active]="view === 'week'"> view_week </mat-icon> -->
            <mat-icon class="" (click)="view = 'day'" [class.active]="view === 'day'"> view_day </mat-icon>
            <mat-icon class="" (click)="addNewReportDialog()"> add </mat-icon>
         </div>
      </div>
   </div>
   <br>
   <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"> </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"> </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"> </mwl-calendar-day-view>
   </div>
   <button class="btn btn-primary float-right" (click)="addNewReportDialog()">
     Add Event
   </button>
</mat-card>
