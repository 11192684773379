export class Branch {
    constructor(
      public branchCode: string,
      public branchName: string,
      public branchAddress: string,
      public branchPostalCode: number,
      public branchTelephone: number,
      public branchFax: string,
      public bank?: {
        _id: string,
        bankName?: string
      },
      public creator?: string,
      public _id?: string,
      public status?: number
    ) {}
  
  }
  