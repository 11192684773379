import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "../../service/core/core.service";
import { ProjectService } from "app/service/project/project.service";
import { UserService } from "app/user/user.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "app/service/auth-service/auth.service";

@Component({
  selector: "ms-task-board",
  templateUrl: "./task-board.component.html",
  styleUrls: ["./task-board.component.scss"],
})
export class TaskBoardComponent implements OnInit {
  title: string;
  message: string;
  taskboardContent: any;
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;
  isLoading: Boolean;
  temct: Array<any>;
  ctype: Array<any> = [];
  public filteredList4;
  popUpNewReportResponse: any;
  userLevel: number;

  constructor(
    private pageTitle: PageTitleService,
    private translate: TranslateService,
    public coreService: CoreService,
    private projectService: ProjectService,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.pageTitle.setTitle("Task Board");
    this.userLevel = this.authService.getUserLevel();
    this.onReload();
    // this.coreService.getTaskboardContent().
    //     subscribe( res => { this.taskboardContent = res },
    //                err => console.log(err),
    //                ()  => this.taskboardContent
    //             );
  }

  addTask() {
    this.coreService.addNewTaskDailog().subscribe(
      (res) => {
        this.popUpNewReportResponse = res;
      },
      (err) => console.log(err),
      () => this.getAddReportPopupResponse(this.popUpNewReportResponse)
    );
  }

  /**
   *getAddUserPopupResponse method is used to get a new user dialog response.
   *if response will be get then add new user into user list.
   */
  getAddReportPopupResponse(response: any) {
    if (response) {
      // let addUser = {
      //    img : "assets/img/user-4.jpg",
      //    firstName : response.firstName,
      //    lastName : response.lastName,
      //    emailAddress : response.emailAddress,
      //    accountType : response.accountType,
      //    status : "Active",
      //    statusTime:"Since 1 hour",
      //    dateCreated : new Date(),
      //    accountTypeColor : this.color[response.accountType]
      // }
      // this.usermanagelist.push(addUser);
      // this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
      // this.dataSource.paginator = this.paginator;
      console.log(response);
    }
  }

  /**
   * onCreate Method is used to create new Task Board.
   */
  onCreate() {
    if (this.message && this.title != "") {
      let create = {
        image: "assets/img/user-4.jpg",
        date: "8 May",
        title: this.title,
        message: this.message,
      };
      this.taskboardContent.allTaskBoard.push(create);
      this.title = "";
      this.message = "";
    }
  }

  fetchProjectsList() {
    this.isLoading = true;
    this.projectService.getActiveProjectList().subscribe(
      (resData) => {
        console.log(resData);
        this.temct = [...resData["projectlist"]];
        this.temct.forEach((item) => {
          this.ctype.push({
            value: item._id,
            viewValue: item.title,
          });
        });
        this.filteredList4 = this.ctype.slice();
      },
      (err) => {
        this.errorFound(err);
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  /**
   *reload data.
   */
  onReload() {
    this.fetchProjectsList();
  }

  errorFound(err) {
    this.isFound = false;
    this.loadErrorToastr("ERROR_MSG");
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
  }

  /**
   * Load Error Message
   */
  private loadErrorToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }

  /**
   * Load Success Message
   */
  private loadSuccessToastr(msg) {
    this.translate.get(msg).subscribe((text: string) => {
      this.toastr.success(text);
    });
  }
}
