import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from "app/Shared/component/url";

@Injectable({ providedIn: "root" })
export class PlanningStageService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url + "planningstage/";

  getPlanningStages() {
    return this.httpService.get(this.domain + "list");
  }

  getActivePlanningStages() {
    return this.httpService.get(this.domain + "activelist");
  }

  getSubPlanningStage(id) {
    const _id = id;
    return this.httpService.get(
      this.mainUrl.url + "subplanningstage/subs/" + _id
    );
  }

  getSinglePlanningStage(id) {
    const _id = id;
    return this.httpService.get(this.domain + "view/" + _id);
  }

  createSinglePlanningStage(data) {
    return this.httpService.post(this.domain + "create", JSON.stringify(data));
  }

  editSinglePlanningStage(data, id) {
    const _id = id;
    console.log(data);
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deletePlanningStage(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pausePlanningStage(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activePlanningStage(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
