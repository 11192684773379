import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class ClientService {
  // domain = "https://apidemotrace.herokuapp.com/client/";
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"client/";
  
  getActiveClientList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getClientList() {
    return this.httpService.get(this.domain + "list");
  }

  getSingleClient(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createClient(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleClient(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteClient(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pauseClient(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeClient(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
