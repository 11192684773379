import { IConsultant } from 'app/interfaces/consultant';

export class ConsultantType {
    constructor(
      public title: string,
      public description: string,
      public consultants?: Array<IConsultant>[],
      public status?: number,
      public creator?: string,
      public upDatedBy?: string,
      public _id?: string
    ) {}
  
  }