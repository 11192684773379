import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatChipsModule,
  MatProgressBarModule,
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";
import { MatSelectFilterModule } from 'mat-select-filter';

import { ConsultantTypeRoutes } from "./consultanttype.routing";

import { ListConsultantTypeComponent } from "./list/list.component";
import { AddConsultantTypeComponent } from "./add/add.component";
import { EditConsultantTypeComponent } from "./edit/edit.component";
import { ViewConsultantTypeComponent } from "./view/view.component";
import { AddConsultantComponent } from './consultant/add.component';

@NgModule({
  declarations: [
    AddConsultantComponent,
    ListConsultantTypeComponent,
    AddConsultantTypeComponent,
    EditConsultantTypeComponent,
    ViewConsultantTypeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ConsultantTypeRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectFilterModule 
  ],
})
export class ConsultantTypeModule {}
