import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class SubPlanningStageService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"subplanningstage/";

  getSubPlanningStages() {
    return this.httpService.get(this.domain + "list");
  }

  getSubSinglePlanningStage(id) {
    const _id = id;
    return this.httpService.get(this.domain + "view/" + _id);
  }

  createSubSinglePlanningStage(data) {
    return this.httpService.post(this.domain + "create", JSON.stringify(data));
  }

  editSubSinglePlanningStage(data, id) {
    const _id = id;
    console.log(data);
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteSubPlanningStage(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pauseSubPlanningStage(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeSubPlanningStage(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
