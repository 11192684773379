import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent }   from './main/main.component';
import { AuthGuard } from './core/guards/auth.guard';

const appRoutes: Routes = [
   {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
   },
   {	
      path: 'session',
      loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
   },
   {
      path: '',
      component: MainComponent,
      canActivate: [AuthGuard],
      runGuardsAndResolvers: 'always',
      children: [
         {  path: 'calendar', loadChildren: () => import('./calendar/calendar.module').then(m => m.Calendar_Module)},
         {  path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
         {  path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule)},
         {  path: 'planningstage', loadChildren: () => import('./planningstage/planningstage.module').then(m => m.PlanningstageModule)},
         {  path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule)},
         {  path: 'bank', loadChildren: () => import('./bank/bank.module').then(m => m.BankModule)},
         {  path: 'documents', loadChildren: () => import('./document/document.module').then(m => m.DocumentsModule)},
         {  path: 'floor', loadChildren: () => import('./floor/floor.module').then(m => m.FloorModule)},
         {  path: 'projecttype', loadChildren: () => import('./projecttype/projecttype.module').then(m => m.ProjectTypeModule)},
         {  path: 'clients', loadChildren: () => import('./client/client.module').then(m => m.ClientModule)},
         {  path: 'jobrole', loadChildren: () => import('./jobrole/jobrole.module').then(m => m.JobRoleModule)},
         {  path: 'contacts', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
         {  path: 'projects', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule)},
         {  path: 'consultanttype', loadChildren: () => import('./consultanttype/consultanttype.module').then(m => m.ConsultantTypeModule)},
         {  path: 'consultant', loadChildren: () => import('./consultant/consultant.module').then(m => m.ConsultantModule)},
         {  path: 'taskboard', loadChildren: () => import('./taskboard/task-board.module').then(m => m.TaskBoardModule)},
      ]
   },
   {
      path: '**',
      redirectTo: 'session/loginV2'
   }
]

@NgModule({
  	imports: [RouterModule.forRoot(appRoutes)],
 	exports: [RouterModule],
  	providers: []
})
export class RoutingModule { }
