import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit ,ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { fadeInAnimation} from "../../core/route-animation/route.animation";
import { startOfDay,
         endOfDay,
         subDays,
         addDays,
         endOfMonth,
         isSameDay,
         isSameMonth,
         addHours
      } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent,
         CalendarEventAction,
         CalendarEventTimesChangedEvent
      } from 'angular-calendar';
import { CoreService } from '../../service/core/core.service';

const colors: any = {
   red: {
      primary: '#ad2121',
      secondary: '#FAE3E3'
   },
   blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
   },
   yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA'
   }
};

@Component({
   selector: 'ms-full-calendar',
   changeDetection: ChangeDetectionStrategy.OnPush,
   templateUrl:'./calendar-material.html',
   styleUrls: ['./calendar-material.scss'],
   encapsulation: ViewEncapsulation.None,
   host: {
      "[@fadeInAnimation]": 'true'
   },
   animations: [ fadeInAnimation ]
})

export class CalendarComponent implements OnInit {

   activeDayIsOpen: boolean = true;
   @ViewChild('modalContent',{static : false}) modalContent: TemplateRef<any>;
   popUpNewReportResponse: any;

   newEvent: CalendarEvent;
   view: string = 'day';

   viewDate: Date = new Date();

   modalData: {
      action: string,
      event: CalendarEvent
   };

   refresh: Subject<any> = new Subject();

   /**
     * actions is used to delete the event.
     */
   actions: CalendarEventAction[] = [ {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({event}: {event: CalendarEvent}): void => {
         this.handleEvent('Edited', event);
      }
   }, {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({event}: {event: CalendarEvent}): void => {
         this.events = this.events.filter(iEvent => iEvent !== event);
         this.handleEvent('Deleted', event);
      }
   }];

  
   /**
     *events is used to view the events
     */
   events: CalendarEvent[] = [
      {
         start: new Date('2020-12-30 6:50'),
         end: new Date('2020-12-30 14:50'),
         title: 'test',
         color: colors.blue,
         actions: this.actions,
         resizable: {
         beforeStart: true,
         afterEnd: true
         },
         draggable: true
      }
   ];

   constructor( private modal: NgbModal, 
                private pageTitleService: PageTitleService,
                private coreService : CoreService) {}

   ngOnInit() {
      this.pageTitleService.setTitle("Calendar");

      // console.log(moment().startOf('week').toISOString());
      console.log(moment().startOf('week').toArray());
      console.log(moment().calendar());
      console.log(new Date('2020-12-27 19:50'));
   }

   /** 
     * addNewReportDialog method is used to open a add new report dialog.
     */   
    addNewReportDialog() {
      this.coreService.addNewReportDailog().
         subscribe( res => {this.popUpNewReportResponse = res},
                    err => console.log(err),
                    ()  => this.getAddReportPopupResponse(this.popUpNewReportResponse))
   }

   /**
     *getAddUserPopupResponse method is used to get a new user dialog response.
     *if response will be get then add new user into user list.
     */
    getAddReportPopupResponse(response: any){
      if(response){
         // let addUser = {
         //    img : "assets/img/user-4.jpg",
         //    firstName : response.firstName,
         //    lastName : response.lastName,
         //    emailAddress : response.emailAddress,
         //    accountType : response.accountType,
         //    status : "Active",
         //    statusTime:"Since 1 hour",
         //    dateCreated : new Date(),
         //    accountTypeColor : this.color[response.accountType]
         // }
         // this.usermanagelist.push(addUser);
         // this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
         // this.dataSource.paginator = this.paginator; 
         console.log(response);
      }
   }

   /**
     * dayClicked method is used to open the active day.
     */
   dayClicked({date, events}: {date: Date, events: CalendarEvent[]}): void {
      if (isSameMonth(date, this.viewDate)) {
         if (
            (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
            events.length === 0
         ) {
            this.activeDayIsOpen = false;
         } else {
            this.activeDayIsOpen = true;
            this.viewDate = date;
         }
      }
   }

   /**
     * eventTimesChanged method is used to change the calendar Event time.
     */   
   eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
      event.start = newStart;
      event.end = newEnd;
      this.handleEvent('Dropped or resized', event);
      this.refresh.next();
   }

   /**
     * handleEvent method is used to handle the event and action.
     */
   handleEvent(action: string, event: CalendarEvent): void {
      this.modalData = {event, action};
      this.modal.open(this.modalContent, {size: 'lg'});
   }

   /**
     * addEvent method is used to add a new event into calendar.
     */
   addEvent(): void {
      this.modal.open(this.modalContent, {size: 'lg'});
      this.newEvent = {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        actions: this.actions,
      }
      this.events.push(this.newEvent);

      this.refresh.next();
      this.handleEvent('Add new event', this.newEvent);
   } 
}