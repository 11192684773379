<mat-card-content>
   <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
   <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard" *ngIf="!isLoading">
      <mat-card fxFlex="100%">
         <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
               <div fxLayout="column">
                  <h4>{{'USERS_ADD_NEW'|translate}}</h4>
               </div>
               <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
         </div>
         <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
         <div class="gene-card-content" *ngIf="isFound">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
               <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                     <mat-select placeholder="{{'FULLNAME_CHOOSE_TITLE'|translate}}" formControlName="project">
                        <mat-select-filter placeholder="{{'TITLE_FILTER'|translate}}" [displayMember]="'project'" [array]="ctype" (filteredReturn)="filteredListConsultants = $event"></mat-select-filter>
                           <mat-option *ngFor="let item of filteredList4" [value]="item" (onSelectionChange)="changeValueConsultant($event)">{{ item.viewValue }}</mat-option>
                        </mat-select>
                  </div>
                  <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                     <mat-form-field class="full-wid mrgn-b-md">
                        <input matInput placeholder="{{'TASK_TITLE'|translate}}" formControlName="title"  minlength="5" maxlength="64">
                     </mat-form-field>
                     <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched">
                        {{'TASK_TITLE'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('minlength') && form.controls['title'].touched">
                        {{'TASK_TITLE'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['title'].hasError('maxlength') && form.controls['title'].touched">
                        {{'TASK_TITLE'|translate}}
                     </small>
                  </div>
               </div>
               <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                     <mat-form-field class="full-wid mrgn-b-md">
                        <input matInput placeholder="{{'TASK_DESCRIPTION'|translate}}" formControlName="description"  minlength="8" maxlength="8">
                     </mat-form-field>
                     <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched">
                        {{'TASK_DESCRIPTION_REQUIRE'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('minlength') && form.controls['description'].touched">
                        {{'TASK_DESCRIPTION_LENGTH'|translate}}
                     </small>
                     <small class="gene-block warn-text" *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched">
                        {{'TASK_DESCRIPTION_LENGTH'|translate}}
                     </small>
                  </div>
               </div>
               <div class="pad-wrap">
                  <mat-card-actions>
                     <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">{{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
                     <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
                  </mat-card-actions>
               </div>
            </form>
         </div>
      </mat-card>
   </div>
</mat-card-content>