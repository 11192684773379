<mat-card-content>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <div class="user-content">
        <mat-card>
          <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <div class="pad-y-sm" *ngIf="!isLoading">
            <div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile">
              <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
              <div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)" fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100" class="user-contact-info" *ngIf="isFound">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>{{'DOCUMENTS_TITLE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4 *ngIf="userLevel == 1" class="activeIcon">
                      <a class="gene-block"href="/documents/editSub/{{ sps._id }}">{{ sps.title }}</a>
                    </h4>
                    <h4 *ngIf="userLevel != 1" class="activeIcon">
                      {{ sps.title }}
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="100">
                    <h4>{{'DOCUMENTS_DESCRIPTION'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="100">
                    <h4>{{ sps.description }}</h4>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="pad-wrap">
                <mat-card-actions>
                  <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'DOCUMENTS_BACK_TO_MAIN'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon></button>
                </mat-card-actions>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card-content>
