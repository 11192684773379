import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatChipsModule,
  MatProgressBarModule,
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";

import { ProjectTypeRoutes } from "./projecttype.routing";

import { ListProjectTypeComponent } from "./list/list.component";
import { AddProjectTypeComponent } from "./add/add.component";
import { EditProjectTypeComponent } from "./edit/edit.component";
import { ViewProjectTypeComponent } from "./view/view.component";

@NgModule({
  declarations: [
    ListProjectTypeComponent,
    AddProjectTypeComponent,
    EditProjectTypeComponent,
    ViewProjectTypeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ProjectTypeRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatChipsModule,
  ],
})
export class ProjectTypeModule {}
