<mat-card-content>
  <div class="user-manage-list">
    <mat-card>
      <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="mrgn-r-sm">
            <h3>{{'PROJECT_TYPE_LIST'|translate}}</h3>
          </div>
        </div>
        <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
          <mat-card-actions class="mat-pad-none margin-none">
            <a href="/projecttype/add" mat-raised-button mat-button-sm color="primary" *ngIf="userLevel == 1">
              {{'PROJECT_TYPE_ADD_NEW'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
            </a>&nbsp;
            <button mat-raised-button mat-button-sm color="primary" (click)="onReload()">
              {{'BTN_RELOAD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">refresh</mat-icon>
            </button>
          </mat-card-actions>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="pad-all-md mat-white box-inset mrgn-all-md" *ngIf="this.totalItems > 0">
        <form class="example-form" fxFlex="220px">
          <mat-form-field class="full-wid">
            <input matInput type="text" placeholder="{{'TITLE_FILTER'|translate}}" (keyup)="updateFilter($event)" />
          </mat-form-field>
        </form>
      </div>
      <mat-divider></mat-divider>
      <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto" ></mat-progress-spinner>
      <div class="table-responsive" *ngIf="!isLoading">
        <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="this.totalItems == 0">{{errCode|translate}}</b>
        <table class="full-wid" mat-table [dataSource]="dataSource" *ngIf="this.totalItems > 0">  
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_SERIAL_ID'|translate}}</th>
            <td mat-cell *matCellDef="let element; index as i">
              {{ i+1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TYPE'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <a href="/projecttype/view/{{ element._id }}">{{ element.title }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TYPE_DESCRIPTION'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_UPDATED_AT'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <a href="/projecttype/view/{{ element._id }}" mat-icon-button>
                <mat-icon class="mat-icon-grey gene-text-lg">remove_red_eye</mat-icon>
              </a>
              <a *ngIf="userLevel == 1" href="/projecttype/edit/{{ element._id }}" mat-icon-button>
                <mat-icon class="mat-icon-grey gene-text-lg editIcon">edit</mat-icon>
              </a>
              <button mat-icon-button (click)="onReactive(element._id, i)" *ngIf="element.status != 1 && userLevel == 1">
                <mat-icon class="mat-icon-grey gene-text-lg activeIcon">play_arrow</mat-icon>
              </button>
              <button mat-icon-button (click)="onPause(element._id, i)" *ngIf="userLevel == 1">
                <mat-icon class="mat-icon-grey gene-text-lg pauseIcon">pause</mat-icon>
              </button>
              <button mat-icon-button (click)="onDelete(element._id, i)" *ngIf="userLevel == 1">
                <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
      </div>
      <div class="pagination" *ngIf="this.totalItems > 0">
        <mat-paginator [length]="usermanagelist.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-card>
  </div>
</mat-card-content>