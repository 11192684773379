import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";
import * as firebase from "firebase/app";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { User } from "./user.model";
import { MainUrl } from "app/Shared/component/url";

export interface AuthResponseData {
  email: string;
  level: string;
  fullName?: string;
  userId?: string;
  token?: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;
  userData: any;
  isLoggedIn = false;
  private mainUrl: MainUrl = new MainUrl();
  constructor(
    private httpClientService: HttpClient,
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private toastr: ToastrService
  ) {}

  /*
   *  getLocalStorageUser function is used to get local user profile data.
   */
  getLocalStorageUser() {
    this.userData = JSON.parse(localStorage.getItem("userProfile"));
    if (this.userData) {
      this.isLoggedIn = true;
      return true;
    } else {
      this.isLoggedIn = false;
      return false;
    }
  }

  getUserClient() {
    let ud: any;
    ud = JSON.parse(localStorage.getItem("userProfile"));
    if (ud) {
      return ud.clientId;
    } else {
      return null;
    }
  }

  getUserInfo() {
    let ud: any;
    ud = JSON.parse(localStorage.getItem("userProfile"));
    if (ud) {
      return ud._token;
    } else {
      return null;
    }
  }

  getUserID() {
    let ud: any;
    ud = JSON.parse(localStorage.getItem("userProfile"));
    if (ud) {
      return ud.userId;
    } else {
      return null;
    }
  }

  getUserLevel() {
    let ud: any;
    ud = JSON.parse(localStorage.getItem("userProfile"));
    if (ud) {
      return ud.level;
    } else {
      return null;
    }
  }

  getUserFullName() {
    let ud: any;
    ud = JSON.parse(localStorage.getItem("userProfile"));
    if (ud) {
      return ud.fullName;
    } else {
      return null;
    }
  }
  /*
   * signupUserProfile method save email and password into firabse &
   * signupUserProfile method save the user sign in data into local storage.
   */
  signupUserProfile(value) {
    this.firebaseAuth.auth
      .createUserWithEmailAndPassword(value.email, value.password)
      .then((value) => {
        this.toastr.success("נרשמת בהצלחה!");
        this.setLocalUserProfile(value);
        this.router.navigate(["/"]);
      })
      .catch((err) => {
        this.toastr.error(err.message);
      });
  }

  /*
   * loginUser fuction used to login
   */
  loginUser(value) {
    const email = value.email;
    const password = value.password;
    const curUrl = this.mainUrl.url + "auth/login";
    return this.httpClientService
      .post<AuthResponseData>(curUrl, {
        // .post<AuthResponseData>("http://localhost:8080/auth/login", {
        email: email,
        password: password,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthintication(
            resData.email,
            resData.userId,
            resData.fullName,
            resData.token,
            resData.level,
            //   +resData.expiresIn
            1200000
          );
          this.toastr.success("נרשמת בהצלחה!");
          this.router.navigate(["/"]);
        })
      );
  }

  /*
   * resetPasswordV2 is used to reset your password
   */
  resetPasswordV2(value) {
    this.firebaseAuth.auth
      .sendPasswordResetEmail(value.email)
      .then((value) => {
        this.toastr.success("סיסמה חדשה נשלחת לכתובת הדואר האלקטרוני שלך.");
        this.router.navigate(["/session/loginV2"]);
      })
      .catch((err) => {
        this.toastr.error(err.message);
      });
  }

  /*
   * logOut function is used to sign out
   */
  logOut() {
    this.user.next(null);
    this.isLoggedIn = false;
    this.toastr.success("התנתקתם בהצלחה!");
    this.router.navigate(["/session/loginV2"]);
    localStorage.removeItem("userProfile");
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.user.next(null);
      this.isLoggedIn = false;
      this.toastr.success("התנתקתם עקב אי פעילות!");
      this.router.navigate(["/session/lockscreenV2"]);
      localStorage.removeItem("userProfile");
      if (this.tokenExpirationTimer) {
        clearTimeout(this.tokenExpirationTimer);
      }
      this.tokenExpirationTimer = null;
    }, expirationDuration);
  }
  /*
   * setLocalUserProfile function is used to set local user profile data.
   */
  setLocalUserProfile(value) {
    localStorage.setItem("userProfile", JSON.stringify(value));
    this.getLocalStorageUser();
    this.isLoggedIn = true;
  }

  private handleAuthintication(
    email: string,
    userId: string,
    fullName: string,
    token: string,
    level: string,
    expiresIn: number
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(
      email,
      userId,
      fullName,
      token,
      level,
      expirationDate
    );
    this.user.next(user);
    localStorage.setItem("userProfile", JSON.stringify(user));
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = "An unknown error occurred!";
    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorMessage);
    }
    switch (errorRes.error.error.message) {
      case "EMAIL_EXISTS":
        errorMessage = "This Email Exists Already";
        break;
      case "EMAIL_NOT_FOUND":
        errorMessage = "This email does not exist.";
        break;
      case "INVALID_PASSWORD":
        errorMessage = "Invalid password";
        break;
    }
    return throwError(errorMessage);
  }
}
