import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { AuthService } from "app/service/auth-service/auth.service";
import { DocumentService } from "app/service/documents/document.service";
import { ToastrService } from "ngx-toastr";

import { Document } from "../document.model";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddDocuemntComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private documentService: DocumentService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/documents"]);
    }
    this.pageTitleService.setTitle("DOCUMENTS_MAIN_TITLE");

    this.form = this.fb.group({
      title: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(24),
        ]),
      ],
      description: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(64),
        ]),
      ],
    });
  }

  onSubmit() {
    const newPS = new Document(
      this.form.value.title,
      this.form.value.description
    );
    this.documentService.createDocument(newPS).subscribe(
      (res) => {
        this.translate.get("DOCUMENTS_ADD_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.router.navigate(["/documents"]);
      },
      (errorMessage) => {
        this.translate.get("DOCUMENTS_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/documents"]);
  }
}
