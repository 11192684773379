import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatChipsModule,
  MatProgressBarModule,
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";

import { DocumentRoutes } from "./document.routing";
import { AddDocuemntComponent } from './document-add/add.component';
import { EditDocumentComponent } from './document-edit/edit.component';
import { ListDocumentComponent } from './document-list/list.component';
import { ViewDocumentComponent } from './document-view/view.component';
import { AddSubDocumentComponent } from './sub-document-add/add.component';
import { EditSubDocumentComponent } from './sub-document-edit/edit.component';
import { ViewSubDocumentComponent } from './sub-document-view/view.component';

@NgModule({
  declarations: [
    AddDocuemntComponent,
    EditDocumentComponent,
    ListDocumentComponent,
    ViewDocumentComponent,
    AddSubDocumentComponent,
    EditSubDocumentComponent,
    ViewSubDocumentComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(DocumentRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatChipsModule,
  ],
})
export class DocumentsModule {}
