import { Component, OnInit } from "@angular/core";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";

import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { AuthService } from 'app/service/auth-service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from "app/user/user.service";
import { User } from "app/user/user.model";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./task-board-view.component.html",
  styleUrls: ["./task-board-view.component.scss"],
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewSingleTaskComponent implements OnInit {
  sps: User;
  private _id;
  isLoading: Boolean;
  isEditing: Boolean = false;
  reports: Array<any> = [];
  temp: Array<any> = [];
  userLevel: number;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("USERS_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
        this.router.navigate(["/user"]);
      }
      this.userService.getSingleUser(this._id).subscribe((resData) => {
        this.sps = resData["user"];
        console.log(this.sps);
        this.sps.reports.forEach((i) => {
          this.reports.push(i);
          this.temp.push(i);
        });
      });
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onCancel() {
    this.router.navigate(["/user"]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
