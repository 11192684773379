import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { UserService } from "../user.service";
import { User } from "../user.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { BankService } from "app/service/bank/bank.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edituser.component.html",
  styleUrls: ["./edituser.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditUserComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  user: User;
  levelsRoot: Array<any> = [
    { i: 2, title: "מנהל" },
    { i: 3, title: "משתמש" },
  ];
  levelsAdmin: Array<any> = [{ i: 3, title: "משתמש" }];
  levels: Array<any>;
  banks: Array<any> = [];
  branches: Array<any> = [];
  tems: Array<any>;
  isLoadingBranches: Boolean;
  public filteredList5;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private bankService: BankService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.pageTitleService.setTitle("USERS_MAIN_TITLE");
    const userLevel = this.authService.getUserLevel();
    if (userLevel == 1) {
      this.levels = this.levelsRoot;
    } else if (userLevel == 2) {
      this.levels = this.levelsAdmin;
    } else {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/"]);
    }
    this.route.params.subscribe((param) => {
      this._id = param.id;
      this.userService.getSingleUser(this._id).subscribe(
        (resData) => {
          this.user = resData["user"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
      if (this.isFound) {
        this.fetch();
        this.form = this.fb.group({
          level: [this.user.level, Validators.compose([Validators.required])],
          fullName: [
            this.user.fullName,
            Validators.compose([
              Validators.required,
              Validators.minLength(7),
              Validators.maxLength(24),
            ]),
          ],
          cid: [
            this.user.cid,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(9),
            ]),
          ],
          telephone: [
            this.user.telephone,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
          password: [
            null,
            Validators.compose([
              Validators.minLength(8),
              Validators.maxLength(8),
            ]),
          ],
          email: [
            this.user.email,
            Validators.compose([
              Validators.required,
              Validators.minLength(11),
              Validators.maxLength(64),
            ]),
          ],
          address: [
            this.user.address,
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              Validators.maxLength(64),
            ]),
          ],
          bankID: [null, Validators.compose([Validators.required])],
          branchId: [null, Validators.compose([Validators.required])],
          accountNumber: [
            this.user.bankInfo.accountNumber,
            Validators.compose([
              Validators.required,
              Validators.minLength(4),
              Validators.maxLength(11),
            ]),
          ],
        });
      }
    }, 3000);
  }

  fetch() {
    this.bankService.getBanksList().subscribe((resData) => {
      this.tems = [...resData["banks"]];
      this.tems.forEach(
        (item) => {
          this.banks.push({
            value: item._id,
            viewValue: item.bankName,
          });
        },
        (err) => {
          this.errorFound(err);
        }
      );
      this.filteredList5 = this.banks.slice();
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  loadBranches(bankIndex) {
    this.isLoadingBranches = true;
    this.branches = [];
    const temps = this.tems.filter(function (d) {
      return d._id.indexOf(bankIndex.value) !== -1 || !bankIndex.value;
    });
    temps[0].bankBranches.forEach((item) => {
      this.branches.push({
        value: item._id,
        viewValue: item.branchName,
      });
    });
    setTimeout(() => {
      this.isLoadingBranches = false;
    }, 3000);
  }

  onSubmit() {
    const newPS = new User(
      this.form.value.fullName,
      this.form.value.email,
      this.form.value.telephone,
      this.form.value.cid,
      this.form.value.level,
      this.form.value.address,
      this.form.value.bankID,
      this.form.value.branchId,
      this.form.value.accountNumber
    );
    console.log(newPS);
    this.userService.editUser(newPS, this._id).subscribe(
      (res) => {
        this.translate.get("USERS_UPDATE_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/user"]);
      },
      (errorMessage) => {
        this.translate.get("USERS_UPDATE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/user"]);
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
