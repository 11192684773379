export class SubPlanningStage {
    constructor(
      public title: string,
      public description: string,
      public planningStage?: {
        _id: string,
        title?: string
      },
      public creator?: string,
      public _id?: string,
      public status?: string
    ) {}
  
  }
  