import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { Floor } from "../floor.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { FloorService } from "app/service/floor/floor.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewFloorComponent implements OnInit {
  sps: Floor;
  private _id;
  isLoading: Boolean;
  userLevel: number;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private floorService: FloorService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("FLOOR_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/floor"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.floorService.getSingleFloor(this._id).subscribe(
        (resData) => {
          this.sps = resData["floor"];
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onCancel() {
    this.router.navigate(["/floor"]);
  }
}
