import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class SubDocumentService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"subdocument/";

  getSubdocumentList() {
    return this.httpService.get(this.domain + "list/");
  }
  getSubdocumentsList(id) {
    const _id = id;
    return this.httpService.get(this.domain + "subs/" + _id);
  }

  getSingleSubdocument(id) {
    const _id = id;
    return this.httpService.get(this.domain + "view/" + _id);
  }

  createSubdocument(data) {
    return this.httpService.post(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleSubdocument(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteSubdocument(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "delete/" + _id
    );
  }

  pauseSubdocument(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeSubdocument(id) {
    const _id = id;
    return this.httpService.delete(
      this.domain + "active/" + _id
    );
  }
}
