import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { SubPlanningStage } from "../subplanningstage.model";
import { SubPlanningStageService } from 'app/service/planningstage/subplanningstage.service';
import { AuthService } from 'app/service/auth-service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { PlanningStageService } from 'app/service/planningstage/planningstage.service';

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./addsubplanningstage.component.html",
  styleUrls: ["./addsubplanningstage.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddSubPlanningStageComponent implements OnInit {
  public form: FormGroup;
  private _id;
  isLoading: Boolean;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private spsService: SubPlanningStageService,
    private psService: PlanningStageService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/planningstage"]);
    } 
    this.pageTitleService.setTitle("PLANNING_STAGE_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
        this.router.navigate(["/planningstage"]);
      }
      this.psService.getSinglePlanningStage(this._id).subscribe(
        (resData) => {
          this.form = this.fb.group({
            title: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(24),
              ]),
            ],
            description: [
              null,
              Validators.compose([
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(64),
              ]),
            ],
          });
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
  }

  onSubmit() {
    const newPS = new SubPlanningStage(
      this.form.value.title,
      this.form.value.description,
      {_id: this._id}
    );
    this.spsService.createSubSinglePlanningStage(newPS).subscribe(
      (res) => {
        this.translate.get("SUB_PLANNING_STAGE_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/planningstage/view/" + this._id]);
      },
      (errorMessage) => {
        this.translate.get("SUB_PLANNING_STAGE_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
        this.router.navigate(["/planningstage/view/" + this._id]);
  }
}
