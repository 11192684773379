import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";
import { ToastrService } from "ngx-toastr";

import { Bank } from "../bank.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { BankService } from "app/service/bank/bank.service";
import { BranchService } from "app/service/bank/branch.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewBankComponent implements OnInit {
  sps: Bank;
  private _id;
  isLoading: Boolean;
  isEditing: Boolean = false;
  reports: Array<any> = [];
  temp: Array<any> = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: any;
  usermanagelist: any;
  tempusermanagelist: Array<any>;
  bankId: string;
  isLoadingBank: Boolean;
  userLevel: number;
  errorMessage = "";
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  displayedColumns: string[] = [
    "branchCode",
    "branchName",
    "branchAddress",
    "branchPostalCode",
    "branchTelephone",
    "branchFax",
    "createdAt",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);
  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private bankService: BankService,
    private branchService: BranchService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("TITLE_BANK");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.bankService.getSingleBank(this._id).subscribe(
        (resData) => {
          this.sps = resData["bank"];
          this.bankId = this.sps._id;
          this.fetch(this.bankId);
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  fetch(_id) {
    this.isLoadingBank = true;
    this.branchService.getBranchsList(_id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.translate.get("DATA_NOT_EXIST").subscribe((text: string) => {
            this.errorMessage = text;
          });
        } else {
          this.tempusermanagelist = [...resData["branches"]];
          this.usermanagelist = [...resData["branches"]];
        }
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "DATA_NOT_FOUND";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoadingBank = false;
    }, 3000);
  }

  fetchActive(_id) {
    this.branchService.getBranchsList(_id).subscribe(
      (resData) => {
        this.totalItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.translate.get("DATA_NOT_EXIST").subscribe((text: string) => {
            this.errorMessage = text;
          });
        } else {
          this.tempusermanagelist = [...resData["branches"]];
          this.usermanagelist = [...resData["branches"]];
        }
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "DATA_NOT_FOUND";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      },
      () => this.getUserList(this.usermanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserList(res) {
    this.usermanagelist = res;
    this.dataSource = new MatTableDataSource<any>(this.usermanagelist);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onDelete(_id, index) {
    this.branchService.deleteBranch(_id).subscribe(
      (resData) => {
        this.translate.get("BRANCH_DELETE_SUCCESS").subscribe((text: string) => {
           this.toastr.success(text);
        });
        this.fetch(this.bankId);
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("BRANCH_DELETE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
         });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onPause(_id, index) {
    this.branchService.pauseBranch(_id).subscribe(
      (resData) => {
        this.translate.get("BRANCH_PAUSE_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.fetch(this.bankId);
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("BRANCH_PAUSE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onDelete method is used to open a delete dialog.
   */
  onReactive(_id, index) {
    this.branchService.activeBranch(_id).subscribe(
      (resData) => {
        this.translate.get("BRANCH_ACTIVE_SUCCESS").subscribe((text: string) => {
           this.toastr.success(text);
        });
        this.fetch(this.bankId);
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("BRANCH_ACTIVE_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/bank"]);
  }

  /**
   *reload data.
   */
  onReload() {
    if (this.userLevel == 3) {
      this.fetchActive(this.bankId);
    } else {
      this.fetch(this.bankId);
    }
    this.isLoading = true;
  }
}
