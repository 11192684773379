<div class="gene-login login-v2 pad-y-xl">
   <div fxLayout="row" fxLayoutAlign="center start">
      <div fxLayout="column" fxFlex="100" fxFlex.xs="90" fxFlex.sm="80" fxFlex.md="95" fxFlex.lg="70" fxFlex.xl="60">
         
         <div fxLayout="row wrap" fxLayoutAlign="center stretch" class="height-full pad-t-xl pad-b-xl form-wrap-row set-resp-space" fxLayoutGap.gt-md="15px">
            <div fxLayout="column" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="60" fxFlex.lg="60" fxFlex.xl="60">
               <mat-card class="gene-login-v2 card-full-height card-full-width">
                  <div>
                     <div fxLayout="column" fxLayoutAlign="center center" class="mrgn-b-lg text-center">
                        <h2>{{'LOGOUT_IDLE_LOGON'|translate}}</h2>
                        <p style="direction: rtl;">{{'Reset_Password'|translate}}</p>
                     </div>
                     <form #form="ngForm" (ngSubmit)="login(form.value)" fxLayoutAlign="center start">
                     <div fxLayout="column" fxFlex.xs="100" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="70" fxFlex.xl="70" fxLayoutAlign="start">
                        <mat-form-field class="full-wid mrgn-b-md">
                           <input class="mrgn-t-none" matInput placeholder="{{'TITLE_EMAIL'|translate}}" type="text" name="email" required [(ngModel)]="email">
                           <span matSuffix><i class="material-icons">verified_user</i></span>                              
                        </mat-form-field>
                        <mat-form-field class="full-wid mrgn-b-md">
                           <input class="mrgn-t-none" matInput placeholder="{{'PASSWORD'|translate}}" type="password" name="password" required [(ngModel)]="password">
                           <span matSuffix><i class="material-icons">vpn_key</i></span>
                        </mat-form-field>
                        <div class="mrgn-b-md"><a [routerLink]="['/session/forgot-passwordV2']">{{'FORGET_PASSWORD'|translate}}?</a></div>
                        <div fxLayoutAlign="center start" class=" mrgn-b-lg">
                           <button mat-flat-button class="success-bg gene-btn-lg" [disabled]="!form.valid">{{'LOGIN'|translate}}</button>    
                        </div>
                     </div>
                     </form>
                  </div>
               </mat-card>
            </div>
         </div>
      </div>
   </div>
</div>