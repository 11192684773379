<mat-card-content>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <div class="user-content">
        <mat-card>
          <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <div class="pad-y-sm" *ngIf="!isLoading">
            <div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile">
              <div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)" fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100" class="user-contact-info">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4 *ngIf="userLevel == 3">{{'TABLE_CLIENT_NAME'|translate}} :</h4>
                    <h4 *ngIf="userLevel != 3"><i class="fas fa-user-edit"></i> {{'TABLE_CLIENT_NAME'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4 *ngIf="userLevel != 3" class="activeIcon">
                      <a class="gene-block" href="/clients/edit/{{ sps._id }}">{{ sps.fullName }}</a>
                    </h4>
                    <h4 *ngIf="userLevel == 3" class="activeIcon">{{ sps.fullName }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-map-marker"></i> {{'TITLE_ADDRESS'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4>{{ sps.address }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="far fa-id-card"></i> {{'CID_TITLE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4>{{ sps.cid }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-phone"></i> {{'TITLE_TELEPHONE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4><a href="tel:{{ sps.telephone }}" class="editIcon">{{ sps.telephone }}</a></h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="sps.cellphone !== ''">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-mobile-alt"></i> {{'TITLE_TELEPHONE_SECONDERY'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4><a href="tel:{{ sps.cellphone }}" class="editIcon">{{ sps.cellphone }}</a></h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-fax"></i> {{'TITLE_FAX'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4>{{ sps.fax }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="far fa-envelope"></i> {{'TITLE_EMAIL'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4><a href="mailto:{{ sps.email }}" class="activeIcon">{{ sps.email }}</a></h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4>{{'BANK_ACCOUNT_DETAILS'|translate}} :</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-university"></i> {{'BANK_CHOOSE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4>
                      <a href="/bank/view/{{ sps.bankInfo.bank._id }}" class="underLine">
                        {{ sps.bankInfo.bank.bankName }} ({{ sps.bankInfo.bank.bankCode }})
                      </a>
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-code-branch"></i> {{'BRANCH_CHOOSE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4>
                      <a href="/bank/viewBranch/{{ sps.bankInfo.branch._id }}" class="underLine">
                        {{ sps.bankInfo.branch.branchName }} ({{ sps.bankInfo.branch.branchCode }})
                      </a>
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
                    <h4><i class="fas fa-money-check"></i> {{'TITLE_ACCOUNT_NUMBER'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="75" fxFlex.gt-xs="75" fxFlex="100">
                    <h4>{{ sps.bankInfo.accountNumber }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="pad-wrap">
              <mat-card-actions>
                  <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'CLIENT_BACK_TO'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon></button>
              </mat-card-actions>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card-content>
