export class Planningstage {
    constructor(
      public title: string,
      public description: string,
      public subplanningstages?: [],
      public creator?: string,
      public status?: string,
      public _id?: string
    ) {}
  
  }
  