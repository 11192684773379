import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { MatDialogRef, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { AddNewReportComponent } from 'app/widget-component/add-new-report/add-new-report.component';
import { AddNewTaskComponent } from 'app/widget-component/add-new-task/add-new-task.component';


@Injectable({
	providedIn: 'root'
})

export class CoreService {
	
	collapseSidebar 		 : boolean = false;
	collapseSidebarStatus : boolean;
	sidenavMode				 : string  = "side";
	sidenavOpen 			 : boolean = true;
	horizontalSideNavMode : string  = "over";
	horizontalSideNavOpen : boolean = false; 	
	projectDetailsContent : any;
	editProductData 		 : any;	

	constructor(private matDialog : MatDialog,private http : HttpClient){
	}
	
	//addNewUserDailog function is used to open Add New user Dialog Component. 
	addNewReportDailog(){
		let dialogRef : MatDialogRef<AddNewReportComponent>;
		dialogRef = this.matDialog.open(AddNewReportComponent);
		
		return dialogRef.afterClosed();
	}

	//addNewUserDailog function is used to open Add New user Dialog Component. 
	addNewTaskDailog(){
		let dialogRef : MatDialogRef<AddNewTaskComponent>;
		dialogRef = this.matDialog.open(AddNewTaskComponent);
		
		return dialogRef.afterClosed();
	}
}