export class Contact {
    constructor(
      public fullName: string,
      public telephone: string,
      public fax: string,
      public email: string,
      public cellphone: string,
      public level: {
        _id: string,
        title?: string
      },
      public typeOfContact?: number,
      public client?: {
        _id?: string,
        fullName?: string
      },
      public status?: number,
      public creator?: string,
      public upDatedBy?: string,
      public _id?: string
    ) {}
  
  }