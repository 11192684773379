<div class="gene-lockscreenV2 pad-y-xl">
   <div fxLayout="row" fxLayoutAlign="center start">
      <div fxLayout="column" fxFlex="100" fxFlex.xs="90" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="70" fxFlex.xl="60">
         <mat-toolbar-row fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutAlign.xs="center center">
            <div class="mrgn-b-md">
               <img src="assets/img/logo-sign.png">
            </div>
         </mat-toolbar-row>
         <div fxLayout="row wrap" fxLayoutAlign="center stretch" class="height-full pad-t-xl pad-b-xl form-wrap-row">
            <div fxLayout="column" fxFlex="100" fxFlex.xs="100" fxFlex.sm="500px" fxFlex.md="544px" fxFlex.lg="544px" fxFlex.xl="544px">
               <mat-card class="gene-login-v2 height-full ">
                  <div>
                     <div fxLayout="column" fxLayoutAlign="center center" class="mrgn-b-sm text-center">
                        <h2>{{'LOGOUT_IDLE_TITLE'|translate}}</h2>                                                    
                        <p>{{'LOGOUT_IDLE_DESCRIBTION'|translate}}</p>
                     </div>
                     <button [routerLink]="['/session/loginV2']" mat-flat-button color="green-600" class="gene-btn-lg full-wid" >{{'LOGOUT_IDLE_LOGON'|translate}}</button>
                  </div>
               </mat-card>
            </div>
         </div>
      </div>
   </div>
</div>
