import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "app/service/auth-service/auth.service";
import { Contact } from "app/contact/contact.model";
import { ContactService } from "app/service/contact/contact.service";
import { JobRoleService } from "app/service/jobrole/jobrole.service";
import { TranslateService } from "@ngx-translate/core";
import { ClientService } from "app/service/client/client.service";

@Component({
  selector: "ms-addplanningstage",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class AddContactComponent implements OnInit {
  public form: FormGroup;
  private _id;
  levels: Array<any> = [];
  tems: Array<any>;
  isLoading: Boolean;
  public filteredList5;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private contactService: ContactService,
    private authService: AuthService,
    private jobRoleService: JobRoleService,
    private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("CONTACT_TITLE");
    this.isLoading = true;
    const userLevel = this.authService.getUserLevel();
    if (userLevel == 3) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/clients/view/" + this._id]);
    }
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.router.navigate(["/clients"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.clientService.getSingleClient(this._id).subscribe(
        (resData) => {
          this.fetch();
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
  }

  fetch() {
    this.jobRoleService.getActiveJobRoleList().subscribe(
      (resData) => {
        this.tems = [...resData["jobRoleList"]];
        this.tems.forEach((item) => {
          this.levels.push({
            value: item._id,
            viewValue: item.title,
          });
        });
        this.filteredList5 = this.levels.slice();
        this.form = this.fb.group({
          level: [null, Validators.compose([Validators.required])],
          fullName: [
            null,
            Validators.compose([
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(64),
            ]),
          ],
          telephone: [
            null,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
          fax: [
            null,
            Validators.compose([
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
          email: [
            null,
            Validators.compose([
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(64),
              Validators.email,
            ]),
          ],
          cellphone: [
            null,
            Validators.compose([
              Validators.minLength(9),
              Validators.maxLength(10),
            ]),
          ],
        });
      },
      (err) => {
        this.isFound = false;
        if (err.status == 404) {
          this.errCode = "DATA_NOT_EXIST";
        } else {
          this.errCode = "ERROR_DATA_MISSING";
        }
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  onSubmit() {
    const newPS = new Contact(
      this.form.value.fullName,
      this.form.value.telephone,
      this.form.value.fax,
      this.form.value.email,
      this.form.value.cellphone,
      {
        _id: this.form.value.level.value,
        title: this.form.value.level.viewValue,
      },
      1,
      { _id: this._id }
    );
    this.contactService.createContact(newPS).subscribe(
      (res) => {
        this.translate.get("CONTACT_ADD_SUCCESS").subscribe((text: string) => {
          this.toastr.success(text);
        });
        this.router.navigate(["/clients/view/" + this._id]);
      },
      (errorMessage) => {
        this.translate.get("CONTACT_ADD_WARNING").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
    );
  }

  onCancel() {
    this.router.navigate(["/clients/view/" + this._id]);
  }
}
