import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  MatInputModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatDatepickerModule, 
  MatIconModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatTabsModule,
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule, MatProgressBarModule
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";

import { ClientRoutes } from "./client.routing";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgAisModule } from "angular-instantsearch";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WidgetComponentModule } from "app/widget-component/widget-component.module";
import { MatSelectFilterModule } from 'mat-select-filter';

import { AddClientComponent } from './add/add.component';
import { EditClientComponent } from './edit/edit.component';
import { ListClientComponent } from './list/list.component';
import { ViewClientComponent } from './view/view.component';
import { AddProjectComponent } from './project/add.component';
import { AddContactComponent } from './contact/add.component';

@NgModule({
  declarations: [
    AddClientComponent,
    EditClientComponent,
    ListClientComponent,
    ViewClientComponent,
    AddContactComponent,
    AddProjectComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ClientRoutes),
    NgxDatatableModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
		MatDatepickerModule, 
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgAisModule.forRoot(),
    WidgetComponentModule,
    MatPaginatorModule,
    MatSortModule,
		MatTabsModule,
    MatSelectFilterModule 
  ],
})
export class ClientModule {}
