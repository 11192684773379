import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class DocumentService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"document/";

  getDocumentsList() {
    return this.httpService.get(this.domain + "list");
  }

  getSingleDocument(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "view/" + _id
    );
  }

  createDocument(data) {
    return this.httpService.put(
      this.domain + "create",
      JSON.stringify(data)
    );
  }

  editSingleDocument(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteDocument(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pauseDocument(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeDocument(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
