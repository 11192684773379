import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";

import { Document } from "../document.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { DocumentService } from "app/service/documents/document.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ms-editplanningstage",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class EditDocumentComponent implements OnInit {
  public form: FormGroup;
  private documentId;
  isLoading: Boolean;
  sps: Document;
  isFound: Boolean = true;
  errCode: string;

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private documentService: DocumentService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const userLevel = this.authService.getUserLevel();
    if (userLevel != 1) {
      this.translate.get("AUTH_MESSAGE").subscribe((text: string) => {
        this.toastr.warning(text);
      });
      this.router.navigate(["/documents"]);
    }
    this.isLoading = true;
    this.pageTitleService.setTitle("DOCUMENTS_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this.documentId = param.id;
      if (this.documentId.length != 24) {
        this.router.navigate(["/consultanttype"]);
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
      }
      this.documentService.getSingleDocument(this.documentId).subscribe(
        (resData) => {
          this.sps = resData["document"];
        },
        (err) => {
          this.isFound = false;
          if (err.status == 404) {
            this.errCode = "DATA_NOT_EXIST";
          } else {
            this.errCode = "DATA_NOT_FOUND";
          }
          this.translate.get("ERROR_MSG").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
      if (this.isFound) {
        this.form = this.fb.group({
          title: [
            this.sps.title,
            Validators.compose([
              Validators.required,
              Validators.minLength(4),
              Validators.maxLength(24),
            ]),
          ],
          description: [
            this.sps.description,
            Validators.compose([
              Validators.required,
              Validators.minLength(12),
              Validators.maxLength(64),
            ]),
          ],
        });
      }
    }, 2000);
  }

  onSubmit() {
    this.sps.title = this.form.value.title;
    this.sps.description = this.form.value.description;
    this.documentService.editSingleDocument(this.sps, this.documentId).subscribe(
        (res) => {
          this.translate.get("DOCUMENTS_UPDATE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
          });
          this.router.navigate(["/documents/view/" + this.documentId]);
        },
        (errorMessage) => {
          this.translate.get("DOCUMENTS_UPDATE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
          });
        }
      );
  }

  onCancel() {
    this.router.navigate(["/documents/view/" + this.documentId]);
  }
}
