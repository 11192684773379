<mat-card-content>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <div class="user-content">
        <mat-card>
          <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
          <div class="pad-y-sm" *ngIf="!isLoading">
            <div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile">
              <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="!isFound">{{errCode|translate}}</b>
              <div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)" fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100" class="user-contact-info" *ngIf="isFound">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4 *ngIf="userLevel == 3">{{'FULLNAME_TITLE'|translate}} :</h4>
                    <h4 *ngIf="userLevel != 3"><i class="fas fa-user-edit"></i> {{'FULLNAME_TITLE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4 *ngIf="userLevel != 3" class="activeIcon">
                      <a class="gene-block" href="/contact/edit/{{ sps._id }}">{{
                        sps.fullName
                      }}</a>
                    </h4>
                    <h4 *ngIf="userLevel == 3" class="activeIcon">
                      {{ sps.fullName }}
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4 *ngIf="sps.typeOfContact == 1"><i class="fas fa-user-tie"></i> {{'TITLE_CLIENT'|translate}} :</h4>
                    <h4 *ngIf="sps.typeOfContact == 2"><i class="fas fa-user-tie"></i> {{'TITLE_CONSULTANT'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4>{{ sps.client.fullName }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4><i class="fas fa-user-tie"></i> {{'JOB_ROLE_TITLE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4>{{ sps.level.title }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4><i class="fas fa-phone"></i> {{'TITLE_TELEPHONE'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4>
                      <a class="gene-block editIcon" href="tel:{{ sps.telephone }}">{{
                        sps.telephone
                      }}</a>
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="sps.cellphone !== ''">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4><i class="fas fa-mobile-alt"></i> {{'TITLE_TELEPHONE_SECONDERY'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4>
                      <a class="gene-block editIcon" href="tel:{{ sps.cellphone }}">{{
                        sps.cellphone
                      }}</a>
                    </h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4><i class="fas fa-fax"></i> {{'TITLE_FAX'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4>{{ sps.fax }}</h4>
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="100">
                    <h4><i class="far fa-envelope"></i> {{'TITLE_EMAIL'|translate}} :</h4>
                  </div>
                  <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100">
                    <h4>
                      <a class="gene-block underLine" href="mailto:{{ sps.email }}">{{sps.email}}</a>
                    </h4>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div class="pad-wrap">
                  <mat-card-actions>
                    <button mat-raised-button color="warn" type="button" (click)="onCancel()">
                      {{'CONTACT_BACK_TO_LIST'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon>
                    </button>
                    &nbsp;
                    <button mat-raised-button color="warn" type="button" (click)="onRetuenToClient()" *ngIf="sps.typeOfContact == 1">
                      {{'CLIENT_BACK_TO_DATA'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon>
                    </button>
                    <button mat-raised-button color="warn" type="button" (click)="onRetuenToClient()" *ngIf="sps.typeOfContact == 2">
                      {{'CONSULTANT_BACK_TO_DATA'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">keyboard_return</mat-icon>
                    </button>
                  </mat-card-actions>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card-content>
