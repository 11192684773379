<mat-card-content>
   <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
      <mat-card fxFlex="100%">
         <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
               <div fxLayout="column">
                  <h4>{{'BANK_ADD'|translate}}</h4>
               </div> <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
         </div>
         <div class="gene-card-content">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
               <div fxLayout="row wrap" fxLayoutAlign="space-between center">
               <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="{{'BANK_EDIT'|translate}}" formControlName="bankCode" minlength="2" maxlength="2">
                  </mat-form-field>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankCode'].hasError('required') && form.controls['bankCode'].touched">
                     {{'BANK_CODE_REQUIRE'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankCode'].hasError('minlength') && form.controls['bankCode'].touched">
                     {{'BANK_CODE_LENGTH'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankCode'].hasError('maxlength') && form.controls['bankCode'].touched">
                     {{'BANK_CODE_LENGTH'|translate}}
                  </small>
               </div>
               <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="{{'BANK_EDIT'|translate}}" formControlName="bankName" minlength="7" maxlength="36">
                  </mat-form-field>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankName'].hasError('required') && form.controls['bankName'].touched">
                     {{'BANK_NAME_REQUIRE'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankName'].hasError('minlength') && form.controls['bankName'].touched">
                     {{'BANK_NAME_MIN'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankName'].hasError('maxlength') && form.controls['bankName'].touched">
                     {{'BANK_NAME_MAX'|translate}}
                  </small>
               </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
               <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="{{'TITLE_ADDRESS'|translate}}" formControlName="bankAddress" minlength="9" maxlength="64">
                  </mat-form-field>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankAddress'].hasError('required') && form.controls['bankAddress'].touched">
                     {{'TITLE_ADDRESS_REQIURE'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankAddress'].hasError('minlength') && form.controls['bankAddress'].touched">
                     {{'TITLE_ADDRESS_MIN'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankAddress'].hasError('maxlength') && form.controls['bankAddress'].touched">
                     {{'TITLE_ADDRESS_MAX'|translate}}
                  </small>
               </div>
               <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="{{'TITLE_POSTAL_CODE'|translate}}" formControlName="bankPostalCode" minlength="5" maxlength="7">
                  </mat-form-field>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankPostalCode'].hasError('required') && form.controls['bankPostalCode'].touched">
                     {{'TITLE_POSTAL_CODE_REQIURE'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankPostalCode'].hasError('minlength') && form.controls['bankPostalCode'].touched">
                     {{'TITLE_POSTAL_CODE_MIN'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankPostalCode'].hasError('maxlength') && form.controls['bankPostalCode'].touched">
                     {{'TITLE_POSTAL_CODE_MAX'|translate}}
                  </small>
               </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
               <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="{{'TITLE_TELEPHONE'|translate}}" formControlName="bankTelephone" minlength="9" maxlength="10">
                  </mat-form-field>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankTelephone'].hasError('required') && form.controls['bankTelephone'].touched">
                     {{'TITLE_TELEPHONE_REQIURE'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankTelephone'].hasError('minlength') && form.controls['bankTelephone'].touched">
                     {{'TITLE_TELEPHONE_MIN'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankTelephone'].hasError('maxlength') && form.controls['bankTelephone'].touched">
                     {{'TITLE_TELEPHONE_MAX'|translate}}
                  </small>
               </div>
               <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput placeholder="{{'TITLE_FAX'|translate}}" formControlName="bankFax" minlength="9" maxlength="10">
                  </mat-form-field>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankFax'].hasError('required') && form.controls['bankFax'].touched">
                     {{'TITLE_FAX_REQIURE'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankFax'].hasError('minlength') && form.controls['bankFax'].touched">
                     {{'TITLE_FAX_MIN'|translate}}
                  </small>
                  <small class="gene-block warn-text" *ngIf="form.controls['bankFax'].hasError('maxlength') && form.controls['bankFax'].touched">
                     {{'TITLE_FAX_MAX'|translate}}
                  </small>
               </div>
            </div>
               <div class="pad-wrap">
                  <mat-card-actions>
                     <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">{{'BTN_ADD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">check</mat-icon></button>&nbsp;
                     <button mat-raised-button color="warn" type="button" (click)="onCancel()">{{'BTN_CANCEL'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">close</mat-icon></button>
                  </mat-card-actions>
               </div>
            </form>
         </div>
      </mat-card>
   </div>
</mat-card-content>