<mat-card-content>
  <div class="user-manage-list">
    <mat-card>
      <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="mrgn-r-sm">
            <h3>{{'PROJECT_LIST'|translate}}</h3>
          </div>
        </div>
        <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
          <mat-card-actions class="mat-pad-none margin-none">
            <button mat-raised-button mat-button-sm color="primary" (click)="onReload()">
              {{'BTN_RELOAD'|translate}}<mat-icon class="mrgn-l-md gene-text-xl">refresh</mat-icon>
            </button>
          </mat-card-actions>
        </div>
      </div>
      <mat-divider></mat-divider>
      <mat-progress-spinner mode="indeterminate" color="primary" *ngIf="isLoading" style="margin: auto"></mat-progress-spinner>
      <div class="table-responsive" *ngIf="!isLoading">
        <div class="pad-all-md mat-white box-inset mrgn-all-md">
          <form class="example-form" fxFlex="220px">
            <mat-form-field class="full-wid">
              <input matInput type="text" placeholder="{{'TITLE_FILTER'|translate}}" (keyup)="updateFilter($event)" />
            </mat-form-field>
          </form>
        </div>
        <mat-divider></mat-divider>
        <b class="warn-text" style="margin: auto; text-align: center;" *ngIf="this.totalItems == 0">{{errCode|translate}}</b>
        <table class="full-wid" mat-table [dataSource]="dataSource" *ngIf="this.totalItems > 0">
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_SERIAL_ID'|translate}}</th>
            <td mat-cell *matCellDef="let element; index as i">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="projectInternalId">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_INTERNAL_NUMBER'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.projectInternalId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_NAME'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <a href="/projects/view/{{ element._id }}" class="underLine">{{ element.title }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_CLIENT_NAME'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <a href="/clients/view/{{ element.client._id }}" class="underLine">{{ element.client.fullName }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="projectType">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TYPE'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <a href="/projecttype/view/{{ element.projectType._id }}" class="underLine">{{ element.projectType.title }}</a>
              
            </td>
          </ng-container>
                    
          <ng-container matColumnDef="fee">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_FEES'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              ₪ {{ element.projectFees | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="usedFee">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_USED_FEE'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              ₪ {{ element.projectFees | number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_UPDATED_AT'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>{{'PROJECT_LAST_DATE'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{'TITLE_STATUS'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div fxLayoutAlign="start start">
                <mat-chip-list>
                  <mat-chip color="{{ colors[element.status] }}" selected>{{ status[element.status] }}</mat-chip>
                </mat-chip-list>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{'TABLE_ACTIONS'|translate}}</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <a href="/projects/view/{{ element._id }}" mat-icon-button>
                <mat-icon class="mat-icon-grey gene-text-lg">remove_red_eye</mat-icon>
              </a>
              <a *ngIf="userLevel != 3" href="/projects/edit/{{ element._id }}" mat-icon-button>
                <mat-icon class="mat-icon-grey gene-text-lg editIcon">edit</mat-icon>
              </a>
              <button mat-icon-button (click)="onReactive(element._id, i)" *ngIf="element.status != 1 && userLevel != 3">
                <mat-icon class="mat-icon-grey gene-text-lg activeIcon">play_arrow</mat-icon>
              </button>
              <button mat-icon-button (click)="onPause(element._id, i)" *ngIf="userLevel != 3">
                <mat-icon class="mat-icon-grey gene-text-lg pauseIcon">pause</mat-icon>
              </button>
              <button mat-icon-button (click)="onDelete(element._id, i)" *ngIf="userLevel != 3">
                <mat-icon class="mat-icon-grey gene-text-lg deleteIcon">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
      </div>
      <div class="pagination" *ngIf="this.totalItems > 0">
        <mat-paginator [length]="this.totalItems" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-card>
  </div>
</mat-card-content>
