import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MainUrl } from 'app/Shared/component/url';

@Injectable({ providedIn: "root" })
export class ProjectService {
  private mainUrl: MainUrl = new MainUrl();
  constructor(private httpService: HttpClient) {}
  domain = this.mainUrl.url+"project/";

  getActiveProjectList() {
    return this.httpService.get(this.domain + "activelist");
  }

  getProjectList() {
    return this.httpService.get(this.domain + "list");
  }

  getActiveUserProjectList(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "activeuserlist/" + _id
    );
  }

  getUserProjectList(id) {
    const _id = id;
    return this.httpService.get(this.domain + "userlist/" + _id);
  }

  getActiveProjectTypeProjectsList(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "activeprojecttypelist/" + _id
    );
  }

  getProjectTypeProjectsList(id) {
    const _id = id;
    return this.httpService.get(
      this.domain + "projecttypelist/" + _id
    );
  }

  getSingleProject(id) {
    const _id = id;
    return this.httpService.get(this.domain + "view/" + _id);
  }

  createProject(data) {
    return this.httpService.post(
      this.domain + "createProject",
      JSON.stringify(data)
    );
  }

  editSingleProject(data, id) {
    const _id = id;
    return this.httpService.put(
      this.domain + "edit/" + _id,
      JSON.stringify(data)
    );
  }

  deleteProject(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "delete/" + _id);
  }

  pauseProject(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "pause/" + _id);
  }

  activeProject(id) {
    const _id = id;
    return this.httpService.delete(this.domain + "active/" + _id);
  }
}
