<form [formGroup]="addNewUserForm" class="popup-card-width add-new-user">
  <div>
    <h2 mat-dialog-title>{{ "translate" | translate }}</h2>
  </div>
  <mat-dialog-content>
		<div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
			<mat-form-field class="full-wid mrgn-b-md">
			   <input matInput placeholder="{{'FULLNAME_TITLE'|translate}}" formControlName="title"  minlength="5" maxlength="64">
			</mat-form-field>
			<small class="gene-block warn-text" *ngIf="addNewUserForm.controls['title'].hasError('required') && addNewUserForm.controls['title'].touched">
			   {{'title_REQUIRE'|translate}}
			</small>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none">
    <button
      type="button"
      mat-raised-button
      (click)="dialogRef.close()"
      color="primary"
    >
      CLOSE
    </button>
    <button
      (click)="onFormSubmit()"
      type="submit"
      [disabled]="!addNewUserForm.valid"
      mat-raised-button
      color="warn"
    >
      SUBMIT
    </button>
  </mat-dialog-actions>
</form>
