import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, throwError } from "rxjs";

import { User } from "./user.model";

@Injectable({ providedIn: "root" })
export class UserService {
  userChanged = new Subject<User[]>();
  private user: User[] = [];
  totalPosts;

  constructor(private httpService: HttpClient) {}

  getUsersList() {
    return this.httpService.get("http://localhost:8080/auth/list");
  }

  getSingleUser(id) {
    const _id = id;
    return this.httpService.get(
      "http://localhost:8080/auth/view/" + _id
    );
  }

  createUser(data) {
    return this.httpService.put(
      "http://localhost:8080/auth/createUser",
      JSON.stringify(data)
    );
  }

  editUser(data, id) {
    const _id = id;
    console.log(data);
    return this.httpService.patch(
      "http://localhost:8080/auth/editUser/" + _id,
      JSON.stringify(data)
    );
  }

  deleteUser(id) {
    const _id = id;
    return this.httpService.delete("http://localhost:8080/auth/delete/" + _id);
  }

  pauseUser(id) {
    const _id = id;
    return this.httpService.delete("http://localhost:8080/auth/pause/" + _id);
  }

  activeUser(id) {
    const _id = id;
    return this.httpService.delete("http://localhost:8080/auth/active/" + _id);
  }
}
