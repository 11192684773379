import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PageTitleService } from "app/core/page-title/page-title.service";
import { fadeInAnimation } from "app/core/route-animation/route.animation";

import { ProjectType } from "../projecttype.model";
import { AuthService } from "app/service/auth-service/auth.service";
import { ProjectTypeService } from 'app/service/projecttype/projecttype.service';
import { ProjectService } from 'app/service/project/project.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class ViewProjectTypeComponent implements OnInit {
  sps: ProjectType;
  private _id;
  isLoading: Boolean;
  userLevel: number;
  status: Array<string> = ["", "פעיל", "טרם הופעל", "מושהה", "לא פעיל"];
  colors: Array<string> = ["", "blue", "gray", "orange", "red"];
  totalItems: number;
  isFound: Boolean = true;
  errCode: string;

  @ViewChild(MatPaginator, { static: false }) projectsPaginator: MatPaginator;
  projectsDataSource: any;
  projectmanagelist: any;
  tempprojectsmanagelist: Array<any>;
  totalProjectsItems: number;
  displayedProjectsColumns: string[] = [
    "serialNumber",
    "projectInternalId",
    "title",
    "projectType",
    "status",
    "fee",
    "actions",
  ];
  selectionProjects = new SelectionModel<any>(true, []);

  constructor(
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private projectTypeService: ProjectTypeService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("PROJECT_TYPE_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
        this.router.navigate(["/projecttype"]);
      }
      this.projectTypeService.getSingleProjectType(this._id).subscribe(
        (resData) => {
          this.sps = resData["projecttype"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      if(this.userLevel==3){
        this.fetchActiveProjects();
      }else {
        this.fetchProjects();
      }
      this.isLoading = false;
    }, 2000);
  }
  
  updateProjectsFilter(event) {
    const fil = event.target.value;
    const tempprojects = this.tempprojectsmanagelist.filter(function (d) {
      return d.title.indexOf(fil) !== -1 || !fil;
    });
    this.getUserProjectsList(tempprojects);
  }

  fetchProjects() {
    this.isLoading = true;
    this.projectService.getProjectTypeProjectsList(this._id).subscribe(
      (resData) => {
        this.totalProjectsItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempprojectsmanagelist = [...resData["projectlist"]];
          this.projectmanagelist = [...resData["projectlist"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserProjectsList(this.projectmanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  fetchActiveProjects() {
    this.isLoading = true;
    this.projectService.getActiveProjectTypeProjectsList(this._id).subscribe(
      (resData) => {
        this.totalProjectsItems = resData["totalItems"];
        if (this.totalItems == 0) {
          this.errCode = "DATA_NOT_FOUND";
        } else {
          this.tempprojectsmanagelist = [...resData["projectlist"]];
          this.projectmanagelist = [...resData["projectlist"]];
        }
      },
      (err) => {
        this.errorFound(err);
      },
      () => this.getUserProjectsList(this.projectmanagelist)
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  getUserProjectsList(res) {
    this.projectmanagelist = res;
    this.projectsDataSource = new MatTableDataSource<any>(this.projectmanagelist);
    setTimeout(() => {
      console.log(this.projectsDataSource)
      this.projectsDataSource.paginator = this.projectsPaginator;
    }, 0);
  }

  /**
   *onDeleteContact method is used to delete contact / status 4.
   */
  onDeleteProject(_id, index) {
    this.projectService.deleteProject(_id).subscribe(
      (resData) => {
        this.translate.get("PROJECT_TYPE_DELETE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetchProjects();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PROJECT_TYPE_DELETE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onPauseContact method is used to pause contact / status 3.
   */
  onPauseProject(_id, index) {
    this.projectService.pauseProject(_id).subscribe(
      (resData) => {
        this.translate.get("PROJECT_TYPE_PAUSE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetchProjects();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PROJECT_TYPE_PAUSE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *onReactiveContact method is used to delete contact / status 1.
   */
  onReactiveProject(_id, index) {
    this.projectService.activeProject(_id).subscribe(
      (resData) => {
        this.translate.get("PROJECT_TYPE_ACTIVE_SUCCESS").subscribe((text: string) => {
            this.toastr.success(text);
        });
        this.fetchProjects();
        this.isLoading = true;
      },
      (errorMessage) => {
        this.translate.get("PROJECT_TYPE_ACTIVE_WARNING").subscribe((text: string) => {
            this.toastr.warning(text);
        });
      }
    );
  }

  /**
   *reload data.
   */
  onReload() {
    if(this.userLevel==3){
      this.fetchActiveProjects();
    }else {
      this.fetchProjects();
    }
    this.isLoading = true;
  }

  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
  
  onCancel() {
    this.router.navigate(["/projecttype"]);
  }
}
