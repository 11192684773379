import { Component, OnInit } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";

import { AuthService } from "app/service/auth-service/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

import { SubPlanningStage } from "../subplanningstage.model";
import { SubPlanningStageService } from 'app/service/planningstage/subplanningstage.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "ms-singleplanningstage",
  templateUrl: "./singlesubplanningstage.component.html",
  styleUrls: ["./singlesubplanningstage.component.scss"],
  host: {
    "[@fadeInAnimation]": "true",
  },
  animations: [fadeInAnimation],
})
export class SingleSubPlanningStageComponent implements OnInit {
  sps: SubPlanningStage;
  private _id;
  isLoading: Boolean;
  isEditing: Boolean = false;
  userLevel: number;
  isFound: Boolean = true;
  errCode: string;
  
  constructor(
    private pageTitleService: PageTitleService,
    private psService: SubPlanningStageService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userLevel = this.authService.getUserLevel();
    this.pageTitleService.setTitle("PLANNING_STAGE_MAIN_TITLE");
    this.route.params.subscribe((param) => {
      this._id = param.id;
      if (this._id.length != 24) {
        this.translate.get("ERROR_MSG").subscribe((text: string) => {
          this.toastr.warning(text);
        });
        this.router.navigate(["/planningstage"]);
      }
      this.psService.getSubSinglePlanningStage(this._id).subscribe(
        (resData) => {
          this.sps = resData["subplanningStage"];
        },
        (err) => {
          this.errorFound(err);
        }
      );
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  onCancel() {
    this.router.navigate(["/planningstage/view/" + this.sps.planningStage._id]);
  }
  
  errorFound(err) {
    this.isFound = false;
    if (err.status == 404) {
      this.errCode = "DATA_NOT_EXIST";
    } else {
      this.errCode = "DATA_NOT_FOUND";
    }
    this.translate.get("ERROR_MSG").subscribe((text: string) => {
      this.toastr.warning(text);
    });
  }
}
